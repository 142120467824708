<template>
    <div>
        <span class="text-danger" v-if="error">{{ error }}</span>
    </div>
</template>

<script>
export default {
    data() {
        return {
            error: null,
            paid: false,
        };
    },
    props: {
        paymentIntent: {
            type: Object,
            required: true,
        },
    },
    methods: {
        processPayment() {
            this.error = null;
            if (
                !this.paymentIntent ||
                !this.paymentIntent.hosted_payment ||
                !this.paymentIntent.hosted_payment.payment_url
            ) {
                this.error = "Can't init PayPlug";
                return;
            }
            this.$emit("set-loading", true);
            Payplug.showPayment(this.paymentIntent.hosted_payment.payment_url);
        },
    },
    mounted() {
        if (!Payplug) {
            this.error = "Can't init PayPlug";
            return;
        }
        Payplug._listen("message", window, (e) => {
            if (typeof e.data === "string") {
                if (e.data === "closePayPlugFrame") {
                    if (this.paid) this.$emit("paid");
                    else this.$emit("set-loading", false);
                }
            } else if (typeof e.data === "object") {
                if (e.data.event === "paidByPayPlug" || e.data.event === "setRedirectionUrl") {
                    this.paid = true;
                }
            }
        });
    },
};
</script>
