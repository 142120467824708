<template>
    <div>
        Votre paiement de {{ $utils.formatPrice(amount_paid / 100) }}€ a bien été pris en compte !
        <template v-if="amount_paid === command.remaining_amount_to_pay">
            <p><strong>Récapitulatif de la commande :</strong></p>
            <div class="ml-2">
                <p class="d-block" v-for="(product, index) in command.command_products.data" :key="index">
                    <span class="d-block">{{ product.quantity }} x {{ product.product.name }}</span>
                    <span class="d-block" v-if="product.comment">{{ product.comment }}</span>
                    <span class="ml-2" v-if="product.command_product_option.data.length > 0"></span>
                    <span
                        v-for="(option, index) in product.command_product_option.data"
                        :key="index"
                        style="font-size: 0.8rem">
                        {{ option.product_option.cc_product.name }}
                        <span v-if="!option.product_option.include > 0">
                            ({{ $utils.formatPrice(option.product_option.price) }}€)</span
                        >
                        <span v-if="option.quantity > 1"> (x{{ option.quantity }})</span>
                        <span v-if="index < product.command_product_option.data.length - 1"> • </span>
                    </span>
                </p>
            </div>
            <div class="w-100 text-center" v-if="command.redirect_url">
                <a
                    :href="command.redirect_url"
                    class="mt-2 btn main-button"
                    :style="{ 'background-color': `${bgColor} !important` }"
                    >Retour au site</a
                >
            </div>
        </template>
        <div class="w-100 text-center" v-else>
            <button
                type="button"
                class="mt-2 btn main-button"
                :style="{ 'background-color': `${bgColor} !important` }"
                @click="$emit('go-back')">
                Régler le montant restant
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        command: {
            type: Object,
            required: true,
        },
        amount_paid: {
            type: Number,
            required: true,
        },
    },
    computed: {
        bgColor() {
            return this.$store.getters["restaurant/bgcolor"];
        },
    },
};
</script>
