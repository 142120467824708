<template>
    <div class="row">
        <label
            >Merci de renseigner votre adresse
            <input
                :disabled="isValidatingAddress"
                type="text"
                name="address"
                placeholder="Saisissez votre adresse"
                class="form-control mt-2"
                autofocus
                @input="clientAddressChanged"
                v-model="clientAddress" />
        </label>
        <small class="text-danger" v-if="errorAddress">{{ errorAddress }}</small>
        <div class="address_results border-light" v-if="addresses_.length > 0">
            <span
                @click="selectAddress(address)"
                class="d-block mb-1 mt-1"
                v-for="(address, index) in addresses_"
                :key="index">
                {{ address.description }}
            </span>
        </div>
        <button
            class="main-button btn-light text-white d-flex mt-2"
            style="align-self: flex-end"
            :style="styleCartButton"
            :disabled="dispatch.finalClientAddress === null || isValidatingAddress"
            @click="validateAddress">
            Valider
        </button>
    </div>
</template>

<script>
import DispatchTypesEnum from "../../mixins/enums/click_and_collect/DispatchTypesEnum.js";
import axios from "axios";

export default {
    data() {
        return {
            clientAddress: this.$store.getters["client/addressForClient"],
            addresses: [],
            errorAddress: null,
            isValidatingAddress: false,
            sessionToken: null,
        };
    },
    mixins: [DispatchTypesEnum],
    props: {
        dispatch: {
            required: true,
        },
    },
    computed: {
        addresses_() {
            return this.addresses;
        },
        restaurantBgColorOnly() {
            return this.$store.getters["restaurant/bgcolor"];
        },
        styleCartButton() {
            return { "background-color": `${this.restaurantBgColorOnly} !important` };
        },
    },
    methods: {
        changeAddress() {
            this.dispatch.isAddressValidated = false;
        },
        searchAddress(oldAddressVal) {
            if (this.clientAddress !== oldAddressVal) return;

            if (!this.sessionToken) this.sessionToken = this.$utils.generateRandomString();

            axios
                .get(
                    `${API_URL}/${this.$api_key}/searchAddress?query=${this.clientAddress}&sessionToken=${this.sessionToken}`
                )
                .then((response) => {
                    this.errorAddress = null;
                    this.addresses = response.data.data;
                    if (this.addresses_.length === 0) this.errorAddress = this.$t("errors.address.no_match");
                })
                .catch((error) => {
                    this.addresses = [];
                    this.errorAddress = this.$t("errors.common.unknown");
                    if (error.response && error.response.data.message) this.errorAddress = error.response.data.message;
                    else if (error.message && error.message.toLowerCase() == "network error") {
                        this.errorAddress = this.$t("errors.common.network");
                    } else if (error.message) {
                        this.errorAddress = error.message;
                    }
                });
        },
        clientAddressChanged(e) {
            this.dispatch.finalClientAddress = null;
            this.dispatch.isAddressValidated = false;
            const newVal = e.target.value;
            if (newVal.length < 5) {
                this.addresses = [];
                this.errorAddress = null;
                this.sessionToken = null;
                return;
            }
            setTimeout(() => {
                this.searchAddress(newVal);
            }, 600);
        },
        selectAddress(address) {
            this.dispatch.finalClientAddress = address;
            this.clientAddress = address.description;
            this.addresses = [];
            this.errorAddress = null;
        },
        validateAddress() {
            if (this.dispatch.finalClientAddress != null) {
                this.isValidatingAddress = true;
                this.errorAddress = null;
                this.$store
                    .dispatch("client/validateAddressDispatch", {
                        place_id: this.dispatch.finalClientAddress.place_id,
                        sessionToken: this.sessionToken,
                    })
                    .then(() => {
                        this.$emit("next-step");
                        this.dispatch.isAddressValidated = true;
                        this.sessionToken = null;
                    })
                    .catch((error) => {
                        this.dispatch.isAddressValidated = false;
                        this.isValidatingAddress = false;
                        this.errorAddress = this.$t("errors.common.unknown");
                        if (error.message && error.message.toLowerCase() == "network error") {
                            this.errorAddress = this.$t("errors.common.network");
                        } else if (error.response && error.response.data.message) {
                            this.errorAddress = error.response.data.message;
                        } else if (error.message) {
                            this.errorAddress = error.message;
                        }
                    });
            } else {
                this.errorAddress = this.$t("errors.address.invalid");
            }
        },
    },
    created() {
        const address = this.$store.getters["client/address"];
        if (address) this.dispatch.finalClientAddress = address;
    },
};
</script>

<style scoped>
.address_results {
    position: relative;
    max-height: 15em;
    overflow-y: scroll;
    margin-bottom: 1em;
    z-index: 9999;
    background-color: white;
    cursor: pointer;
}
</style>
