<template>
    <ul class="nav">
        <nav-item-component
            v-for="item in items"
            :key="item.id"
            :item="item"
            :show-active="displayPerCategory && currentCategory !== null && item.id === currentCategory.id"
            @go-to="goTo" />
    </ul>
</template>

<script>
import NavItemComponent from "./NavItemComponent.vue";

export default {
    props: {
        items: {
            type: Array,
            required: true,
        },
        currentCategory: {
            type: Object,
            default: null,
        },
    },
    computed: {
        displayPerCategory() {
            return this.$store.getters["restaurant/displayPerCategory"];
        },
    },
    methods: {
        goTo(event) {
            this.$emit("set-current-category", event);
        },
    },
    components: {
        NavItemComponent,
    },
};
</script>
