var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: { "mt-3": _vm.hideCategoryName } }, [
    !_vm.hideCategoryName
      ? _c(
          "div",
          {
            staticClass: "row section-menu",
            attrs: { id: `noshow-cc-category-${_vm.category.id}` },
          },
          [
            _c("div", { staticClass: "col-12" }, [
              _c(
                "h6",
                {
                  staticClass: "categories-title noshow-widget-font-title",
                  style: _vm.styleTitle,
                },
                [_vm._v(_vm._s(_vm.category.name))]
              ),
            ]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        _vm._l(_vm.categoryProducts, function (product) {
          return _c("ProductCardComponent", {
            key: product.id,
            attrs: {
              addressValidated: _vm.addressValidated,
              categories: _vm.categories,
              category: _vm.category.name,
              product: product,
            },
          })
        }),
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }