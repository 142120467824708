<template>
    <header id="noshow-cc-header">
        <loader-component v-if="loading > 0" />
        <small class="text-danger" v-if="errors && errors.length > 0" v-html="errors.join('<br/>')"></small>
        <div v-show="loading === 0" class="pickup-info p-3">
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-12">
                            <h5 class="d-inline-block noshow-widget-font-title" :style="styleTitle">Retrait</h5>
                            <button
                                v-if="isDispatchAvailable && canChangeCollectType"
                                class="main-button float-right btn-light d-inline-block ml-3 text-white"
                                style="padding: 0.6em 1em !important"
                                :style="styleCartButton"
                                @click="$emit('changeCollectType', WIDGET_DISPATCH.value)">
                                Expédition à domicile
                            </button>
                            <button
                                v-if="isDeliveryAvailable && canChangeCollectType"
                                class="main-button float-right btn-light d-inline-block ml-3 text-white"
                                style="padding: 0.6em 1em !important"
                                :style="styleCartButton"
                                @click="$emit('changeCollectType', WIDGET_DELIVERY.value)">
                                Livraison par coursier
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6" style="margin-top: 10px !important">
                            <label class="mr-3">Date de retrait</label>
                            <datepicker
                                format="dd/MM/yyyy"
                                :use-utc="true"
                                v-model="currentDate"
                                :disabled-dates="disabledDates"
                                calendar-class="positition-relative z-2000 mt-2"
                                input-class="w-auto"
                                class="date-resa-cal date-resa"
                                :monday-first="true"
                                :language="fr"></datepicker>
                        </div>
                        <div class="col-md-6" style="margin-top: 10px !important">
                            <label>Heure de retrait</label>
                            <select
                                v-if="slots.length > 0"
                                class="custom-select"
                                style="margin-top: -2px !important"
                                v-model="currentSlot"
                                :disabled="isLoadingSlot">
                                <option v-for="slot in slots" :key="slot.id" :value="slot.id">
                                    {{ slotLabel(slot.hour) }}
                                </option>
                            </select>
                            <select
                                disabled="disabled"
                                v-else
                                class="custom-select disabled"
                                style="margin-top: -2px !important">
                                <option>Aucun créneau disponible</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="pickup-point p-3" style="background-color: #f7f7f7">
                        <h5 class="noshow-widget-font-title">Point de retrait</h5>
                        <h6 :style="styleTitle">
                            <strong class="noshow-widget-font-title">{{ restaurantName }}</strong>
                        </h6>
                        <p>{{ restaurantAddress }}</p>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import axios from "axios";
import LoaderComponent from "../LoaderComponent.vue";
import moment from "../../plugins/moment.js";
import WidgetTypesEnum from "../../mixins/enums/click_and_collect/WidgetTypesEnum.js";

export default {
    data() {
        return {
            loading: 0,
            errors: [],
            currentSlot: this.$store.getters["cart/slot"] !== null ? this.$store.getters["cart/slot"].id : null,
            currentDate: this.$store.getters["cart/date"] !== null ? this.$store.getters["cart/date"] : new Date(),
            selectedDay: null,
            isLoadingSlot: false,
        };
    },
    mixins: [WidgetTypesEnum],
    props: {
        canChangeCollectType: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        isDeliveryAvailable() {
            return this.$store.getters["restaurant/isDeliveryAvailable"];
        },
        isDispatchAvailable() {
            return this.$store.getters["restaurant/isDispatchAvailable"];
        },
        restaurantBgColorOnly() {
            return this.$store.getters["restaurant/bgcolor"];
        },
        fr() {
            return fr;
        },
        styleCartButton() {
            return { "background-color": `${this.restaurantBgColorOnly} !important` };
        },
        disabledDates() {
            let firstAvailableDate = this.firstAvailableDate;
            let today = moment().startOf("day");
            if (this.isTodayOrderable) today.subtract(1, "day");
            const closuresRanges = this.futureClosures.map((c) => {
                let from = moment(c.start_day).startOf("day");
                let to = moment(c.end_day).add(1, "day").startOf("day");
                return {
                    from: from.toDate(),
                    to: to.toDate(),
                };
            });

            var result = {
                to: firstAvailableDate ? moment(firstAvailableDate).startOf("day").toDate() : today,
                ranges: closuresRanges,
                customPredictor: this.datePickerPredictor,
            };

            if (this.lastDayOrderable) {
                result.from = moment(this.lastDayOrderable).toDate();
            }
            return result;
        },
        restaurantName() {
            return this.$store.getters["restaurant/name"];
        },
        restaurantAddress() {
            return this.$store.getters["restaurant/address"];
        },
        restaurantBgColor() {
            return this.$store.getters["restaurant/bgcolor"];
        },
        styleTitle() {
            return { color: this.restaurantBgColor + "!important" };
        },
        futureClosures() {
            return this.$store.getters["restaurant/futureClosures"];
        },
        futureServices() {
            return this.$store.getters["restaurant/futureServices"];
        },
        futureOpenings() {
            return this.$store.getters["restaurant/futureOpenings"];
        },
        selectedServices() {
            if (this.currentDate) {
                return this.getServicesAtDate(this.currentDate);
            }

            return [];
        },
        slots() {
            let slots = this.$store.getters["restaurant/slots"];
            if (slots !== null) return slots.filter((s) => s.enabled);
            return null;
        },
        lastDayOrderable() {
            return this.$store.getters["restaurant/lastDayOrderable"];
        },
        isTodayOrderable() {
            return this.$store.getters["restaurant/isTodayAvailable"];
        },
        firstAvailableDate() {
            let firstOrderableDate = this.$store.getters["restaurant/firstOrderableDate"];
            if (firstOrderableDate) return moment(firstOrderableDate).startOf("day").add(3, "hour").toDate();
            return new Date();
        },
    },
    methods: {
        setFirstAvailableDate() {
            this.currentDate = this.firstAvailableDate;
        },
        init() {
            this.errors = [];
            this.loading = 0;

            this.loading++;
            const lastDayPromise = this.$store
                .dispatch("restaurant/fetchLastDayOrderable", { params: { collect_type: "collect" } })
                .catch((error) => {
                    this.errors.push(
                        this.$utils.getErrorMsgFromErrorResponse(error, this.$t("errors.init.last_day_orderable"))
                    );
                })
                .finally(() => {
                    this.loading--;
                });

            this.loading++;
            this.$store
                .dispatch("restaurant/fetchFutureServices", { params: { collect_type: "collect" } })
                .then(() => {
                    lastDayPromise.then(() => {
                        let firstAvailableDate = this.firstAvailableDate;
                        if (moment(firstAvailableDate).startOf("day").isSameOrBefore(moment())) {
                            this.currentDate = firstAvailableDate;
                        } else {
                            this.$emit("noSlotAvailableForToday");
                        }
                    });
                })
                .catch((error) => {
                    this.errors.push(
                        this.$utils.getErrorMsgFromErrorResponse(error, this.$t("errors.init.future_services"))
                    );
                })
                .finally(() => {
                    this.loading--;
                });

            this.loading++;
            this.$store
                .dispatch("restaurant/fetchFutureClosures", { params: { type: "collect" } })
                .catch((error) => {
                    this.errors.push(this.$utils.getErrorMsgFromErrorResponse(error, this.$t("errors.init.closures")));
                })
                .finally(() => {
                    this.loading--;
                });

            this.loading++;
            this.$store
                .dispatch("restaurant/fetchFutureOpenings", { params: { type: "collect" } })
                .catch((error) => {
                    this.errors.push(this.$utils.getErrorMsgFromErrorResponse(error, this.$t("errors.init.openings")));
                })
                .finally(() => {
                    this.loading--;
                });
        },
        slotLabel(slotValue) {
            return slotValue.replace(":", "h").substr(0, 5);
        },
        getDate(date) {
            return moment(date).format("Y-MM-DD");
        },
        datePickerPredictor(date) {
            const hasSlotAtDate = this.getServicesAtDate(date).some((s) => s.slots.data.some((slot) => slot.enabled));
            return !hasSlotAtDate;
        },
        getServicesAtDate(date) {
            const momentCurrentDate = moment(date).startOf("day");
            const hasOpening = this.futureOpenings.some((o) => {
                return (
                    moment(o.start_day).startOf("day").isSameOrBefore(momentCurrentDate) &&
                    moment(o.end_day).startOf("day").isSameOrAfter(momentCurrentDate)
                );
            });
            return this.futureServices.filter((s) => {
                if (hasOpening && !s.is_special) return false;
                const startDay = moment(s.startDay).startOf("day");
                const endDay = moment(s.endDay).startOf("day");

                return (
                    startDay.isSameOrBefore(momentCurrentDate) &&
                    (s.endDay === null || momentCurrentDate.isSameOrBefore(endDay)) &&
                    s.day == momentCurrentDate.day()
                );
            });
        },
    },
    watch: {
        currentDate: {
            immediate: true,
            handler(newVal, oldVal) {
                this.errors = [];

                if (newVal && oldVal && moment(newVal).startOf("day").isSame(moment(oldVal).startOf("day"))) {
                    return;
                }

                this.$emit("setLoading", true);
                this.isLoadingSlot = true;

                this.$store
                    .dispatch("restaurant/fetchSlotsForDay", {
                        params: {
                            date: moment(newVal).format("YYYY-MM-DD"),
                            collect_type: "collect",
                        },
                    })
                    .then(() => {
                        this.$nextTick(() => {
                            if (this.slots.length > 0) {
                                const oldSlotId = this.currentSlot;
                                this.currentSlot = this.slots[0].id;
                                if (oldSlotId === this.currentSlot) this.$emit("slotSelected");
                            } else {
                                this.currentSlot = null;
                                if (typeof oldVal !== "undefined") this.$emit("noProducts");
                            }
                        });
                    })
                    .catch((error) => {
                        this.errors.push(
                            this.$utils.getErrorMsgFromErrorResponse(error, this.$t("errors.init.future_services"))
                        );
                    })
                    .finally(() => {
                        this.$emit("setLoading", false);
                        this.isLoadingSlot = false;
                    });

                let date = moment(newVal);
                this.error = null;

                if (date.isBefore(moment(), "day")) {
                    this.error = this.$t("errors.date.later", { date: moment().format("LL") });
                } else {
                    this.$store.dispatch("cart/setDate", this.getDate(newVal));
                }
            },
        },
        currentSlot(newVal) {
            let slot = this.slots.find((slot) => slot.id === newVal);

            this.$store.dispatch("cart/setSlot", slot || null);

            this.$emit(typeof slot !== "undefined" ? "slotSelected" : "noSlot");
        },
    },
    components: {
        Datepicker,
        LoaderComponent,
    },
    created() {
        this.init();
    },
};
</script>
