import moment from "../../../plugins/moment.js";
import axios from "axios";

export default {
    setName({ commit }, name) {
        commit("setName", name);
    },
    setAddress({ commit }, address) {
        commit("setAddress", address);
    },
    setBgColor({ commit }, bgcolor) {
        commit("setBgColor", bgcolor);
    },
    setWidgetFontTitle({ commit }, widget_font_title) {
        commit("setWidgetFontTitle", widget_font_title);
    },
    setStripeClientId({ commit }, stripe_client_id) {
        commit("setStripeClientId", stripe_client_id);
    },
    setHasCardPaymentSystem({ commit }, has_card_payment_system) {
        commit("setHasCardPaymentSystem", has_card_payment_system);
    },
    setAvailablePaymentMethods({ commit }, methods) {
        commit("setAvailablePaymentMethods", methods);
    },
    setAvailableCollectTypes({ commit }, available_collect_types) {
        commit("setAvailableCollectTypes", available_collect_types);
    },
    setGvEnabled({ commit }, gv_enabled) {
        commit("setGvEnabled", gv_enabled);
    },
    setCgv({ commit }, cgv) {
        commit("setCgv", cgv);
    },
    setDefaultTelCountry({ commit }, default_tel_country) {
        commit("setDefaultTelCountry", default_tel_country);
    },
    setIsGroupPaymentEnable({ commit }, is_group_payment_enable) {
        commit("setIsGroupPaymentEnable", is_group_payment_enable);
    },
    setDisplayPerCategory({ commit }, display_per_category) {
        commit("setDisplayPerCategory", display_per_category);
    },
    setUseBurgerMenu({ commit }, use_burger_menu) {
        commit("setUseBurgerMenu", use_burger_menu);
    },
    resetData({ commit }) {
        commit("setStripeClientId", null);
        commit("setAddress", null);
        commit("setName", null);
        commit("setAvailableCollectTypes", null);
    },
    changeCollectType({ commit }) {
        commit("setSlots", []);
        commit("setLastDayOrderable", null);
        commit("setFutureServices", []);
        commit("setFutureClosures", []);
        commit("setIsTodayAvailable", null);
    },
    fetchFutureServices({ commit }, { store = true, params = {} } = { store: true, params: {} }) {
        params.from = moment().format("YYYY-MM-DD");
        const promise = axios.get(`${API_URL}/${this._vm.$api_key}/services?include=startDay,endDay,slots`, {
            params,
        });

        if (store) {
            promise.then((response) => {
                commit("setFutureServices", response.data.data);
                return response;
            });
        }

        return promise;
    },
    fetchFutureClosures({ commit }, { store = true, params = {} } = { store: true, params: {} }) {
        params.from = moment().format("YYYY-MM-DD");
        const promise = axios.get(`${API_URL}/${this._vm.$api_key}/closures`, {
            params,
        });

        if (store) {
            promise.then((response) => {
                commit("setFutureClosures", response.data.closures);
                return response;
            });
        }

        return promise;
    },
    fetchFutureOpenings({ commit }, { store = true, params = {} } = { store: true, params: {} }) {
        params.from = moment().format("YYYY-MM-DD");
        const promise = axios.get(`${API_URL}/${this._vm.$api_key}/openings`, {
            params,
        });

        if (store) {
            promise.then((response) => {
                commit("setFutureOpenings", response.data.data);
                return response;
            });
        }

        return promise;
    },
    fetchLastDayOrderable({ commit }, { store = true, params = {} } = { store: true, params: {} }) {
        const promise = axios.get(`${API_URL}/${this._vm.$api_key}/lastDay`, {
            params,
        });

        if (store) {
            promise.then((response) => {
                let is_today_available = response.data.is_today_available;
                commit("setLastDayOrderable", response.data.date);
                commit("setIsTodayAvailable", is_today_available === 0);
                commit("setIsTodayAvailableReason", is_today_available === 0 ? null : is_today_available);
                commit("setFirstOrderableDate", response.data.first_orderable_date);
                commit("setPromoCodeEnabled", response.data.promo_code_enabled);
                return response;
            });
        }

        return promise;
    },
    fetchSlotsForDay({ commit }, { store = true, params = {} } = { store: true, params: {} }) {
        const promise = axios.get(`${API_URL}/${this._vm.$api_key}/slots`, {
            params,
        });

        if (store) {
            promise.then((response) => {
                commit("setSlots", response.data.data);
                return response;
            });
        }

        return promise;
    },
    fetchDispatchSettings({ commit }) {
        return axios.get(`${API_URL}/${this._vm.$api_key}/dispatch_config`).then((response) => {
            commit("setDispatchMinAmount", response.data.dispatch_min_amount);
            commit("setDispatchFreeAbove", response.data.dispatch_free_above);
            commit("setSendcloudPublicKey", response.data.sendcloud_public_key);
            commit("setEnableServicePoints", response.data.enable_service_points);
            commit("setPromoCodeEnabled", response.data.promo_code_enabled);
            return response;
        });
    },
    fetchDispatchClosures() {
        return axios.get(`${API_URL}/${this._vm.$api_key}/closures_dispatch`);
    },
};
