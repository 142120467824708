export default {
    products: [],
    slot: null,
    date: null,
    token: null,
    comment: null,
    collectType: null,
    deliveryInfo: null,
    promoCode: null,
    giftVoucher: null,
};
