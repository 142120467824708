var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "nav" },
    _vm._l(_vm.items, function (item) {
      return _c("nav-item-component", {
        key: item.id,
        attrs: {
          item: item,
          "show-active":
            _vm.displayPerCategory &&
            _vm.currentCategory !== null &&
            item.id === _vm.currentCategory.id,
        },
        on: { "go-to": _vm.goTo },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }