var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(_vm.currentComponent, {
    tag: "component",
    attrs: { items: _vm.items, "current-category": _vm.currentCategory },
    on: { "set-current-category": _vm.setCurrentCategory },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }