export default {
    errors: {
        common: {
            unknown: "An error occured",
            required_action: "Required action",
            empty_cart: "Your cart is empty",
            no_slots: "Sorry, no collect slot are available for this day",
            stripe_init: "Sorry, an error occured while loading payment module",
            commands_closed: "Commands are not available for today, order now for the next available slots:",
            commands_ended: "Commands are closed for today, order now for the next available slots: ",
            module_closed: "Orders are not currently available.",
            cant_be_pay: "This command can not be paid anymore",
            already_paid: "This command has already been paid",
            network: "Please check your network connection",
            maintenance: "Widget is not available for now. Please try again later.",
            loading: "An error occured during initialization.",
            copy: {
                default: "{element} could not be copied",
                notSecure: "Browser is not in a secure context, cannot copy {element}",
                notActive: "You do not have permission to copy please enable this feature in your browser",
            },
        },
        init: {
            last_day_orderable: "Unable to fetch last orderable day.",
            future_services: "Unable to fetch future services.",
            closures: "Unable to fetch future closings.",
            openings: "Unable to fetch future openings.",
        },
        address: {
            no_match: "No match",
            invalid: "This address is not valid",
            not_found: "Sorry, this address is not available for delivery",
            no_slot_available: "This address is not available for this delivery slot. Please choose another one.",
        },
        products: {
            quantity: {
                invalid: "Please enter a valid quantity",
                max: 'Sorry, you have reached the maximum quantity for the product "{product}"',
            },
        },
        categories: {
            quantity: {
                max: 'Sorry, you have reached the maximum quantity for the category "{category}"',
            },
        },
        options: {
            required: "Mandatory choice",
            quantity: {
                max: "Sorry, the maximum quantity for the option {option} is {max}",
            },
        },
        date: {
            later: "Please enter a date later than {date}",
        },
        group_payment: {
            command_not_found: "Sorry, this command could not be found",
            email_not_found: "Sorry, this email address does not match any payment",
            captain_cant_pay: "You must wait for all other paiements before processing yours",
            already_paid: "This payment has already been made",
            too_late: "Payment delay outdated",
        },
        service_points: {
            not_selected: "Please select a service point",
        },
        shipping_methods: {
            no_available: "Sorry, no shipping method available for this quantity",
        },
        payment_methods: {
            already_used: "Sorry, this payment method has already been used",
            unavailable: "This payment method is not available",
            edenred: {
                empty_balance: "Your Edenred balance is empty",
            },
        },
    },
    confirm: {
        products: {
            delete: "Are you sure you want to delete this product from your cart ?",
        },
    },
    info: {
        paid: "Your payment has been made !",
        paid_title: "Thanks for your order",
        collect: "Collect",
        delivery: {
            name: "Delivery",
            free: "Free delivery",
            min_amount: "Command's mininum amount:",
            free_above: "Free delivery above:",
            fees: "Delivery fees:",
        },
    },
    loading: "Loading ...",
    labels: {
        copy: "Copy",
        linkIdentifier: "The link",
    },
    success: {
        common: {
            copy: "{element} has been successfully copied",
        },
    },
};
