var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", { staticClass: "nav-item" }, [
    _c(
      "a",
      {
        staticClass: "nav-link",
        style: _vm.showActive
          ? `color: ${_vm.restaurantBgColor} !important; text-decoration: underline;`
          : undefined,
        attrs: { href: `#noshow-cc-category-${_vm.item.id}` },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.goTo($event, _vm.item)
          },
        },
      },
      [_vm._v("\n        " + _vm._s(_vm.item.name) + "\n    ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }