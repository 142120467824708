class Utils {
    getQueryParameter(param) {
        let url = location.href.split("?");
        if (url.length < 2) return null;
        let params = url[1].split("&");
        for (let i = 0; i < params.length; i++) {
            let keyValue = params[i].split("=");
            if (keyValue[0] === param) return keyValue.length > 1 ? keyValue[1] : null;
        }
        return null;
    }

    removeQueryParameter(param) {
        let url = location.href.split("?");
        let newUrl = url[0] + "?";
        if (url.length < 2) return location.href;
        let params = url[1].split("&");
        for (let i = 0; i < params.length; i++) {
            let keyValue = params[i].split("=");
            if (keyValue[0] !== param) {
                newUrl += "&" + keyValue[0];
                if (keyValue[1]) newUrl += "=" + keyValue[1];
            }
        }
        return newUrl;
    }

    formatPrice(price) {
        return Number.parseFloat(price).toFixed(2).toString().replace(".", ",");
    }

    getCategoryName(categoryId, categories) {
        let found = categories.find((c) => c.id === categoryId);
        if (typeof found != "undefined") return found.name;
        return "";
    }

    backNtoSpace(text) {
        return text !== null ? text.replace(/\n+/g, " ") : "";
    }

    formatHour(hour) {
        const hourSplit = hour.split(":");
        if (hourSplit.length < 2) return hour;
        return hourSplit[0] + "h" + hourSplit[1];
    }

    capitalize(s) {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    inEnum(value, enums) {
        return enums.some((e) => e.value === value);
    }

    generateRandomString() {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0,
                v = c == "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    getErrorMsgFromErrorResponse(error, defaultMessage = undefined) {
        if (error && error.response && error.response.data && error.response.data.message) {
            return error.response.data.message;
        }

        if (error && error.message) {
            return error.message.toLowerCase() === "network error" ? this.$t("errors.common.network") : error.message;
        }

        if (typeof error === "string" && error !== "") {
            return error;
        }

        return defaultMessage || this.$t("errors.common.unknown");
    }
}

export default {
    install(Vue) {
        Vue.prototype.$utils = new Utils();
    },
};
