<template>
    <div>
        <loader-component v-if="loading" />
        <template v-else>
            <small class="text-danger d-block" v-if="error">{{ error }}</small>
            <ShowErrors class="d-block" :errors="errors" errorKey="*" />
            <label
                >Méthode d'expédition
                <select class="custom-select mt-2" v-model="shipping_method">
                    <option :value="null" :disabled="true">Sélectionner une méthode d'expédition</option>
                    <option v-for="(sm, index) in shipping_methods" :key="sm.id" :value="sm">
                        {{ sm.name }} -
                        {{ index === 0 && isDispatchFeesFree ? "Offert" : $utils.formatPrice(sm.price / 100) + "€" }}
                    </option>
                </select>
            </label>
            <div class="d-flex justify-content-between">
                <button
                    class="main-button btn-light text-white d-flex mt-2"
                    style="align-self: flex-end"
                    :style="styleCartButton"
                    :disabled="!dispatch.isShippingMethodValidated"
                    @click="$emit('next-step')">
                    Valider
                </button>
            </div>
        </template>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../LoaderComponent.vue";
import ShowErrors from "../errors/ShowErrors.vue";
import PromoCodeTypeEnum from "../../mixins/enums/click_and_collect/PromoCodeTypeEnum.js";

export default {
    data() {
        return {
            loading: false,
            error: null,
            errors: null,
            shipping_methods: [],
        };
    },
    mixins: [PromoCodeTypeEnum],
    props: {
        dispatch: {
            required: true,
        },
    },
    computed: {
        hasValidPromoCode() {
            return this.promoCode != null;
        },
        promoCode() {
            return this.$store.getters["cart/promoCode"];
        },
        products() {
            return this.$store.getters["cart/products"];
        },
        totalAmount() {
            let totalAmount = 0;
            if (this.products.length === 0) return 0;
            return this.$store.getters["cart/totalAmount"];
        },
        totalAmountWithPromoCode() {
            let totalAmount = this.totalAmount * 100;
            if (!this.hasValidPromoCode) return totalAmount;
            let promoCode = this.$store.getters["cart/promoCode"];
            if (promoCode.type === this.PROMO_CODE_TYPE_AMOUNT.value) return totalAmount - promoCode.amount;
            if (promoCode.type === this.PROMO_CODE_TYPE_PERCENT.value)
                return totalAmount - (promoCode.amount * totalAmount) / 100;
            return totalAmount;
        },
        isDispatchFeesFree() {
            const dispatchFreeAbove = this.$store.getters["restaurant/dispatch_free_above"];
            return dispatchFreeAbove && dispatchFreeAbove <= this.totalAmountWithPromoCode;
        },
        shipping_method: {
            get() {
                if (this.dispatch.finalShippingMethod) return this.dispatch.finalShippingMethod;
                return null;
            },
            set(newVal) {
                if (newVal) {
                    this.dispatch.isShippingMethodValidated = true;
                    this.dispatch.finalShippingMethod = newVal;
                } else {
                    this.dispatch.isShippingMethodValidated = false;
                    this.dispatch.finalShippingMethod = null;
                }
            },
        },
        restaurantBgColorOnly() {
            return this.$store.getters["restaurant/bgcolor"];
        },
        styleCartButton() {
            return { "background-color": `${this.restaurantBgColorOnly} !important` };
        },
    },
    methods: {
        fetchShippingMethods() {
            this.loading = true;
            this.error = null;
            this.errors = null;
            this.shipping_method = null;

            axios
                .post(`${API_URL}/${this.$api_key}/shipping_methods`, {
                    cart: { products: this.$store.getters["cart/products"] },
                    dispatch_type: this.dispatch.type,
                    carrier: this.dispatch.servicePointSelected
                        ? this.dispatch.servicePointSelected.carrier
                        : undefined,
                })
                .then((response) => {
                    this.loading = false;
                    this.shipping_methods = response.data.data;
                    if (response.data.data.length == 0) this.error = this.$t("errors.shipping_methods.no_available");
                    else if (response.data.data.length == 1) {
                        this.dispatch.isShippingMethodValidated = true;
                        this.dispatch.finalShippingMethod = response.data.data[0];
                        this.dispatch.finalShippingMethod.is_first = true;
                        this.$emit("next-step");
                    } else {
                        this.shipping_methods[0].is_first = true;
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    if (error.response && error.response.data.message) this.error = error.response.data.message;
                    else if (error.message && error.message.toLowerCase() === "network error")
                        this.error = this.$t("errors.common.network");
                    else if (error.message) this.error = error.message;
                    else this.error = this.$t("errors.common.unknown");
                    if (error.response && error.response.data.errors) this.errors = error.response.data.errors;
                });
        },
    },
    components: {
        LoaderComponent,
        ShowErrors,
    },
    created() {
        this.fetchShippingMethods();
    },
};
</script>
