<template>
    <header id="noshow-cc-header">
        <loader-component v-if="loading > 0" />
        <small class="text-danger" v-if="errors && errors.length > 0" v-html="errors.join('<br/>')"></small>
        <div v-show="loading === 0" class="pickup-info p-3">
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-12">
                            <h5 class="d-inline-block noshow-widget-font-title" :style="restaurantBgColor">Livraison</h5>
                            <button
                                v-if="isDispatchAvailable && canChangeCollectType"
                                class="main-button float-right btn-light d-inline-block ml-3 text-white"
                                style="padding: 0.6em 1em !important"
                                :style="styleCartButton"
                                @click="$emit('changeCollectType', WIDGET_DISPATCH.value)">
                                Expédition à domicile
                            </button>
                            <button
                                v-if="isCollectAvailable && canChangeCollectType"
                                class="main-button float-right btn-light d-inline-block ml-3 text-white"
                                style="padding: 0.6em 1em !important"
                                :style="styleCartButton"
                                @click="$emit('changeCollectType', WIDGET_COLLECT.value)">
                                Retrait sur place
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6" style="margin-top: 10px !important">
                            <label class="mr-3">Date de livraison</label>
                            <datepicker
                                format="dd/MM/yyyy"
                                :use-utc="true"
                                v-model="currentDate"
                                :disabled-dates="disabledDates"
                                calendar-class="positition-relative z-2000 mt-2"
                                input-class="w-auto"
                                class="date-resa-cal date-resa"
                                :monday-first="true"
                                :language="fr"></datepicker>
                        </div>
                        <div class="col-md-6" style="margin-top: 10px !important">
                            <label>Créneau de livraison</label>
                            <select v-if="slots.length > 0" class="custom-select" style="margin-top: -2px !important" v-model="currentSlot">
                                <option v-for="slot in slots" :key="slot.id" :value="slot.id">
                                    {{ slotLabel(slot.hour_start) }} - {{ slotLabel(slot.hour_end) }}
                                </option>
                            </select>
                            <select disabled="disabled" v-else class="custom-select disabled" style="margin-top: -2px !important">
                                <option>Aucun créneau disponible</option>
                            </select>
                        </div>
                    </div>
                    <div class="row" style="margin-top: 10px !important" v-if="currentSlot">
                        <div class="col-12">
                            <label v-if="!isAddressValidated"
                                >Adresse de livraison
                                <input
                                    :disabled="isValidatingAddress"
                                    type="text"
                                    name="address"
                                    placeholder="Saisissez votre adresse"
                                    class="form-control mt-2"
                                    autofocus
                                    @input="clientAddressChanged"
                                    v-model="clientAddress" />
                            </label>
                            <small class="text-danger" v-if="errorAddress">{{ errorAddress }}</small>
                            <div class="address_results border-light" v-if="addresses_.length > 0">
                                <span @click="selectAddress(address)" class="d-block mb-1 mt-1" v-for="(address, index) in addresses_" :key="index">
                                    {{ address.description }}
                                </span>
                            </div>
                            <button
                                class="main-button btn-light text-white d-flex mt-2"
                                v-if="!isAddressValidated"
                                style="align-self: flex-end"
                                :style="styleCartButton"
                                :disabled="finalClientAddress === null || isValidatingAddress || isLoadingSlot"
                                @click="validateAddress">
                                Valider
                            </button>
                            <template v-if="isAddressValidated">
                                <label>Adresse de livraison</label><br />
                                <span style="font-size: 13px !important">{{ clientAddress }}</span>
                                <button
                                    class="main-button btn-light text-white d-flex mt-2"
                                    style="align-self: flex-end"
                                    :style="styleCartButton"
                                    @click="changeAddress">
                                    Changer d'adresse
                                </button>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="pickup-point p-3" style="background-color: #f7f7f7">
                        <div class="row">
                            <div class="col-12">
                                <h5 class="noshow-widget-font-title">Informations de l'établissement</h5>
                                <h6 :style="'color: ' + restaurantBgColorOnly + ' !important;'">
                                    <strong class="noshow-widget-font-title">{{ restaurantName }}</strong>
                                </h6>
                                <p>{{ restaurantAddress }}</p>
                            </div>
                            <div class="col-12 mt-2" v-if="isAddressValidated && deliveryInfoForClient">
                                <h6 class="noshow-widget-font-title">Informations de livraison</h6>
                                <p v-html="deliveryInfoForClient"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import axios from "axios";
import LoaderComponent from "../LoaderComponent.vue";
import moment from "../../plugins/moment.js";
import WidgetTypesEnum from "../../mixins/enums/click_and_collect/WidgetTypesEnum.js";

export default {
    data() {
        return {
            loading: 0,
            isValidatingAddress: false,
            isLoadingSlot: false,
            isAddressValidated: false,
            errors: [],
            currentSlot: this.$store.getters["cart/slot"] !== null ? this.$store.getters["cart/slot"].id : null,
            currentDate: this.$store.getters["cart/date"] !== null ? this.$store.getters["cart/date"] : new Date(),
            selectedDay: null,
            clientAddress: this.$store.getters["client/addressForClient"],
            addresses: [],
            errorAddress: null,
            finalClientAddress: this.$store.getters["client/address"],
            sessionToken: null,
        };
    },
    mixins: [WidgetTypesEnum],
    props: {
        canChangeCollectType: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        deliveryInfoForClient() {
            if (this.deliveryInfo === null) return "";
            const deliveryInfo = this.deliveryInfo.cc_delivery_area;
            let infos = [];
            if (deliveryInfo.min_amount !== null)
                infos.push(`${this.$t("info.delivery.min_amount")} ${this.$utils.formatPrice(deliveryInfo.min_amount)}€`);
            if (deliveryInfo.is_free) infos.push(`${this.$t("info.delivery.free")}`);
            else if (deliveryInfo.price) infos.push(`${this.$t("info.delivery.fees")} ${this.$utils.formatPrice(deliveryInfo.price)}€`);
            if (deliveryInfo.free_above !== null && !deliveryInfo.is_free)
                infos.push(`${this.$t("info.delivery.free_above")} ${this.$utils.formatPrice(deliveryInfo.free_above)}€`);
            return infos.join("<br />");
        },
        deliveryInfo() {
            return this.$store.getters["cart/deliveryInfo"];
        },
        addresses_() {
            return this.addresses;
        },
        isCollectAvailable() {
            return this.$store.getters["restaurant/isCollectAvailable"];
        },
        isDispatchAvailable() {
            return this.$store.getters["restaurant/isDispatchAvailable"];
        },
        restaurantBgColorOnly() {
            return this.$store.getters["restaurant/bgcolor"];
        },
        fr() {
            return fr;
        },
        styleCartButton() {
            return { "background-color": `${this.restaurantBgColorOnly} !important` };
        },
        disabledDates() {
            let firstAvailableDate = this.firstAvailableDate;
            let today = moment().startOf("day");
            if (this.isTodayOrderable) today.subtract(1, "day");
            const closuresRanges = this.futureClosures.map((c) => {
                let from = moment(c.start_day).startOf("day");
                let to = moment(c.end_day).add(1, "day").startOf("day");
                return {
                    from: from.toDate(),
                    to: to.toDate(),
                };
            });

            var result = {
                to: firstAvailableDate ? moment(firstAvailableDate).startOf("day").toDate() : today,
                ranges: closuresRanges,
                customPredictor: this.datePickerPredictor,
            };

            if (this.lastDayOrderable) {
                result.from = moment(this.lastDayOrderable).toDate();
            }
            return result;
        },
        restaurantName() {
            return this.$store.getters["restaurant/name"];
        },
        restaurantAddress() {
            return this.$store.getters["restaurant/address"];
        },
        restaurantBgColor() {
            return { color: this.$store.getters["restaurant/bgcolor"] };
        },
        futureClosures() {
            return this.$store.getters["restaurant/futureClosures"];
        },
        futureServices() {
            return this.$store.getters["restaurant/futureServices"];
        },
        futureOpenings() {
            return this.$store.getters["restaurant/futureOpenings"];
        },
        slots() {
            let slots = this.$store.getters["restaurant/slots"];
            if (slots !== null) return slots;
            return null;
        },
        lastDayOrderable() {
            return this.$store.getters["restaurant/lastDayOrderable"];
        },
        isTodayOrderable() {
            return this.$store.getters["restaurant/isTodayAvailable"];
        },
        firstAvailableDate() {
            let firstOrderableDate = this.$store.getters["restaurant/firstOrderableDate"];
            if (firstOrderableDate) return moment(firstOrderableDate).startOf("day").add(3, "hour").toDate();
            return new Date();
        },
    },
    methods: {
        changeAddress() {
            // this.$emit('noSlot');
            this.isAddressValidated = false;
        },
        setFirstAvailableDate() {
            this.currentDate = this.firstAvailableDate;
        },
        init() {
            this.errors = [];
            this.loading = 0;

            this.loading++;
            const lastDayPromise = this.$store
                .dispatch("restaurant/fetchLastDayOrderable", { params: { collect_type: "delivery" } })
                .catch((error) => {
                    this.errors.push(this.$utils.getErrorMsgFromErrorResponse(error, this.$t("errors.init.last_day_orderable")));
                })
                .finally(() => {
                    this.loading--;
                });

            this.loading++;
            this.$store
                .dispatch("restaurant/fetchFutureServices", { params: { collect_type: "delivery" } })
                .then(() => {
                    lastDayPromise.then(() => {
                        let firstAvailableDate = this.firstAvailableDate;
                        if (moment(firstAvailableDate).startOf("day").isSameOrBefore(moment())) this.currentDate = firstAvailableDate;
                        else this.$emit("noSlotAvailableForToday");
                    });
                })
                .catch((error) => {
                    this.errors.push(this.$utils.getErrorMsgFromErrorResponse(error, this.$t("errors.init.future_services")));
                })
                .finally(() => {
                    this.loading--;
                });

            this.loading++;
            this.$store
                .dispatch("restaurant/fetchFutureClosures", { params: { type: "delivery" } })
                .catch((error) => {
                    this.errors.push(this.$utils.getErrorMsgFromErrorResponse(error, this.$t("errors.init.closures")));
                })
                .finally(() => {
                    this.loading--;
                });

            this.loading++;
            this.$store
                .dispatch("restaurant/fetchFutureOpenings", { params: { type: "delivery" } })
                .catch((error) => {
                    this.errors.push(this.$utils.getErrorMsgFromErrorResponse(error, this.$t("errors.init.openings")));
                })
                .finally(() => {
                    this.loading--;
                });
        },
        slotLabel(slotValue) {
            return slotValue.replace(":", "h").substr(0, 5);
        },
        getDate(date) {
            return moment(date).format("Y-MM-DD");
        },
        datePickerPredictor(date) {
            const hasSlotAtDate = this.getServicesAtDate(date).length > 0;
            return !hasSlotAtDate;
        },
        getServicesAtDate(date) {
            const momentCurrentDate = moment(date).startOf("day");
            const hasOpening = this.futureOpenings.some((o) => {
                return (
                    moment(o.start_day).startOf("day").isSameOrBefore(momentCurrentDate) &&
                    moment(o.end_day).startOf("day").isSameOrAfter(momentCurrentDate)
                );
            });
            return this.futureServices.filter((s) => {
                if (hasOpening && !s.is_special) return false;
                const startDay = moment(s.startDay).startOf("day");
                const endDay = moment(s.endDay).startOf("day");

                return (
                    startDay.isSameOrBefore(momentCurrentDate) &&
                    (s.endDay === null || momentCurrentDate.isSameOrBefore(endDay)) &&
                    s.day == momentCurrentDate.day()
                );
            });
        },
        searchAddress(oldAddressVal) {
            if (this.clientAddress !== oldAddressVal) return;

            if (!this.sessionToken) {
                this.sessionToken = this.$utils.generateRandomString();
            }

            axios
                .get(`${API_URL}/${this.$api_key}/searchAddress?query=${this.clientAddress}&sessionToken=${this.sessionToken}`)
                .then((response) => {
                    this.errorAddress = null;
                    this.addresses = response.data.data;
                    if (this.addresses_.length === 0) {
                        this.errorAddress = this.$t("errors.address.no_match");
                    }
                })
                .catch((error) => {
                    this.addresses = [];
                    this.errorAddress = this.$utils.getErrorMsgFromErrorResponse(error);
                });
        },
        clientAddressChanged(e) {
            this.finalClientAddress = null;
            const newVal = e.target.value;

            if (newVal.length < 5) {
                this.addresses = [];
                this.errorAddress = null;
                this.sessionToken = null;

                return;
            }

            setTimeout(() => {
                this.searchAddress(newVal);
            }, 600);
        },
        selectAddress(address) {
            this.finalClientAddress = address;
            this.clientAddress = address.description;
            this.addresses = [];
            this.errorAddress = null;
        },
        validateAddress(thenFct = null) {
            if (!this.currentSlot) return;

            if (this.finalClientAddress != null) {
                this.isValidatingAddress = true;
                // this.$emit('setLoading', true);
                this.errorAddress = null;
                this.$store
                    .dispatch("client/validateAddress", {
                        ...this.finalClientAddress,
                        reservation_date: moment(this.currentDate).format("Y-MM-DD"),
                        delivery_slot_id: this.currentSlot,
                        sessionToken: this.sessionToken,
                    })
                    .then((response) => {
                        // this.$emit('setLoading', false);
                        this.isValidatingAddress = false;
                        this.isAddressValidated = true;
                        this.sessionToken = null;
                        this.$store.dispatch("cart/setDeliveryInfo", response.data.deliveryCity);
                        // this.$emit('slotSelected');
                        if (typeof thenFct === "function") thenFct();
                    })
                    .catch((error) => {
                        // this.$emit('setLoading', false);
                        this.isValidatingAddress = false;
                        this.errorAddress = this.$t("errors.common.unknown");
                        if (error.response && error.response.data.reason) {
                            switch (error.response.data.reason) {
                                case "no_config":
                                    this.errorAddress = this.$t("errors.common.module_closed");
                                    break;
                                case "not_found":
                                    this.errorAddress = this.$t("errors.address.not_found");
                                    break;
                                case "no_slot_available":
                                    this.errorAddress = this.$t("errors.address.no_slot_available");
                                    break;
                                case "bad_request":
                                    this.errorAddress = this.$t("errors.address.invalid");
                                    break;
                                default:
                                    this.errorAddress = this.$t("errors.common.unknown");
                                    break;
                            }
                        } else if (error.message && error.message.toLowerCase() == "network error") {
                            this.errorAddress = this.$t("errors.common.network");
                        } else if (error.response && error.response.data.message) {
                            this.errorAddress = error.response.data.message;
                        } else if (error.message) {
                            this.errorAddress = error.message;
                        }
                    });
            } else {
                this.errorAddress = this.$t("errors.address.invalid");
            }
        },
    },
    watch: {
        currentDate: {
            immediate: true,
            handler(newVal, oldVal) {
                if (this.isAddressValidated) this.changeAddress();
                this.errors = [];
                this.isLoadingSlot = true;
                this.$store
                    .dispatch("restaurant/fetchSlotsForDay", {
                        params: {
                            date: moment(newVal).format("YYYY-MM-DD"),
                            collect_type: "delivery",
                        },
                    })
                    .then(() => {
                        this.$nextTick(() => {
                            if (this.slots.length > 0) {
                                const oldSlotId = this.currentSlot;
                                this.currentSlot = this.slots[0].id;
                                if (oldSlotId === this.currentSlot) this.$emit("slotSelected");
                            } else {
                                this.currentSlot = null;
                                if (typeof oldVal !== "undefined") this.$emit("noSlot");
                            }
                        });
                    })
                    .catch((error) => {
                        this.errors.push(this.$utils.getErrorMsgFromErrorResponse(error, this.$t("errors.init.future_services")));
                    })
                    .finally(() => {
                        this.isLoadingSlot = false;
                    });
                let date = moment(newVal);
                this.error = null;
                if (date.isBefore(moment(), "day")) this.error = this.$t("errors.date.later", { date: moment().format("LL") });
                else this.$store.dispatch("cart/setDate", this.getDate(newVal));
            },
        },
        currentSlot(newVal) {
            if (this.isAddressValidated) this.changeAddress();
            let slot = this.slots.find((slot) => slot.id === newVal);
            this.$store.dispatch("cart/setSlot", typeof slot != "undefined" ? slot : null);
            if (typeof slot === "undefined") this.$emit("noSlot");
            // else if (this.isAddressValidated)
            else this.$emit("slotSelected");
        },
        isAddressValidated(newVal) {
            this.$emit("address-validated", newVal);
        },
    },
    components: {
        Datepicker,
        LoaderComponent,
    },
    created() {
        this.init();
    },
};
</script>

<style>
.address_results {
    position: absolute;
    z-index: 9999;
    background-color: white;
    cursor: pointer;
}
</style>
