<template>
    <modal @close="closeFromModal">
        <div slot="header" class="border-bottom w-100" style="position: relative">
            <div class="w-100 p-3">
                <div class="d-flex justify-content-between">
                    <h5 class="mt-2 noshow-widget-font-title" :style="basicRestaurantBgColor">
                        {{ $t("errors.common.required_action") }}
                    </h5>
                    <button @click="close" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="text-black" aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
        </div>
        <div slot="body" class="p-3">
            <div class="row">
                <div class="col-12">
                    <ShowErrors class="d-block" :errors="errors" errorKey="*" />
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="w-100 p-3 border-top text-right">
                <button @click="close" class="main-button" :style="buttonRestaurantBgColor">Fermer</button>
            </div>
        </div>
    </modal>
</template>

<script>
import ShowErrors from "../errors/ShowErrors.vue";

export default {
    data() {
        return {};
    },
    computed: {
        restaurantBgColor() {
            return this.$store.getters["restaurant/bgcolor"];
        },
        buttonRestaurantBgColor() {
            return { background: this.restaurantBgColor };
        },
        basicRestaurantBgColor() {
            return { color: `${this.restaurantBgColor} !important` };
        },
    },
    props: {
        errors: {
            default: null,
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            e.stopPropagation();
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        ShowErrors,
    },
};
</script>
