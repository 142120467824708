var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-inline" },
    [
      _c("feather", {
        staticClass: "loader-rotate ml-1 text-muted",
        attrs: { type: "refresh-cw" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }