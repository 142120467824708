var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.hasValidGiftVoucher && _vm.gvEnabled
      ? _c(
          "form",
          { staticClass: "row", on: { submit: _vm.validateGiftVoucher } },
          [
            _c("div", { staticClass: "col-8 mb-2" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.tmpGiftVoucher,
                    expression: "tmpGiftVoucher",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", placeholder: "Chèque cadeau" },
                domProps: { value: _vm.tmpGiftVoucher },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.tmpGiftVoucher = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-4 mb-2" }, [
              _c(
                "button",
                {
                  staticClass:
                    "main-button btn-light d-inline-block text-white",
                  style: _vm.styleCartButton,
                  attrs: {
                    disabled: _vm.loading || !_vm.tmpGiftVoucher,
                    type: "submit",
                  },
                },
                [_vm._v("\n                OK\n            ")]
              ),
            ]),
            _vm._v(" "),
            _c("ShowErrors", {
              staticClass: "col-12",
              attrs: { errors: _vm.giftVoucherErrors, errorKey: "*" },
            }),
          ],
          1
        )
      : _vm.hasValidGiftVoucher && _vm.gvEnabled
      ? _c("span", { staticClass: "text-muted d-block mb-2" }, [
          _c("small", [
            _vm._v("Chèque cadeau : " + _vm._s(_vm.giftVoucherDisplayable)),
          ]),
          _c(
            "button",
            {
              staticClass:
                "btn btn-sm text-danger p-0 pl-1 btn-delete-item d-inline-block",
              staticStyle: { "margin-top": "-2px !important" },
              attrs: { type: "button" },
              on: { click: _vm.resetGiftVoucher },
            },
            [_vm._v("\n            +\n        ")]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }