<template>
    <div>
        <span v-html="custom_css"></span>
        <LoaderComponent v-if="loadingRestaurant" />
        <div class="text-center" v-else-if="errorRestaurant">
            {{ errorRestaurant }} <br />
            <small>Pour toutes informations complémentaires, merci de nous contacter par téléphone au {{ restaurantTel }}</small>
        </div>
        <component
            v-else-if="currentHeaderComponent"
            :is="currentHeaderComponent"
            ref="headerComponent"
            :canChangeCollectType="canChangeCollectType"
            @noSlotAvailableForToday="$emit('noSlotAvailableForToday', $event)"
            @slotSelected="$emit('slotSelected', $event)"
            @noSlot="$emit('noSlot', $event)"
            @setLoading="$emit('setLoading', $event)"
            @noProducts="$emit('noProducts', $event)"
            @changeCollectType="changeCollectType"
            @widget-message="$emit('widget-message', $event)"
            @widget-closed="$emit('widget-closed', $event)"
            @address-validated="$emit('address-validated', $event)" />
    </div>
</template>

<script>
import HeaderCollectComponent from "./Headers/HeaderCollectComponent.vue";
import HeaderDeliveryComponent from "./Headers/HeaderDeliveryComponent.vue";
import HeaderDispatchComponent from "./Headers/HeaderDispatchComponent.vue";
import LoaderComponent from "./LoaderComponent.vue";
import axios from "axios";
import WidgetTypesEnum from "../mixins/enums/click_and_collect/WidgetTypesEnum.js";

export default {
    data() {
        return {
            loadingRestaurant: false,
            errorRestaurant: null,
            collectType: this.$store.getters["cart/collectType"],
            custom_css: null,
        };
    },
    mixins: [WidgetTypesEnum],
    computed: {
        widgetFontTitle() {
            return this.$store.getters["restaurant/widgetFontTitle"];
        },
        canChangeCollectType() {
            return !this.ALL_WIDGET_TYPES.some((t) => t.value === this.$store.getters["widget/type"]);
        },
        currentHeaderComponent() {
            switch (this.collectType) {
                case this.WIDGET_COLLECT.value:
                    return "HeaderCollectComponent";
                case this.WIDGET_DELIVERY.value:
                    return "HeaderDeliveryComponent";
                case this.WIDGET_DISPATCH.value:
                    return "HeaderDispatchComponent";
                default:
                    return null;
            }
        },
        restaurantTel() {
            return this.$store.getters["restaurant/tel"];
        },
    },
    methods: {
        changeCollectType(collectType) {
            this.$store.dispatch("cart/clear");
            this.$store.dispatch("restaurant/changeCollectType");
            this.$emit("changeCollectType");
            this.collectType = collectType;
        },
        setFirstAvailableDate() {
            this.$refs.headerComponent.setFirstAvailableDate();
        },
        fetchRestaurant() {
            this.loadingRestaurant = true;
            this.errorRestaurant = null;

            axios
                .get(`${API_URL}/${this.$api_key}/restaurant`)
                .then((response) => {
                    this.loadingRestaurant = false;
                    this.$store.dispatch("restaurant/setName", response.data.name);
                    this.$store.dispatch("restaurant/setGvEnabled", response.data.gv_enabled);
                    this.$store.dispatch("restaurant/setAddress", response.data.formatted_address);
                    this.$store.dispatch("restaurant/setBgColor", response.data.bgcolor);
                    this.$store.dispatch("restaurant/setWidgetFontTitle", response.data.widget_font_title);
                    this.$store.commit("restaurant/setTel", response.data.tel);
                    this.$store.commit("restaurant/setEmail", response.data.email);
                    this.$store.dispatch("restaurant/setStripeClientId", response.data.stripe_client_id);
                    this.$store.dispatch("restaurant/setHasCardPaymentSystem", response.data.has_card_payment_system);
                    this.$store.dispatch("restaurant/setAvailableCollectTypes", response.data.available_collect_types);
                    this.$store.commit("restaurant/setCgv", response.data.cgv);
                    this.$store.commit("restaurant/setDefaultTelCountry", response.data.default_tel_country);
                    this.$store.commit("restaurant/setDisplayPerCategory", response.data.display_per_category);
                    this.$store.commit("restaurant/setUseBurgerMenu", response.data.use_burger_menu);
                    this.custom_css = `<style>
                        .noshow-widget .noshow-widget-font-title {
                            font-family: ${response.data.widget_font_title} !important;
                        }
                    </style>`;
                    if (response.data.available_collect_types.length > 0) {
                        if (this.canChangeCollectType) {
                            this.collectType = response.data.available_collect_types[0];
                            return;
                        }
                        if (response.data.available_collect_types.some((t) => t === this.$store.getters["widget/type"])) {
                            this.collectType = this.$store.getters["widget/type"];
                        } else {
                            const typesConcat = response.data.available_collect_types.map((t) => `"${t}"`).join(", ");
                            console.error(
                                `NoShow Widget: Widget type "${this.$store.getters["widget/type"]}" is not enabled, expected one of: ${typesConcat}`
                            );
                            this.collectType = response.data.available_collect_types[0];
                        }
                    } else {
                        this.errorRestaurant = this.$t("errors.common.module_closed");
                    }
                })
                .catch((error) => {
                    this.loadingRestaurant = false;
                    this.$store.dispatch("restaurant/resetData");
                    this.errorRestaurant =
                        error.response && error.response.data.message ? error.response.data.message : this.$t("errors.common.unknown");
                });
        },
    },
    created() {
        this.fetchRestaurant();
    },
    watch: {
        collectType(newVal) {
            this.$store.dispatch("cart/clear");
            this.$store.dispatch("cart/setCollectType", newVal);
        },
    },
    components: {
        HeaderCollectComponent,
        HeaderDeliveryComponent,
        HeaderDispatchComponent,
        LoaderComponent,
    },
};
</script>

<style>
.z-2000 {
    z-index: 2000 !important;
}
</style>
