var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c("HeaderComponent", {
        ref: "headerComponent",
        on: {
          noSlotAvailableForToday: function ($event) {
            _vm.products = null
            _vm.noSlotAvailableForToday = true
          },
          changeCollectType: _vm.changeCollectType,
          slotSelected: _vm.fetchData,
          setLoading: function ($event) {
            _vm.loading = $event
          },
          noProducts: _vm.noProductsFct,
          noSlot: function ($event) {
            _vm.products = null
          },
          "widget-message": function ($event) {
            _vm.widgetMessage = $event
          },
          "widget-closed": function ($event) {
            _vm.widgetClosed = true
          },
          "address-validated": function ($event) {
            _vm.addressValidated = $event
          },
        },
      }),
      _vm._v(" "),
      _c("div", [
        _c(
          "div",
          { staticClass: "row m-0 mt-3" },
          [
            _vm.loading
              ? _c("loader-component", { staticStyle: { margin: "auto" } })
              : _vm._e(),
            _vm._v(" "),
            _vm.widgetMessage && !_vm.loading
              ? _c(
                  "div",
                  {
                    staticClass: "col-12 text-white text-center p-3",
                    style: _vm.styleAddButton,
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.widgetMessage) +
                        "\n            "
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row" },
          [
            _vm.errorCategory
              ? _c("div", [
                  _c("h6", { staticClass: "mb-4 text-center text-danger" }, [
                    _vm._v(_vm._s(_vm.errorCategory)),
                  ]),
                ])
              : _vm.products !== null && !_vm.loading && !_vm.widgetClosed
              ? [
                  _c("div", { staticClass: "col-xl-9 col-lg-8 mt-3 mt-md-0" }, [
                    _c(
                      "div",
                      [
                        _c("nav-component", {
                          attrs: {
                            items: _vm.activeCategories,
                            "current-category": _vm.currentCategory,
                          },
                          on: {
                            "set-current-category": function ($event) {
                              _vm.currentCategory = $event
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm._l(_vm.categoriesToDisplay, function (category) {
                          return _c("ProductsCategoryComponent", {
                            key: category.id,
                            attrs: {
                              "hide-category-name": _vm.displayPerCategory,
                              category: category,
                              products: _vm.products,
                              categories: _vm.categories,
                              addressValidated: _vm.addressValidated_,
                            },
                          })
                        }),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-xl-3 col-lg-4" },
                    [
                      _c(_vm.cartComponent, {
                        tag: "component",
                        attrs: {
                          addressValidated: _vm.addressValidated_,
                          categories: _vm.activeCategories,
                        },
                        on: { paid: _vm.paid, saved: _vm.saved },
                      }),
                    ],
                    1
                  ),
                ]
              : !_vm.loading && _vm.noSlotAvailableForToday
              ? _c(
                  "div",
                  {
                    staticClass:
                      "col-12 no-slot-for-current text-center mt-5 mb-5",
                  },
                  [
                    _c("h6", { staticClass: "mb-4 text-center" }, [
                      _vm._v(_vm._s(_vm.noSlotAvailableForTodayReason)),
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "main-button text-center",
                        style: _vm.styleAddButton,
                        on: { click: _vm.setFirstAvailableDate },
                      },
                      [
                        _vm._v(
                          "\n                    Prochain créneau disponible\n                "
                        ),
                      ]
                    ),
                  ]
                )
              : !_vm.loading && _vm.noProducts
              ? _c(
                  "div",
                  {
                    staticClass:
                      "col-12 no-slot-for-current text-center mt-5 mb-5",
                  },
                  [
                    _c("h6", { staticClass: "mb-4 text-center" }, [
                      _vm._v("Aucun produit n'est disponible"),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("footer-component"),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _vm.showPaymentConfirmationModal
        ? _c("PaymentConfirmationModal", {
            on: { close: _vm.closePaymentConfirmationModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }