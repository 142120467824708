export default {
    errors: {
        common: {
            unknown: "Une erreur est survenue",
            required_action: "Action requise",
            empty_cart: "Votre panier est vide",
            no_slots: "Désolé, aucun créneau de retrait n'est disponible pour ce jour",
            stripe_init: "Désolé, une erreur est survenue lors du chargement du module de paiement",
            commands_closed: "Les commandes ne sont pas disponibles pour aujourd'hui, commandez dès maintenant pour les prochains créneaux :",
            commands_ended: "Les commandes sont terminées pour aujourd'hui, commandez dès maintenant pour les prochains créneaux disponibles :",
            module_closed: "Les commandes ne sont pas disponibles actuellement.",
            cant_be_pay: "Cette commande ne peut plus être payée",
            already_paid: "Cette commande a déjà été payée",
            network: "Veuillez vérifier votre connexion internet",
            maintenance: "Le widget est actuellement indisponible. Merci de réessayer plus tard.",
            loading: "Une erreur est survenue au chargement",
            copy: {
                default: "{element} n'a pas pu être copié",
                notSecure: "Le navigateur n'est pas dans un contexte sécurisé, impossible de copier {element}",
                notActive: "Vous n'avez pas la permission de copier merci d'activer cette fonctionnalité sur votre navigateur",
            },
        },
        init: {
            last_day_orderable: "Une erreur est survenue au chargement de la période d'ouverture des commandes.",
            future_services: "Une erreur est survenue à la récupération des disponibilités.",
            closures: "Une erreur est survenue à la récupération des jours de fermeture.",
            openings: "Une erreur est survenue à la récupération des jours d'ouverture",
        },
        address: {
            no_match: "Aucun correspondance",
            invalid: "Cette addresse n'est pas valide",
            not_found: "Désolé, cette adresse n'est pas disponible à la livraison",
            no_slot_available: "Cette adresse n'est pas disponible pour ce créneau de livraison. Veuillez en choisir un autre.",
        },
        products: {
            quantity: {
                invalid: "Merci de saisir une quantité valide",
                max: 'Désolé, vous avez atteint la quantité maximale pour le produit "{product}"',
            },
        },
        categories: {
            quantity: {
                max: 'Désolé, vous avez atteint la quantité maximale pour la catégorie "{category}"',
            },
        },
        options: {
            required: "Choix obligatoire",
            quantity: {
                max: "Désolé, la quantité maximale pour l'option {option} est de {max}",
            },
        },
        date: {
            later: "Merci de saisir une date ultérieure au {date}",
        },
        group_payment: {
            command_not_found: "Désolé, cette commande est introuvable",
            email_not_found: "Désolé, cette adresse email ne correspond à aucun paiement",
            captain_cant_pay: "Vous devez attendre tous les paiements avant de procéder au vôtre",
            already_paid: "Ce paiement a déjà été effectué",
            too_late: "Délai de paiement dépassé",
        },
        service_points: {
            not_selected: "Merci de sélectionner un point relais",
        },
        shipping_methods: {
            no_available: "Désolé, aucune offre de livraison n'est disponible pour cette quantité de produits",
        },
        payment_methods: {
            already_used: "Désolé, ce moyen de paiement a déjà été utilisé",
            unavailable: "Ce moyen de paiement ne peut pas être utilisé",
            edenred: {
                empty_balance:
                    "Vous ne possédez pas assez d'argent sur votre compte Edenred pour effectuer cette transaction. Veuillez choisir un autre moyen de paiement.",
            },
        },
    },
    confirm: {
        products: {
            delete: "Êtes vous sûr de vouloir supprimer ce produit ?",
        },
    },
    info: {
        paid: "Votre paiement a bien été pris en compte.",
        paid_title: "Merci pour votre commande",
        collect: "Retrait",
        delivery: {
            name: "Livraison",
            free: "Livraison gratuite",
            min_amount: "Montant minimum de commande :",
            free_above: "Livraison gratuite à partir de :",
            fees: "Frais de livraison :",
        },
    },
    loading: "Veuillez patienter...",
    labels: {
        copy: "Copier",
        linkIdentifier: "Le lien",
    },
    success: {
        common: {
            copy: "{element} a bien été copié",
        },
    },
};
