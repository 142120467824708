export default {
    setName(state, name) {
        state.name = name;
    },
    setAddress(state, address) {
        state.address = address;
    },
    setBgColor(state, bgcolor) {
        state.bgcolor = bgcolor;
    },
    setWidgetFontTitle(state, widget_font_title) {
        state.widget_font_title = widget_font_title;
    },
    setStripeClientId(state, stripe_client_id) {
        state.stripe_client_id = stripe_client_id;
    },
    setHasCardPaymentSystem(state, has_card_payment_system) {
        state.has_card_payment_system = has_card_payment_system;
    },
    setAvailableCollectTypes(state, available_collect_types) {
        state.available_collect_types = available_collect_types;
    },
    setGvEnabled(state, gv_enabled) {
        state.gv_enabled.collect = gv_enabled && gv_enabled.collect;
        state.gv_enabled.delivery = gv_enabled && gv_enabled.delivery;
        state.gv_enabled.dispatch = gv_enabled && gv_enabled.dispatch;
    },
    setFutureServices(state, futureServices) {
        state.futureServices = futureServices;
    },
    setFutureClosures(state, futureClosures) {
        state.futureClosures = futureClosures;
    },
    setFutureOpenings(state, futureOpenings) {
        state.futureOpenings = futureOpenings;
    },
    setLastDayOrderable(state, lastDayOrderable) {
        state.lastDayOrderable = lastDayOrderable;
    },
    setSlots(state, slots) {
        state.slots = slots;
    },
    setEmail(state, email) {
        state.email = email;
    },
    setTel(state, tel) {
        state.tel = tel;
    },
    setCgv(state, cgv) {
        state.cgv = cgv;
    },
    setIsTodayAvailable(state, is_today_available) {
        state.is_today_available = is_today_available;
    },
    setIsTodayAvailableReason(state, is_today_available_reason) {
        state.is_today_available_reason = is_today_available_reason;
    },
    setIsGroupPaymentEnable(state, is_group_payment_enable) {
        state.is_group_payment_enable = is_group_payment_enable;
    },
    setFirstOrderableDate(state, first_orderable_date) {
        state.first_orderable_date = first_orderable_date;
    },
    setDispatchMinAmount(state, dispatch_min_amount) {
        state.dispatch_min_amount = dispatch_min_amount;
    },
    setDispatchFreeAbove(state, dispatch_free_above) {
        state.dispatch_free_above = dispatch_free_above;
    },
    setSendcloudPublicKey(state, sendcloud_public_key) {
        state.sendcloud_public_key = sendcloud_public_key;
    },
    setEnableServicePoints(state, enable_service_points) {
        state.enable_service_points = enable_service_points;
    },
    setPromoCodeEnabled(state, promo_code_enabled) {
        state.promo_code_enabled = promo_code_enabled;
    },
    setAvailablePaymentMethods(state, methods) {
        state.available_payment_methods = methods;
    },
    setDefaultTelCountry(state, default_tel_country) {
        state.default_tel_country = default_tel_country;
    },
    setDisplayPerCategory(state, display_per_category) {
        state.display_per_category = display_per_category;
    },
    setUseBurgerMenu(state, use_burger_menu) {
        state.use_burger_menu = use_burger_menu;
    },
};
