<template>
    <div class="d-flex flex-column align-items-center justify-content-center">
        <h5>Le widget est désactivé pour cet établissement.</h5>
    </div>
</template>

<script>
export default {
    name: "LicencesErrors",
};
</script>
