<template>
    <div class="product mb-3" v-bind:class="{ disable: productInactive }">
        <div class="row m-0" @click="showModal = productInactive ? false : true">
            <div class="col-8 pt-3 pb-2 product-infos">
                <h6 class="product-title noshow-widget-font-title" :style="restaurantBgColor">{{ product.name }}</h6>
                <p
                    class="product-description"
                    v-if="product.description != null && product.description.length < 70"
                    v-html="$utils.backNtoSpace(product.description)"></p>
                <p
                    class="product-description"
                    v-else-if="product.description != null && product.description.length >= 70"
                    v-html="
                        $utils.backNtoSpace(product.description.substring(0, 70) + '... <span>voir plus</span>')
                    "></p>
                <span class="product-price">
                    <span :class="{ 'text-muted': hasOffer, 'text-strikethrough': hasOffer }"
                        >{{ $utils.formatPrice(product.price) }}€</span
                    >
                    <span v-if="hasOffer">{{ $utils.formatPrice(product.price_offer) }}</span>
                    <span v-if="productInactive"> - Indisponible</span>
                </span>
            </div>
            <div
                class="col-4 product-image"
                :style="imgLink !== null ? `background-image: url('${imgLink}')` : ''"></div>
        </div>
        <AddProductToCartModal
            v-if="showModal"
            v-bind="{
                categories,
                category,
                product,
                addressValidated,
            }"
            @close="showModal = false" />
    </div>
</template>

<script>
import AddProductToCartModal from "../Modals/AddProductToCartModal.vue";
import moment from "moment";

export default {
    data() {
        return {
            showModal: false,
        };
    },
    props: {
        product: {
            type: Object,
            required: true,
        },
        category: {
            type: String,
            required: true,
        },
        categories: {
            type: Array,
            required: true,
        },
        addressValidated: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        imgLink() {
            if (!this.product.img) return null;
            const imgSplit = this.product.img.split(".");
            let newImg = [];
            imgSplit.forEach((e, i) => {
                if (i === imgSplit.length - 1) newImg.push("min");
                newImg.push(e);
            });
            return newImg.join(".");
        },
        hasOffer() {
            if (!this.product.enable_price_offer) return false;
            if (
                this.product.offer_starts_on &&
                moment().startOf("day").isBefore(moment(this.product.offer_starts_on).startOf("day"))
            )
                return false;
            if (
                this.product.offer_ends_on &&
                moment().startOf("day").isAfter(moment(this.product.offer_ends_on).startOf("day"))
            )
                return false;
            if (!this.product.price_offer) return false;
            return true;
        },
        restaurantBgColor() {
            return { color: this.$store.getters["restaurant/bgcolor"] };
        },
        productInactive() {
            return (
                !this.product.active ||
                !this.validateProductStockForProduct(this.product) ||
                !this.validateCategoryStockForProduct(this.product) ||
                this.product.cc_product_components.data.some(
                    (c) =>
                        c.is_mandatory &&
                        c.cc_product_options.data.every(
                            (o) =>
                                !this.validateProductStockForProduct(o.cc_product) ||
                                !this.validateCategoryStockForProduct(o.cc_product)
                        )
                )
            );
        },
    },
    methods: {
        validateProductStockForProduct(product, quantity) {
            return this.$store.getters["cart/validateProductStockForProduct"](product, quantity);
        },
        validateCategoryStockForProduct(product, quantity) {
            return this.$store.getters["cart/validateCategoryStockForProduct"](product, quantity);
        },
    },
    components: {
        AddProductToCartModal,
    },
};
</script>
