import moment from "moment";

function hasOffer(product) {
    if (!product.enable_price_offer) return false;
    if (product.offer_starts_on && moment().startOf("day").isBefore(moment(product.offer_starts_on).startOf("day")))
        return false;
    if (product.offer_ends_on && moment().startOf("day").isAfter(moment(product.offer_ends_on).startOf("day")))
        return false;
    if (!product.price_offer) return false;
    return true;
}

function productPrice(product, with_offer = true) {
    let quantity = Number.parseInt(product.quantity);
    if (isNaN(quantity) || quantity < 1) return 0;
    let price = hasOffer(product.product) && with_offer ? product.product.price_offer : product.product.price;
    product.options.forEach((option) => {
        if (!option.option.include) price += option.option.price * option.quantity;
    });
    return price * product.quantity;
}

export default {
    products(state) {
        return state.products;
    },
    totalAmount(state) {
        let totalAmount = 0;
        state.products.forEach((product) => {
            totalAmount += productPrice(product);
        });
        return totalAmount;
    },
    validateProductStockForProduct:
        (state, getters) =>
        (product, baseQuantity = 1) => {
            if (!product.active) return false;
            if (product.out_of_stock) return false;
            const productQuantity = getters.getProductQuantity(product.id);
            if (product.stock !== null && baseQuantity + productQuantity > product.stock) return false;
            return true;
        },
    validateCategoryStockForProduct:
        (state, getters) =>
        (product, baseQuantity = 1) => {
            if (product.category_id === -1) return true;
            const categoryQuantity = getters.getCategoryQuantity(product.category_id);
            if (
                (product.stock_per_slot !== null && baseQuantity + categoryQuantity > product.stock_per_slot) ||
                (product.stock_per_service !== null && baseQuantity + categoryQuantity > product.stock_per_service) ||
                (product.stock_per_day !== null && baseQuantity + categoryQuantity > product.stock_per_day)
            )
                return false;
            return true;
        },
    getProductQuantity: (state) => (productId) => {
        let quantity = 0;
        state.products.forEach((product) => {
            if (product.product.id === productId) quantity += product.quantity;
            product.options.forEach((option) => {
                if (option.option.cc_product_id === productId) quantity += product.quantity * option.quantity;
            });
        });
        return quantity;
    },
    getCategoryQuantity: (state) => (categoryId) => {
        let quantity = 0;
        state.products.forEach((product) => {
            if (product.product.category_id === categoryId) quantity += product.quantity;
            product.options.forEach((option) => {
                if (option.option.cc_product.category_id === categoryId) quantity += product.quantity * option.quantity;
            });
        });
        return quantity;
    },
    slot(state) {
        return state.slot;
    },
    date(state) {
        return state.date;
    },
    token(state) {
        return state.token;
    },
    comment(state) {
        return state.comment;
    },
    collectType(state) {
        return state.collectType;
    },
    deliveryInfo(state) {
        return state.deliveryInfo;
    },
    promoCode(state) {
        return state.promoCode;
    },
    giftVoucher(state) {
        return state.giftVoucher;
    },
};
