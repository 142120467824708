export default {
    data() {
        return {
            SERVICE_POINT_COUNTRY_FRANCE: {
                value: "fr",
                label: "France",
            },
            SERVICE_POINT_COUNTRY_BELGIUM: {
                value: "be",
                label: "Belgique",
            },
            SERVICE_POINT_COUNTRY_GERMANY: {
                value: "de",
                label: "Allemagne",
            },
            SERVICE_POINT_COUNTRY_NETHERLANDS: {
                value: "nl",
                label: "Pays-Bas",
            },
        };
    },
    methods: {
        getServicePointCountryLabel(value) {
            const found = this.ALL_SERVICE_POINT_COUNTRIES.find((w) => w.value == value);
            if (typeof found != "undefined") return found.label;
            return value;
        },
    },
    computed: {
        ALL_SERVICE_POINT_COUNTRIES() {
            return [
                this.SERVICE_POINT_COUNTRY_FRANCE,
                this.SERVICE_POINT_COUNTRY_BELGIUM,
                this.SERVICE_POINT_COUNTRY_GERMANY,
                this.SERVICE_POINT_COUNTRY_NETHERLANDS,
            ];
        },
    },
};
