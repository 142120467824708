<template>
    <div>
        <div class="row">
            <div class="col-12">
                <template v-if="!paid">
                    <span class="d-block text-danger" v-if="error">{{ error }}</span>
                    <span class="d-block text-danger" v-else-if="alreadyPaid">{{
                        $t("errors.payment_methods.already_used")
                    }}</span>
                    <template v-else>
                        <p v-if="canPay">
                            <span
                                class="mr-2 cursor-pointer"
                                @click="$parent.resetChoosenPaymentMethod()"
                                v-if="availablePaymentMethods.length > 1">
                                <svg
                                    style="margin-top: -5px !important"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="css-i6dzq1">
                                    <polyline points="15 18 9 12 15 6"></polyline>
                                </svg>
                            </span>
                            <template v-if="!hasAccessToken"
                                >Connectez vous à votre compte Edenred et réglez
                                {{ $utils.formatPrice(method.payable_amount / 100) }}€</template
                            >
                            <template v-else-if="loading">Retour</template>
                            <span class="text-danger" v-else-if="!payable_amount || payable_amount < 100">{{
                                $t("errors.payment_methods.edenred.empty_balance")
                            }}</span>
                            <template v-else>
                                Votre compte est créditeur de {{ $utils.formatPrice(balance / 100) }}€ à dépenser
                                aujourd'hui.<br />
                            </template>
                        </p>
                        <p v-else-if="!canPay" class="text-danger">{{ $t("errors.payment_methods.unavailable") }}</p>
                        <LoaderComponent class="w-100 text-center" v-show="loading || loadingPayment" />
                        <span class="d-block text-danger" v-if="errorPayment">{{ errorPayment }}</span>
                        <div class="w-100 text-right">
                            <button
                                v-if="canPay"
                                @click="pay"
                                class="mt-2 btn main-button"
                                :style="{ 'background-color': `${bgColor} !important` }"
                                :disabled="loading > 0 || loadingPayment || !payable_amount || payable_amount < 100">
                                Payer<span v-if="payable_amount"> {{ $utils.formatPrice(payable_amount / 100) }}€</span>
                            </button>
                        </div>
                    </template>
                    <div class="secure-payment text-muted mt-3">
                        <a href="https://stripe.com/fr" target="_blank"
                            ><small><feather class="feather-18" type="lock"></feather> Paiement sécurisé</small></a
                        >
                    </div>
                </template>
                <PaymentConfirmRedirect
                    v-else
                    :command="command"
                    :amount_paid="amount_paid"
                    @go-back="goBackAfterPayment" />
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../LoaderComponent.vue";
import OnlinePaymentMethodEnum from "../../../mixins/enums/click_and_collect/OnlinePaymentMethodEnum.js";
import PaymentStatusEnum from "../../../mixins/enums/click_and_collect/PaymentStatusEnum.js";
import PaymentConfirmRedirect from "./PaymentConfirmRedirect.vue";

export default {
    data() {
        return {
            loading: 0,
            loadingPayment: false,
            error: null,
            errorPayment: null,
            paid: false,
            hasAccessToken: false,
            balance: null,
            payable_amount: null,
            amount_paid: null,
        };
    },
    mixins: [OnlinePaymentMethodEnum, PaymentStatusEnum],
    props: {
        command: {
            type: Object,
            required: true,
        },
        method: {
            type: Object,
            required: true,
        },
    },
    computed: {
        availablePaymentMethods() {
            return this.$store.getters["restaurant/available_payment_methods"];
        },
        bgColor() {
            return this.$store.getters["restaurant/bgcolor"];
        },
        methodCommandPayment() {
            return this.command.command_payments.data.find(
                (payment) => payment.online_payment_method === this.ONLINE_PAYMENT_METHOD_EDENRED.value
            );
        },
        alreadyPaid() {
            if (this.methodCommandPayment)
                return this.$utils.inEnum(this.methodCommandPayment.status, this.PAID_STATUS_WITH_REFUNDED);
            return this.command.remaining_amount_to_pay === 0;
        },
        canPay() {
            if (this.alreadyPaid) return false;
            return (
                (!this.methodCommandPayment || this.methodCommandPayment.status === this.PAYMENT_PENDING.value) &&
                this.method.payable_amount > 0
            );
        },
    },
    methods: {
        goBackAfterPayment() {
            document.location.replace(this.$utils.removeQueryParameter("payment_method"));
        },
        fetchEdenredCustomerBalance() {
            this.loading++;
            this.balance = null;
            this.error = null;

            axios
                .get(`${API_URL}/${this.$api_key}/getEdenredCustomerBalance/${this.command.token}`)
                .then((response) => {
                    this.loading--;
                    this.balance = response.data.balance;
                })
                .catch((error) => {
                    this.loading--;
                    this.error = this.$t("errors.common.unknown");
                    if (error.response && error.response.data.message) this.error = error.response.data.message;
                    else if (error.message) this.error = error.message;
                });
        },
        estimateEdenredCharge() {
            this.loading++;
            this.payable_amount = null;
            this.error = null;

            axios
                .get(`${API_URL}/${this.$api_key}/estimateEdenredCharge/${this.command.token}`)
                .then((response) => {
                    this.loading--;
                    this.payable_amount = response.data.payable_amount;
                })
                .catch((error) => {
                    this.loading--;
                    this.error = this.$t("errors.common.unknown");
                    if (error.response && error.response.data.message) this.error = error.response.data.message;
                    else if (error.message) this.error = error.message;
                });
        },
        fetchEdenredRedirectURL() {
            this.loading++;
            this.error = null;

            axios
                .get(`${API_URL}/${this.$api_key}/getEdenredAuthCodeUrl/${this.command.token}`)
                .then((response) => {
                    document.location.href = response.data.url;
                })
                .catch((error) => {
                    this.loading--;
                    this.error = this.$t("errors.common.unknown");
                    if (error.response && error.response.data.message) this.error = error.response.data.message;
                    else if (error.message) this.error = error.message;
                });
        },
        pay() {
            this.loadingPayment = true;
            this.errorPayment = null;

            axios
                .post(`${API_URL}/${this.$api_key}/pay/${this.command.token}`, {
                    amount: this.payable_amount,
                })
                .then((response) => {
                    this.loadingPayment = false;
                    this.paid = true;
                    this.amount_paid = response.data.amount_paid;
                })
                .catch((error) => {
                    this.loadingPayment = false;
                    this.errorPayment = this.$t("errors.common.unknown");
                    if (error.response && error.response.data.message) this.errorPayment = error.response.data.message;
                    else if (error.message) this.errorPayment = error.message;
                });
        },
    },
    components: {
        LoaderComponent,
        PaymentConfirmRedirect,
    },
    created() {
        this.hasAccessToken = this.methodCommandPayment && this.methodCommandPayment.has_edenred_access_token;
        if (this.hasAccessToken) {
            this.fetchEdenredCustomerBalance();
            this.estimateEdenredCharge();
        } else {
            this.fetchEdenredRedirectURL();
        }
    },
};
</script>

<style>
.pay-from-bo .main-button:hover:disabled {
    opacity: 0.65 !important;
    cursor: not-allowed !important;
}
</style>
