<template>
    <header id="noshow-cc-header">
        <loader-component v-if="loading > 0" />
        <div v-show="loading === 0" class="pickup-info p-3">
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-12">
                            <h5 class="d-inline-block noshow-widget-font-title" :style="restaurantBgColor">
                                Expédition
                            </h5>
                            <button
                                v-if="isDeliveryAvailable && canChangeCollectType"
                                class="main-button float-right btn-light d-inline-block ml-3 text-white"
                                style="padding: 0.6em 1em !important"
                                :style="styleCartButton"
                                @click="$emit('changeCollectType', WIDGET_DELIVERY.value)">
                                Livraison par coursier
                            </button>
                            <button
                                v-if="isCollectAvailable && canChangeCollectType"
                                class="main-button float-right btn-light d-inline-block ml-3 text-white"
                                style="padding: 0.6em 1em !important"
                                :style="styleCartButton"
                                @click="$emit('changeCollectType', WIDGET_COLLECT.value)">
                                Retrait sur place
                            </button>
                            <small
                                class="d-block text-danger"
                                v-if="errors && errors.length > 0"
                                v-html="errors.join('<br/>')"></small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <p>
                                <span class="text-muted d-block" v-if="dispatch_min_amount"
                                    >Montant minimum de commande :
                                    {{ $utils.formatPrice(dispatch_min_amount / 100) }}€</span
                                >
                                <span class="text-muted" v-if="dispatch_free_above"
                                    >Livraison offerte à partir de {{ $utils.formatPrice(dispatch_free_above / 100) }}€
                                    d'achat</span
                                >
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import axios from "axios";
import LoaderComponent from "../LoaderComponent.vue";
import moment from "../../plugins/moment.js";
import WidgetTypesEnum from "../../mixins/enums/click_and_collect/WidgetTypesEnum.js";

export default {
    data() {
        return {
            loading: 0,
            errors: [],
            fr,
        };
    },
    mixins: [WidgetTypesEnum],
    props: {
        canChangeCollectType: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        dispatch_min_amount() {
            return this.$store.getters["restaurant/dispatch_min_amount"];
        },
        dispatch_free_above() {
            return this.$store.getters["restaurant/dispatch_free_above"];
        },
        isDeliveryAvailable() {
            return this.$store.getters["restaurant/isDeliveryAvailable"];
        },
        isCollectAvailable() {
            return this.$store.getters["restaurant/isCollectAvailable"];
        },
        restaurantBgColorOnly() {
            return this.$store.getters["restaurant/bgcolor"];
        },
        styleCartButton() {
            return { "background-color": `${this.restaurantBgColorOnly} !important` };
        },
        restaurantName() {
            return this.$store.getters["restaurant/name"];
        },
        restaurantAddress() {
            return this.$store.getters["restaurant/address"];
        },
        restaurantBgColor() {
            return { color: this.$store.getters["restaurant/bgcolor"] };
        },
    },
    methods: {
        init() {
            this.errors = [];

            this.loading++;
            this.$store
                .dispatch("restaurant/fetchDispatchSettings")
                .catch((error) => {
                    this.errors.push(this.$utils.getErrorMsgFromErrorResponse(error, this.$t("errors.common.loading")));
                })
                .finally(() => {
                    this.loading--;
                });

            this.loading++;
            this.$store
                .dispatch("restaurant/fetchDispatchClosures")
                .then((response) => {
                    let dispatch_message = null;
                    let widget_closed = false;
                    if (response.data.data)
                        response.data.data.forEach((closure) => {
                            if (closure.dispatch_message) dispatch_message = closure.dispatch_message;
                            if (closure.disable_dispatch_widget) widget_closed = true;
                        });
                    if (dispatch_message) this.$emit("widget-message", dispatch_message);
                    if (widget_closed) this.$emit("widget-closed");
                    else this.$emit("slotSelected");
                })
                .catch((error) => {
                    this.errors.push(this.$utils.getErrorMsgFromErrorResponse(error, this.$t("errors.common.loading")));
                })
                .finally(() => {
                    this.loading--;
                });
        },
    },
    watch: {},
    components: {
        LoaderComponent,
    },
    created() {
        this.init();
    },
};
</script>

<style></style>
