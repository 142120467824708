<template>
    <modal @close="closeFromModal">
        <div slot="header" class="border-bottom w-100" style="position: relative">
            <div class="w-100 p-3">
                <div class="d-flex justify-content-between">
                    <h5 class="mt-2" :style="basicRestaurantBgColor">{{ $t("info.paid_title") }}</h5>
                    <button @click="close" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="text-black" aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
        </div>
        <div slot="body" class="p-3">
            <div class="row">
                <div class="col-12">
                    <h6>
                        <strong>{{ $t("info.paid") }}</strong>
                    </h6>
                    <template v-if="collectType === 'collect'">
                        <p>
                            <strong>Date et heure de retrait :</strong> le {{ moment(date).format("DD MMMM Y") }} à
                            {{ $utils.formatHour(slot.hour) }}
                        </p>
                    </template>
                    <template v-else-if="collectType === 'delivery'">
                        <p>
                            <strong>Date et heure de livraison :</strong> le
                            {{ moment(date).format("DD MMMM Y") }} entre {{ $utils.formatHour(slot.hour_start) }} et
                            {{ $utils.formatHour(slot.hour_end) }}
                        </p>
                        <p>Adresse de livraison : {{ address }}</p>
                    </template>
                    <p><strong>Récapitulatif de la commande :</strong></p>
                    <div class="ml-2">
                        <p class="d-block" v-for="(product, index) in products" :key="index">
                            <span class="d-block">{{ product.quantity }} x {{ product.product.name }}</span>
                            <span class="d-block" v-if="product.comment">{{ product.comment }}</span>
                            <span class="ml-2" v-if="product.options && product.options.length > 0"></span>
                            <span
                                v-for="(option, index) in product.options"
                                v-bind:key="index"
                                style="font-size: 0.8rem">
                                {{ option.option.cc_product.name }}
                                <span v-if="!option.option.include > 0">
                                    ({{ $utils.formatPrice(option.option.price) }}€)</span
                                >
                                <span v-if="option.quantity > 1"> (x{{ option.quantity }})</span>
                                <span v-if="index < product.options.length - 1"> • </span>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="w-100 p-3 border-top text-right">
                <button @click="close" class="main-button" :style="buttonRestaurantBgColor">Fermer</button>
            </div>
        </div>
    </modal>
</template>

<script>
import moment from "moment";

export default {
    data() {
        return {
            moment,
        };
    },
    computed: {
        restaurantBgColor() {
            return this.$store.getters["restaurant/bgcolor"];
        },
        buttonRestaurantBgColor() {
            return { background: this.restaurantBgColor };
        },
        basicRestaurantBgColor() {
            return { color: `${this.restaurantBgColor} !important` };
        },
        collectType() {
            return this.$store.getters["cart/collectType"];
        },
        date() {
            return this.$store.getters["cart/date"];
        },
        slot() {
            return this.$store.getters["cart/slot"];
        },
        products() {
            return this.$store.getters["cart/products"];
        },
        address() {
            return this.$store.getters["client/addressForClient"];
        },
    },
    props: {
        errors: {
            default: null,
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            e.stopPropagation();
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {},
};
</script>
