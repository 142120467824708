var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _vm.command.remaining_amount_to_pay === 0
            ? _c("div", { staticClass: "text-center" }, [
                _vm._v(
                  "\n                Cette commande a été totalement payée\n                "
                ),
                _vm.command.redirect_url
                  ? _c("div", { staticClass: "w-100 text-center" }, [
                      _c(
                        "a",
                        {
                          staticClass: "mt-3 btn main-button",
                          style: {
                            "background-color": `${_vm.bgColor} !important`,
                          },
                          attrs: { href: _vm.command.redirect_url },
                        },
                        [_vm._v("Retour au site")]
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm.command.status === _vm.COMMAND_CANCELED.value
            ? _c("div", { staticClass: "text-center" }, [
                _vm._v(
                  "\n                Cette commande a été annulée\n                "
                ),
                _vm.command.redirect_url
                  ? _c("div", { staticClass: "w-100 text-center" }, [
                      _c(
                        "a",
                        {
                          staticClass: "mt-3 btn main-button",
                          style: {
                            "background-color": `${_vm.bgColor} !important`,
                          },
                          attrs: { href: _vm.command.redirect_url },
                        },
                        [_vm._v("Retour au site")]
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm.paymentDelayExpired
            ? _c("div", { staticClass: "text-center text-danger" }, [
                _vm._v(
                  "\n                Vous avez dépassé le temps limite pour le paiement de cette commande."
                ),
                _c("br"),
                _vm._v(
                  "\n                La commande a été annulée.\n                "
                ),
                _vm.command.redirect_url
                  ? _c("div", { staticClass: "w-100 text-center" }, [
                      _c(
                        "a",
                        {
                          staticClass: "mt-3 btn main-button",
                          style: {
                            "background-color": `${_vm.bgColor} !important`,
                          },
                          attrs: { href: _vm.command.redirect_url },
                        },
                        [_vm._v("Retour au site")]
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm.choosenPaymentMethod
            ? _c(_vm.$utils.capitalize(_vm.choosenPaymentMethod.method), {
                tag: "component",
                attrs: {
                  method: _vm.choosenPaymentMethod,
                  command: _vm.command,
                },
                on: {
                  paid: function ($event) {
                    return _vm.$emit("paid", $event)
                  },
                },
              })
            : _vm.allPaymentMethods.length === 0
            ? _c("span", { staticClass: "text-danger" }, [
                _vm._v(
                  "\n                Aucun moyen de paiement n'est disponible pour cette commande.\n            "
                ),
              ])
            : [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12 mb-2" }, [
                    _vm._v("Sélectionnez votre moyen de paiement"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center pl-3 pr-3" },
                    _vm._l(_vm.allPaymentMethods, function (method) {
                      return _c(
                        "div",
                        {
                          key: method.method,
                          staticClass: "border-light p-1 mt-2 ml-2",
                          class: {
                            "cursor-pointer": _vm.isMethodAvailable(method),
                          },
                          style: _vm.isMethodAvailable(method)
                            ? ""
                            : "opacity: 0.5;",
                        },
                        [
                          _c("img", {
                            staticStyle: { height: "50px", margin: "auto" },
                            attrs: { src: `/images/${method.logo}` },
                            on: {
                              click: function ($event) {
                                return _vm.choosePaymentMethod(method)
                              },
                            },
                          }),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ],
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }