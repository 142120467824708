var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.dispatch.isServicePointValidated
      ? _c("label", {
          staticClass: "d-block mt-2 mb-2",
          staticStyle: {
            padding: "14px !important",
            border: "2px solid #f7f7f7 !important",
          },
          domProps: { innerHTML: _vm._s(_vm.servicePointDisplayable) },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.errorServicePoint
      ? _c("small", { staticClass: "text-danger d-block" }, [
          _vm._v(_vm._s(_vm.errorServicePoint)),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex justify-content-between" }, [
      _c(
        "button",
        {
          staticClass: "main-button btn-light text-white d-flex mt-2",
          staticStyle: { "align-self": "flex-end" },
          style: _vm.styleCartButton,
          on: { click: _vm.openServicePoint },
        },
        [_vm._v("\n            Rechercher un point relais\n        ")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "main-button btn-light text-white d-flex mt-2",
          staticStyle: { "align-self": "flex-end" },
          style: _vm.styleCartButton,
          attrs: {
            disabled:
              !_vm.dispatch.isServicePointValidated ||
              !_vm.dispatch.isAddressValidated,
          },
          on: {
            click: function ($event) {
              return _vm.$emit("next-step")
            },
          },
        },
        [_vm._v("\n            Valider\n        ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }