<template>
    <div id="cc-cart" class="cart-sticky">
        <div class="cart p-3">
            <h5 :style="styleTitle" class="noshow-widget-font-title">Votre commande</h5>
            <div class="cart-body mb-3">
                <div v-for="(product, index) in products" :key="index" class="cart-product pt-1 pb-1">
                    <div class="row div-cart-price">
                        <div class="col-3 pr-1">
                            <input
                                type="number"
                                :min="1"
                                class="form-control"
                                @input="productQuantityChanged(index)"
                                v-model.number="product.quantity" />
                        </div>
                        <div class="col-6 pl-1 pr-1">
                            <p>
                                <strong class="d-block">{{ product.product.name }}</strong>
                                <span class="d-block" v-if="product.comment">{{ product.comment }}</span>
                            </p>
                            <ul class="pl-3" style="list-style: none; font-size: 0.8rem; color: #666666">
                                <span v-for="(option, index) in product.options" v-bind:key="index">
                                    {{ option.option.cc_product.name }}
                                    <span v-if="!option.option.include > 0"> ({{ $utils.formatPrice(option.option.price) }}€)</span>
                                    <span v-if="option.quantity > 1"> (x{{ option.quantity }})</span>
                                    <span v-if="index < product.options.length - 1"> • </span>
                                </span>
                            </ul>
                        </div>
                        <div class="col-3 pl-0 text-right">
                            <p class="text-right">
                                <strong class="cart-price">
                                    <span
                                        :class="{
                                            'text-muted': hasOffer(product.product),
                                            'text-strikethrough': hasOffer(product.product),
                                        }"
                                        >{{ currentPrice(product, false) }}€</span
                                    >
                                    <span v-if="hasOffer(product.product)">{{ currentPrice(product) }}€</span>
                                </strong>
                                <button
                                    type="button"
                                    @click="deleteCartItem(index)"
                                    class="btn btn-sm text-danger p-0 pl-1 float-right btn-delete-item">
                                    +
                                </button>
                            </p>
                        </div>
                    </div>
                    <small class="d-block text-danger" v-if="errors[index] && errors[index].length > 0" v-html="errors[index].join('<br />')"></small>
                </div>
            </div>
            <form class="row mb-2" v-if="!hasValidPromoCode && promoCodeEnabled" @submit="validatePromoCode">
                <div class="col-md-8">
                    <input type="text" v-model="tmpPromoCode" placeholder="Code promotionnel" class="form-control" />
                </div>
                <div class="col-md-4">
                    <button
                        :disabled="loading || !tmpPromoCode"
                        type="submit"
                        :style="styleCartButton"
                        class="main-button btn-light d-inline-block text-white">
                        OK
                    </button>
                </div>
                <ShowErrors :errors="promoCodeErrors" errorKey="*" class="col-12" />
            </form>
            <span class="text-muted d-block mb-2" v-else-if="hasValidPromoCode && promoCodeEnabled"
                ><small>Code promotionnel : {{ promoCodeDisplayable }}</small
                ><button
                    @click="resetPromoCode"
                    type="button"
                    class="btn btn-sm text-danger p-0 pl-1 btn-delete-item d-inline-block"
                    style="margin-top: -6px !important">
                    +
                </button></span
            >
            <AddGiftVoucher :loading="loading" @start-loading="loading++" @stop-loading="loading--" :totalAmount="totalAmountWithPromoCode" />
            <textarea
                v-model="comment"
                class="form-control mb-2"
                placeholder="Note pour l'établissement"
                style="height: initial !important"></textarea>
            <button
                :disabled="products.length === 0 || invalidCart || !hasCardPaymentSystem || loading > 0"
                @click="showPaymentModal"
                :style="styleCartButton"
                class="main-button">
                <div class="row">
                    <div class="col-8 text-left">Valider la commande</div>
                    <div class="col-4 text-right">
                        <span
                            :class="{
                                'text-white': (hasValidPromoCode || hasValidGiftVoucher) && totalAmount !== 0,
                                'text-strikethrough': (hasValidPromoCode || hasValidGiftVoucher) && totalAmount !== 0,
                            }"
                            >{{ totalAmountStr }}€</span
                        >
                        <span v-if="(hasValidPromoCode || hasValidGiftVoucher) && totalAmount !== 0"
                            >{{ totalAmountStrWithPromoCodeAndGiftVoucher }}€</span
                        >
                    </div>
                </div>
            </button>
        </div>
        <PaymentModalDispatch v-if="showModal" @close="showModal = false" @paid="paid" ref="paymentModalDispatch" @saved="saved" />
        <ShowErrorsModal v-if="showShowErrorsModal" @close="showShowErrorsModal = false" :errors="errorsForModal" />
    </div>
</template>

<script>
import PaymentModalDispatch from "../Modals/PaymentModalDispatch.vue";
import axios from "axios";
import ShowErrorsModal from "../Modals/ShowErrorsModal.vue";
import ShowErrors from "../errors/ShowErrors.vue";
import PromoCodeTypeEnum from "../../mixins/enums/click_and_collect/PromoCodeTypeEnum.js";
import moment from "moment";
import AddGiftVoucher from "../GiftVouchers/AddGiftVoucher.vue";

export default {
    data() {
        return {
            showModal: false,
            errors: {},
            errorsForModal: null,
            showShowErrorsModal: false,
            showModal: false,
            saveProducts: this.$_.cloneDeep(this.$store.getters["cart/products"]),
            products: this.$store.getters["cart/products"],
            comment: this.$store.getters["cart/comment"],
            loading: 0,
            tmpPromoCode: null,
            promoCodeErrors: null,
        };
    },
    mixins: [PromoCodeTypeEnum],
    props: {
        categories: {
            type: Array,
            required: true,
        },
    },
    computed: {
        giftVoucher() {
            return this.$store.getters["cart/giftVoucher"];
        },
        hasValidGiftVoucher() {
            return this.giftVoucher != null;
        },
        promoCodeEnabled() {
            return this.$store.getters["restaurant/promo_code_enabled"];
        },
        dispatch_min_amount() {
            return this.$store.getters["restaurant/dispatch_min_amount"];
        },
        hasValidPromoCode() {
            return this.promoCode != null;
        },
        promoCode() {
            return this.$store.getters["cart/promoCode"];
        },
        promoCodeDisplayable() {
            if (!this.promoCode) return "";
            const unit = this.getPromoCodeTypeUnit(this.promoCode.type);
            let amount = this.promoCode.amount;
            if (this.promoCode.type === this.PROMO_CODE_TYPE_AMOUNT.value) amount /= 100;
            return `${this.promoCode.name} (-${amount}${unit})`;
        },
        collectType() {
            return this.$store.getters["cart/collectType"];
        },
        restaurantBgColor() {
            return this.$store.getters["restaurant/bgcolor"];
        },
        styleCartButton() {
            return { background: this.restaurantBgColor, width: "100%" };
        },
        styleTitle() {
            return { color: this.restaurantBgColor };
        },
        invalidStock() {
            return this.products.some((product) => {
                return (
                    !this.validateProductStockForProduct(product.product, 0) ||
                    !this.validateCategoryStockForProduct(product.product, 0) ||
                    product.options.some(
                        (o) =>
                            !this.validateProductStockForProduct(o.option.cc_product, 0) ||
                            !this.validateCategoryStockForProduct(o.option.cc_product, 0)
                    )
                );
            });
        },
        deliveryInfo() {
            return this.$store.getters["cart/deliveryInfo"] !== null ? this.$store.getters["cart/deliveryInfo"].cc_delivery_area : null;
        },
        invalidCart() {
            return (
                (this.dispatch_min_amount && this.dispatch_min_amount > this.totalAmountWithPromoCode) ||
                this.invalidStock ||
                Object.values(this.errors).some((errors) => errors.length > 0) ||
                this.products.some((p) => !p.quantity || p.quantity < 1)
            );
        },
        totalAmount() {
            let totalAmount = 0;
            if (this.products.length === 0) return 0;
            return this.$store.getters["cart/totalAmount"];
        },
        totalAmountWithPromoCode() {
            let totalAmount = this.totalAmount * 100;
            if (!this.hasValidPromoCode) return totalAmount;
            let promoCode = this.$store.getters["cart/promoCode"];
            if (promoCode.type === this.PROMO_CODE_TYPE_AMOUNT.value) return totalAmount - promoCode.amount;
            if (promoCode.type === this.PROMO_CODE_TYPE_PERCENT.value) return totalAmount - (promoCode.amount * totalAmount) / 100;
            return totalAmount;
        },
        totalAmountWithPromoCodeAndGiftVoucher() {
            let totalAmount = this.totalAmountWithPromoCode;
            if (!this.hasValidGiftVoucher) return totalAmount;
            return totalAmount - this.giftVoucher.amount * 100;
        },
        totalAmountStr() {
            if (this.totalAmount === 0) return "--";
            return this.$utils.formatPrice(this.totalAmount);
        },
        totalAmountStrWithPromoCode() {
            if (this.totalAmountWithPromoCode === 0) return "--";
            return this.$utils.formatPrice(this.totalAmountWithPromoCode / 100);
        },
        totalAmountStrWithPromoCodeAndGiftVoucher() {
            if (this.totalAmountWithPromoCodeAndGiftVoucher === 0) return "--";
            return this.$utils.formatPrice(this.totalAmountWithPromoCodeAndGiftVoucher / 100);
        },
        hasCardPaymentSystem() {
            return this.$store.getters["restaurant/hasCardPaymentSystem"];
        },
        builtData() {
            let data = {
                cart: {
                    products: this.$store.getters["cart/products"],
                    comment: this.$store.getters["cart/comment"],
                },
                collectType: this.collectType,
                validate_cart_only: true,
                client: this.$store.getters["client/client"],
            };
            return data;
        },
    },
    methods: {
        validatePromoCode(e) {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }
            if (!this.tmpPromoCode) return;
            this.promoCodeErrors = null;
            this.loading++;

            this.$store
                .dispatch("cart/validatePromoCode", {
                    promo_code: this.tmpPromoCode,
                    total_amount: this.totalAmount * 100,
                    collect_type: this.collectType,
                })
                .then((response) => {
                    this.loading--;
                })
                .catch((error) => {
                    if (this.hasValidPromoCode) this.resetPromoCode();
                    this.loading--;
                    if (error.response && error.response.data.errors) this.promoCodeErrors = error.response.data.errors;
                    else if (error.message && error.message.toLowerCase() === "network error")
                        this.promoCodeErrors = [this.$t("errors.common.network")];
                    else if (error.message) this.promoCodeErrors = [error.message];
                    else this.promoCodeErrors = [this.$t("errors.common.unknown")];
                });
        },
        resetPromoCode() {
            this.$store.dispatch("cart/resetPromoCode");
        },
        showPaymentModal() {
            this.loading++;
            this.errorsForModal = null;

            axios
                .post(`${API_URL}/${this.$api_key}/commands`, this.builtData)
                .then((response) => {
                    this.loading--;
                    this.showModal = true;
                })
                .catch((error) => {
                    this.loading--;
                    if (error.response && error.response.data.errors) this.errorsForModal = error.response.data.errors;
                    else if (error.message && error.message.toLowerCase() === "network error")
                        this.errorsForModal = [this.$t("errors.common.network")];
                    else if (error.message) this.errorsForModal = [error.message];
                    else this.errorsForModal = [this.$t("errors.common.unknown")];
                    this.showShowErrorsModal = true;
                });
        },
        validateProductStockForProduct(product, quantity) {
            return this.$store.getters["cart/validateProductStockForProduct"](product, quantity);
        },
        validateCategoryStockForProduct(product, quantity) {
            return this.$store.getters["cart/validateCategoryStockForProduct"](product, quantity);
        },
        getCategoryName(categoryId) {
            const category = this.categories.find((c) => c.id === categoryId);
            if (typeof category != "undefined") return category.name;
            return "";
        },
        getCartProductQuantity(product_id) {
            return this.$store.getters["cart/getProductQuantity"](product_id);
        },
        getCartCategoryQuantity(category_id) {
            return this.$store.getters["cart/getCategoryQuantity"](category_id);
        },
        hasOffer(product) {
            if (!product.enable_price_offer) return false;
            if (product.offer_starts_on && moment().startOf("day").isBefore(moment(product.offer_starts_on).startOf("day"))) return false;
            if (product.offer_ends_on && moment().startOf("day").isAfter(moment(product.offer_ends_on).startOf("day"))) return false;
            if (!product.price_offer) return false;
            return true;
        },
        currentPrice(product, with_offer = true) {
            let quantity = Number.parseInt(product.quantity);
            if (isNaN(quantity) || quantity < 1) return "--";
            let price = this.hasOffer(product.product) && with_offer ? product.product.price_offer : product.product.price;
            product.options.forEach((option) => {
                if (!option.option.include) price += option.option.price * option.quantity;
            });
            return this.$utils.formatPrice(quantity * price);
        },
        paid() {
            if (this.$refs.paymentModalDispatch) this.$refs.paymentModalDispatch.clearTimer();
            this.showModal = false;
            this.$emit("paid");
        },
        saved() {
            if (this.$refs.paymentModalDispatch) this.$refs.paymentModalDispatch.clearTimer();
            this.showModal = false;
            this.$emit("saved");
        },
        deleteCartItem(index) {
            if (!confirm(this.$t("confirm.products.delete"))) return;
            this.products.splice(index, 1);
            this.$store.dispatch("cart/setProducts", this.products);
            this.saveProducts = this.$_.cloneDeep(this.$store.getters["cart/products"]);
            if (this.hasValidPromoCode) this.validatePromoCode();
        },
        productQuantityChanged(index) {
            let product = this.products[index];
            let quantity = Number.parseInt(product.quantity);
            this.errors[index] = [];
            // if (isNaN(quantity) || quantity < 1) {
            //     if (!confirm(this.$t('confirm.products.delete'))) {
            //         this.products = this.$_.cloneDeep(this.saveProducts);
            //         this.$store.dispatch('cart/setProducts', this.products);
            //         this.saveProducts = this.$_.cloneDeep(this.$store.getters['cart/products']);
            //         return;
            //     }
            //     this.products.splice(index, 1);
            //     this.$store.dispatch('cart/setProducts', this.products);
            //     this.saveProducts = this.$_.cloneDeep(this.$store.getters['cart/products']);
            // }
            if (!this.validateProductStockForProduct(product.product, 0)) {
                this.errors[index].push(this.$t("errors.products.quantity.max", { product: product.product.name }));
                return;
            }
            if (!this.validateCategoryStockForProduct(product.product, 0)) {
                this.errors[index].push(
                    this.$t("errors.categories.quantity.max", {
                        category: this.getCategoryName(product.product.category_id),
                    })
                );
                return;
            }
            product.options.forEach((option) => {
                if (!this.validateProductStockForProduct(option.option.cc_product, 0))
                    this.errors[index].push(this.$t("errors.products.quantity.max", { product: option.option.cc_product.name }));
                else if (!this.validateCategoryStockForProduct(option.option.cc_product, 0))
                    this.errors[index].push(
                        this.$t("errors.categories.quantity.max", {
                            category: this.$utils.getCategoryName(option.option.cc_product.category_id, this.categories),
                        })
                    );
            });
            if (this.errors[index].length > 0) return;
            this.$store.dispatch("cart/setProducts", this.products);
            this.saveProducts = this.$_.cloneDeep(this.$store.getters["cart/products"]);
            if (this.hasValidPromoCode) this.validatePromoCode();
        },
    },
    components: {
        PaymentModalDispatch,
        ShowErrorsModal,
        ShowErrors,
        AddGiftVoucher,
    },
    watch: {
        comment(newVal) {
            this.$store.dispatch("cart/setComment", newVal);
        },
        products: {
            deep: true,
            handler() {
                this.saveProducts = this.$_.cloneDeep(this.$store.getters["cart/products"]);
            },
        },
    },
    created() {
        this.$store.watch(
            () => this.$store.getters["cart/products"],
            () => {
                this.products = this.$store.getters["cart/products"];
            }
        );
    },
};
</script>

<style scoped>
.btn-delete-item {
    background: transparent;
    border: none;
    text-transform: capitalize;
}
</style>
