var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c(
      "div",
      {
        staticClass: "product-image-modal",
        staticStyle: { position: "relative" },
        style:
          _vm.product.img != null
            ? `background-image: url('${_vm.product.img}');`
            : `height: 0px`,
        attrs: { slot: "header" },
        slot: "header",
      },
      [
        _vm.product.img != null
          ? _c(
              "button",
              {
                staticClass: "close",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: { color: "#eeeeee !important" },
                    attrs: { "aria-hidden": "true" },
                  },
                  [_vm._v("×")]
                ),
              ]
            )
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "p-4 border-bottom",
        staticStyle: { position: "relative" },
        attrs: { slot: "body" },
        slot: "body",
      },
      [
        _c(
          "h5",
          {
            staticClass: "product-title noshow-widget-font-title",
            style: _vm.styleProductName,
          },
          [_vm._v(_vm._s(_vm.product.name))]
        ),
        _vm._v(" "),
        _vm.product.img == null
          ? _c(
              "button",
              {
                staticClass: "close",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("p", {
          staticClass: "product-description",
          domProps: {
            innerHTML: _vm._s(_vm.backNtoBR(_vm.product.description)),
          },
        }),
        _vm._v(" "),
        _vm.allergens.length > 0
          ? _c("small", { staticStyle: { color: "#222222 !important" } }, [
              _vm._v("Informations allergènes :\n            "),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.allergens
                      .map((allergen) => allergen.toLowerCase())
                      .join(", ")
                  ) + "."
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._l(
          _vm.product.cc_product_components.data,
          function (productComponent) {
            return _c("div", { key: productComponent.id }, [
              productComponent.cc_product_options.data.length > 0 &&
              productComponent.cc_product_options.data.some(
                (o) =>
                  _vm.validateProductStockForProduct(o.cc_product) &&
                  _vm.validateCategoryStockForProduct(o.cc_product)
              )
                ? _c("div", { staticClass: "mt-3 pt-3 border-top" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-5" }, [
                        _c(
                          "h6",
                          { staticClass: "mb-1 noshow-widget-font-title" },
                          [_vm._v(_vm._s(productComponent.name))]
                        ),
                        _vm._v(" "),
                        productComponent.is_mandatory
                          ? _c(
                              "small",
                              {
                                staticClass: "text-muted",
                                staticStyle: { "font-size": "0.75rem" },
                              },
                              [_vm._v("Obligatoire")]
                            )
                          : _c(
                              "small",
                              {
                                staticClass: "text-muted",
                                staticStyle: { "font-size": "0.75rem" },
                              },
                              [_vm._v("Optionnel")]
                            ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-7" }, [
                        _c("div", { staticClass: "row" }, [
                          productComponent.is_mandatory
                            ? _c(
                                "div",
                                { staticClass: "col-12 radio" },
                                [
                                  _vm._l(
                                    productComponent.cc_product_options.data,
                                    function (productOption) {
                                      return [
                                        _vm.validateProductStockForProduct(
                                          productOption.cc_product
                                        ) &&
                                        _vm.validateCategoryStockForProduct(
                                          productOption.cc_product
                                        )
                                          ? _c(
                                              "label",
                                              {
                                                key: `${productComponent.id}-${productOption.id}`,
                                                staticClass: "container mb-1",
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .optionsMandatorySelected[
                                                          productComponent.id
                                                        ].selected,
                                                      expression:
                                                        "optionsMandatorySelected[productComponent.id].selected",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "radio",
                                                    name: `category-${productComponent.id}`,
                                                  },
                                                  domProps: {
                                                    value: productOption,
                                                    checked: _vm._q(
                                                      _vm
                                                        .optionsMandatorySelected[
                                                        productComponent.id
                                                      ].selected,
                                                      productOption
                                                    ),
                                                  },
                                                  on: {
                                                    change: [
                                                      function ($event) {
                                                        return _vm.$set(
                                                          _vm
                                                            .optionsMandatorySelected[
                                                            productComponent.id
                                                          ],
                                                          "selected",
                                                          productOption
                                                        )
                                                      },
                                                      function ($event) {
                                                        _vm.currentPriceChange++
                                                      },
                                                    ],
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("span", {
                                                  staticClass: "checkmark",
                                                }),
                                                _vm._v(
                                                  _vm._s(
                                                    productOption.cc_product
                                                      .name
                                                  ) +
                                                    "\n                                        "
                                                ),
                                                !productOption.include
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "- " +
                                                          _vm._s(
                                                            _vm.$utils.formatPrice(
                                                              productOption.price
                                                            )
                                                          ) +
                                                          "€"
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                productOption.cc_product
                                                  .description
                                                  ? _c("small", {
                                                      staticClass:
                                                        "d-block text-muted",
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.backNtoBR(
                                                            productOption
                                                              .cc_product
                                                              .description
                                                          )
                                                        ),
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                  _vm._v(" "),
                                  _vm.optionsMandatorySelected[
                                    productComponent.id
                                  ].error
                                    ? _c(
                                        "small",
                                        { staticClass: "text-danger d-block" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.optionsMandatorySelected[
                                                productComponent.id
                                              ].error
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              )
                            : _c(
                                "div",
                                { staticClass: "col-12" },
                                _vm._l(
                                  productComponent.cc_product_options.data,
                                  function (productOption) {
                                    return _c(
                                      "label",
                                      {
                                        key: `${productComponent.id}-${productOption.id}`,
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.optionsQuantity[
                                                  productComponent.id
                                                ][productOption.id].quantity,
                                              expression:
                                                "optionsQuantity[productComponent.id][productOption.id].quantity",
                                            },
                                          ],
                                          staticClass:
                                            "d-inline-block form-control text-center pt-1 p-1 mr-2",
                                          staticStyle: { width: "45px" },
                                          attrs: { type: "number", min: "0" },
                                          domProps: {
                                            value:
                                              _vm.optionsQuantity[
                                                productComponent.id
                                              ][productOption.id].quantity,
                                          },
                                          on: {
                                            change: function ($event) {
                                              _vm.currentPriceChange++
                                            },
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.optionsQuantity[
                                                  productComponent.id
                                                ][productOption.id],
                                                "quantity",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "13px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                productOption.cc_product.name
                                              )
                                            ),
                                            !productOption.include
                                              ? _c("span", [
                                                  _vm._v(
                                                    "\n                                            - " +
                                                      _vm._s(
                                                        _vm.$utils.formatPrice(
                                                          productOption.price
                                                        )
                                                      ) +
                                                      "€"
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.optionsQuantity[
                                          productComponent.id
                                        ][productOption.id].error
                                          ? _c(
                                              "small",
                                              {
                                                staticClass:
                                                  "text-danger d-block",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.optionsQuantity[
                                                      productComponent.id
                                                    ][productOption.id].error
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ])
          }
        ),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "p-3", attrs: { slot: "footer" }, slot: "footer" },
      [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.comment,
              expression: "comment",
            },
          ],
          staticClass: "form-control mb-3",
          staticStyle: { height: "initial !important" },
          attrs: { placeholder: "Note pour l'établissement" },
          domProps: { value: _vm.comment },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.comment = $event.target.value
            },
          },
        }),
        _vm._v(" "),
        _vm.error
          ? _c("small", { staticClass: "text-danger mb-2 d-block" }, [
              _vm._v(_vm._s(_vm.error)),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center",
          },
          [
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "main-button second-btn",
                  staticStyle: {
                    width: "40px !important",
                    "padding-left": "18px !important",
                  },
                  on: { click: _vm.quantityMinus },
                },
                [_c("i", { staticClass: "fas fa-minus" })]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: _vm.quantity,
                    expression: "quantity",
                    modifiers: { number: true },
                  },
                ],
                staticClass: "d-inline-block form-control pt-1 text-center",
                staticStyle: { width: "50px" },
                attrs: { type: "text" },
                domProps: { value: _vm.quantity },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.quantity = _vm._n($event.target.value)
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "main-button second-btn",
                  staticStyle: {
                    width: "40px !important",
                    "padding-left": "18px !important",
                  },
                  on: { click: _vm.quantityAdd },
                },
                [_c("i", { staticClass: "fas fa-plus" })]
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "div",
                {
                  staticClass: "d-inline-block mr-2",
                  staticStyle: {
                    color: "#222222 !important",
                    "font-size": "16px !important",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      class: {
                        "text-muted": _vm.hasOffer,
                        "text-strikethrough": _vm.hasOffer,
                      },
                    },
                    [_vm._v(_vm._s(_vm.currentPriceWithoutOffer) + "€")]
                  ),
                  _vm._v(" "),
                  _vm.hasOffer
                    ? _c("span", [_vm._v(_vm._s(_vm.currentPrice) + "€")])
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: !_vm.addressValidated
                        ? {
                            content:
                              "Veuillez saisir votre adresse de livraison pour pouvoir ajouter des produits au panier",
                          }
                        : undefined,
                      expression:
                        "\n                        !addressValidated\n                            ? {\n                                  content:\n                                      'Veuillez saisir votre adresse de livraison pour pouvoir ajouter des produits au panier',\n                              }\n                            : undefined\n                    ",
                    },
                  ],
                  staticClass: "main-button",
                  class: {
                    disabled: !_vm.validQuantity || !_vm.addressValidated,
                  },
                  style: _vm.styleAddButton,
                  on: { click: _vm.addToCart },
                },
                [_vm._v("\n                    Ajouter\n                ")]
              ),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }