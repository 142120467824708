<template>
    <div class="text-center mt-4 mb-4">
        <div class="lds-ring mb-2">
            <div :style="loadingBorderColor"></div>
            <div :style="loadingBorderColor"></div>
            <div :style="loadingBorderColor"></div>
            <div :style="loadingBorderColor"></div>
        </div>
        <div class="text-center" style="font-size: 14px">{{ $t("loading") }}</div>
    </div>
</template>

<script>
export default {
    computed: {
        restaurantBgColor() {
            return this.$store.getters["restaurant/bgcolor"];
        },
        loadingColor() {
            return { color: this.restaurantBgColor };
        },
        loadingBorderColor() {
            return { "border-color": this.restaurantBgColor + " transparent transparent transparent" };
        },
    },
};
</script>

<style scoped type="text/css">
.lds-ring {
    display: inline-block;
    position: relative;
    width: 60px;
    height: 60px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 50px;
    height: 50px;
    margin: 5px;
    border: 4px solid #30a4b7;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #30a4b7 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
