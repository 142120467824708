var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    { attrs: { id: "noshow-cc-header" } },
    [
      _vm.loading > 0 ? _c("loader-component") : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading === 0,
              expression: "loading === 0",
            },
          ],
          staticClass: "pickup-info p-3",
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "h5",
                    {
                      staticClass: "d-inline-block noshow-widget-font-title",
                      style: _vm.restaurantBgColor,
                    },
                    [
                      _vm._v(
                        "\n                            Expédition\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.isDeliveryAvailable && _vm.canChangeCollectType
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "main-button float-right btn-light d-inline-block ml-3 text-white",
                          staticStyle: { padding: "0.6em 1em !important" },
                          style: _vm.styleCartButton,
                          on: {
                            click: function ($event) {
                              return _vm.$emit(
                                "changeCollectType",
                                _vm.WIDGET_DELIVERY.value
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            Livraison par coursier\n                        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isCollectAvailable && _vm.canChangeCollectType
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "main-button float-right btn-light d-inline-block ml-3 text-white",
                          staticStyle: { padding: "0.6em 1em !important" },
                          style: _vm.styleCartButton,
                          on: {
                            click: function ($event) {
                              return _vm.$emit(
                                "changeCollectType",
                                _vm.WIDGET_COLLECT.value
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            Retrait sur place\n                        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.errors && _vm.errors.length > 0
                    ? _c("small", {
                        staticClass: "d-block text-danger",
                        domProps: {
                          innerHTML: _vm._s(_vm.errors.join("<br/>")),
                        },
                      })
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("p", [
                    _vm.dispatch_min_amount
                      ? _c("span", { staticClass: "text-muted d-block" }, [
                          _vm._v(
                            "Montant minimum de commande :\n                                " +
                              _vm._s(
                                _vm.$utils.formatPrice(
                                  _vm.dispatch_min_amount / 100
                                )
                              ) +
                              "€"
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.dispatch_free_above
                      ? _c("span", { staticClass: "text-muted" }, [
                          _vm._v(
                            "Livraison offerte à partir de " +
                              _vm._s(
                                _vm.$utils.formatPrice(
                                  _vm.dispatch_free_above / 100
                                )
                              ) +
                              "€\n                                d'achat"
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }