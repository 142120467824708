<template>
    <div class="container-fluid">
        <HeaderComponent
            ref="headerComponent"
            @noSlotAvailableForToday="
                products = null;
                noSlotAvailableForToday = true;
            "
            @changeCollectType="changeCollectType"
            @slotSelected="fetchData"
            @setLoading="loading = $event"
            @noProducts="noProductsFct"
            @noSlot="products = null"
            @widget-message="widgetMessage = $event"
            @widget-closed="widgetClosed = true"
            @address-validated="addressValidated = $event" />
        <div>
            <div class="row m-0 mt-3">
                <loader-component v-if="loading" style="margin: auto" />
                <div :style="styleAddButton" class="col-12 text-white text-center p-3" v-if="widgetMessage && !loading">
                    {{ widgetMessage }}
                </div>
            </div>
            <div class="row">
                <div v-if="errorCategory">
                    <h6 class="mb-4 text-center text-danger">{{ errorCategory }}</h6>
                </div>
                <template v-else-if="products !== null && !loading && !widgetClosed">
                    <div class="col-xl-9 col-lg-8 mt-3 mt-md-0">
                        <div>
                            <nav-component
                                :items="activeCategories"
                                :current-category="currentCategory"
                                @set-current-category="currentCategory = $event" />
                            <ProductsCategoryComponent
                                v-for="category in categoriesToDisplay"
                                :hide-category-name="displayPerCategory"
                                :key="category.id"
                                :category="category"
                                :products="products"
                                :categories="categories"
                                :addressValidated="addressValidated_" />
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-4">
                        <component
                            :is="cartComponent"
                            :addressValidated="addressValidated_"
                            :categories="activeCategories"
                            @paid="paid"
                            @saved="saved" />
                    </div>
                </template>
                <div v-else-if="!loading && noSlotAvailableForToday" class="col-12 no-slot-for-current text-center mt-5 mb-5">
                    <h6 class="mb-4 text-center">{{ noSlotAvailableForTodayReason }}</h6>
                    <button @click="setFirstAvailableDate" :style="styleAddButton" class="main-button text-center">
                        Prochain créneau disponible
                    </button>
                </div>
                <div v-else-if="!loading && noProducts" class="col-12 no-slot-for-current text-center mt-5 mb-5">
                    <h6 class="mb-4 text-center">Aucun produit n'est disponible</h6>
                </div>
                <footer-component />
            </div>
        </div>
        <PaymentConfirmationModal v-if="showPaymentConfirmationModal" @close="closePaymentConfirmationModal" />
    </div>
</template>

<script>
import HeaderComponent from "../components/HeaderComponent.vue";
import FooterComponent from "../components/FooterComponent.vue";
import NavComponent from "../components/Nav/NavComponent.vue";
import ShowCartComponent from "../components/Cart/ShowCartComponent.vue";
import ShowCartComponentDispatch from "../components/Cart/ShowCartComponentDispatch.vue";
import ProductsCategoryComponent from "../components/ProductsCategories/ProductsCategoryComponent.vue";
import LoaderComponent from "../components/LoaderComponent.vue";
import axios from "axios";
import PaymentConfirmationModal from "../components/Modals/PaymentConfirmationModal.vue";
import WidgetTypesEnum from "../mixins/enums/click_and_collect/WidgetTypesEnum.js";

export default {
    name: "MainLayout",
    data() {
        return {
            loading: false,
            categories: [{ id: null, name: "Formules", order: 0 }],
            products: null,
            noSlotAvailableForToday: false,
            noProducts: false,
            showPaymentConfirmationModal: false,
            widgetMessage: null,
            widgetClosed: false,
            addressValidated: false,
            errorCategory: null,
            currentCategory: null,
        };
    },
    mixins: [WidgetTypesEnum],
    computed: {
        addressValidated_() {
            return this.collectType != this.WIDGET_DELIVERY.value || this.addressValidated;
        },
        cartComponent() {
            switch (this.collectType) {
                case this.WIDGET_COLLECT.value:
                case this.WIDGET_DELIVERY.value:
                    return "ShowCartComponent";
                    break;
                case this.WIDGET_DISPATCH.value:
                    return "ShowCartComponentDispatch";
                    break;
            }
            return null;
        },
        collectType() {
            return this.$store.getters["cart/collectType"];
        },
        activeCategories() {
            return this.categories.filter((category) => this.products.some((product) => product.category_id === category.id));
        },
        slot_id() {
            return this.$store.getters["cart/slot"] !== null ? this.$store.getters["cart/slot"].id : null;
        },
        order_date() {
            return this.$store.getters["cart/date"];
        },
        restaurantBgColor() {
            return this.$store.getters["restaurant/bgcolor"];
        },
        styleAddButton() {
            return { "background-color": `${this.restaurantBgColor} !important` };
        },
        noSlotAvailableForTodayReason() {
            switch (this.$store.getters["restaurant/isTodayAvailableReason"]) {
                case 1:
                    return this.$t("errors.common.commands_closed");
                case 2:
                    return this.$t("errors.common.commands_ended");
                default:
                    return "";
            }
        },
        displayPerCategory() {
            return this.$store.getters["restaurant/displayPerCategory"];
        },
        useBurgerMenu() {
            return this.$store.getters["restaurant/useBurgerMenu"];
        },
        categoriesToDisplay() {
            return this.displayPerCategory && this.currentCategory !== null ? [this.currentCategory] : this.activeCategories;
        },
    },
    methods: {
        noProductsFct() {
            this.loading = false;
            this.products = null;
            this.noProducts = true;
        },
        changeCollectType() {
            this.widgetMessage = null;
            this.widgetClosed = null;
            this.noSlotAvailableForToday = false;
            this.products = null;
            this.$store.dispatch("cart/resetPromoCode");
        },
        setFirstAvailableDate() {
            this.noSlotAvailableForToday = false;
            this.$refs.headerComponent.setFirstAvailableDate();
        },
        paid() {
            this.showPaymentConfirmationModal = true;
        },
        closePaymentConfirmationModal() {
            this.$store.dispatch("cart/clear");
            document.location.reload(true);
        },
        saved() {
            this.$store.dispatch("cart/clear");
            document.location.reload(true);
        },
        fetchCategories() {
            this.errorCategory = null;

            axios
                .get(`${API_URL}/${this.$api_key}/categories`)
                .then((response) => {
                    this.loading = false;
                    this.categories = this.categories.concat(response.data);
                })
                .catch((error) => {
                    this.loading = false;
                    this.errorCategory = this.$utils.getErrorMsgFromErrorResponse(error);
                });
        },
        fetchData() {
            if (this.collectType === this.WIDGET_DISPATCH.value) return this.fetchDataDispatch();
            if (this.slot_id === null) return;
            this.loading = true;

            axios
                .get(
                    `${API_URL}/${this.$api_key}/products/${this.slot_id}/${this.order_date}?include=cc_menus_option,cc_product_components.cc_product_options.cc_product`,
                    {
                        params: {
                            collect_type: this.collectType,
                        },
                    }
                )
                .then((response) => {
                    this.products = response.data.products;
                    this.$store.dispatch("cart/clearUnavailableProducts", this.products);
                    this.$nextTick(() => {
                        this.loading = false;
                        this.$store.dispatch("restaurant/setIsGroupPaymentEnable", response.data.isGroupPaymentEnable);
                    });
                })
                .catch((error) => {
                    this.loading = false;
                    this.errorProduct = this.$utils.getErrorMsgFromErrorResponse(error);
                });
        },
        fetchDataDispatch() {
            axios
                .get(`${API_URL}/${this.$api_key}/products_dispatch?include=cc_menus_option,cc_product_components.cc_product_options.cc_product`)
                .then((response) => {
                    if (response.data.products.length === 0) {
                        this.noProducts = true;
                        this.loading = false;
                    } else {
                        this.products = response.data.products;
                        this.$store.dispatch("cart/clearUnavailableProducts", this.products);
                        this.$nextTick(() => {
                            this.loading = false;
                            this.$store.dispatch("restaurant/setIsGroupPaymentEnable", response.data.isGroupPaymentEnable);
                        });
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    this.errorProduct = this.$utils.getErrorMsgFromErrorResponse(error);
                });
        },
    },
    components: {
        HeaderComponent,
        FooterComponent,
        NavComponent,
        ShowCartComponent,
        ProductsCategoryComponent,
        LoaderComponent,
        PaymentConfirmationModal,
        ShowCartComponentDispatch,
    },
    created() {
        this.fetchCategories();
        if (
            (this.$store.getters["cart/slot"] !== null && this.$store.getters["cart/date"] !== null) ||
            this.collectType === this.WIDGET_DISPATCH.value
        ) {
            this.fetchData();
        }
    },
};
</script>
