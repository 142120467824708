var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          !_vm.paid
            ? [
                _vm.error
                  ? _c("span", { staticClass: "d-block text-danger" }, [
                      _vm._v(_vm._s(_vm.error)),
                    ])
                  : _vm.alreadyPaid
                  ? _c("span", { staticClass: "d-block text-danger" }, [
                      _vm._v(
                        _vm._s(_vm.$t("errors.payment_methods.already_used"))
                      ),
                    ])
                  : [
                      _vm.canPay
                        ? _c(
                            "p",
                            [
                              _vm.availablePaymentMethods.length > 1
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "mr-2 cursor-pointer",
                                      on: {
                                        click: function ($event) {
                                          return _vm.$parent.resetChoosenPaymentMethod()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          staticClass: "css-i6dzq1",
                                          staticStyle: {
                                            "margin-top": "-5px !important",
                                          },
                                          attrs: {
                                            viewBox: "0 0 24 24",
                                            width: "24",
                                            height: "24",
                                            stroke: "currentColor",
                                            "stroke-width": "2",
                                            fill: "none",
                                            "stroke-linecap": "round",
                                            "stroke-linejoin": "round",
                                          },
                                        },
                                        [
                                          _c("polyline", {
                                            attrs: {
                                              points: "15 18 9 12 15 6",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.hasAccessToken
                                ? [
                                    _vm._v(
                                      "Connectez vous à votre compte Edenred et réglez\n                            " +
                                        _vm._s(
                                          _vm.$utils.formatPrice(
                                            _vm.method.payable_amount / 100
                                          )
                                        ) +
                                        "€"
                                    ),
                                  ]
                                : _vm.loading
                                ? [_vm._v("Retour")]
                                : !_vm.payable_amount ||
                                  _vm.payable_amount < 100
                                ? _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "errors.payment_methods.edenred.empty_balance"
                                        )
                                      )
                                    ),
                                  ])
                                : [
                                    _vm._v(
                                      "\n                            Votre compte est créditeur de " +
                                        _vm._s(
                                          _vm.$utils.formatPrice(
                                            _vm.balance / 100
                                          )
                                        ) +
                                        "€ à dépenser\n                            aujourd'hui."
                                    ),
                                    _c("br"),
                                  ],
                            ],
                            2
                          )
                        : !_vm.canPay
                        ? _c("p", { staticClass: "text-danger" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("errors.payment_methods.unavailable")
                              )
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("LoaderComponent", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.loading || _vm.loadingPayment,
                            expression: "loading || loadingPayment",
                          },
                        ],
                        staticClass: "w-100 text-center",
                      }),
                      _vm._v(" "),
                      _vm.errorPayment
                        ? _c("span", { staticClass: "d-block text-danger" }, [
                            _vm._v(_vm._s(_vm.errorPayment)),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "w-100 text-right" }, [
                        _vm.canPay
                          ? _c(
                              "button",
                              {
                                staticClass: "mt-2 btn main-button",
                                style: {
                                  "background-color": `${_vm.bgColor} !important`,
                                },
                                attrs: {
                                  disabled:
                                    _vm.loading > 0 ||
                                    _vm.loadingPayment ||
                                    !_vm.payable_amount ||
                                    _vm.payable_amount < 100,
                                },
                                on: { click: _vm.pay },
                              },
                              [
                                _vm._v("\n                            Payer"),
                                _vm.payable_amount
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$utils.formatPrice(
                                              _vm.payable_amount / 100
                                            )
                                          ) +
                                          "€"
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ],
                _vm._v(" "),
                _c("div", { staticClass: "secure-payment text-muted mt-3" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://stripe.com/fr",
                        target: "_blank",
                      },
                    },
                    [
                      _c(
                        "small",
                        [
                          _c("feather", {
                            staticClass: "feather-18",
                            attrs: { type: "lock" },
                          }),
                          _vm._v(" Paiement sécurisé"),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]
            : _c("PaymentConfirmRedirect", {
                attrs: { command: _vm.command, amount_paid: _vm.amount_paid },
                on: { "go-back": _vm.goBackAfterPayment },
              }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }