var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading > 0
        ? _c("loader-component", { staticClass: "w-100 text-center" })
        : _vm.errorRestaurant || _vm.errorCommand
        ? _c("div", { staticClass: "container" }, [
            _vm.errorRestaurant
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v(_vm._s(_vm.errorRestaurant)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.errorCommand
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v(_vm._s(_vm.errorCommand)),
                ])
              : _vm._e(),
          ])
        : _c(
            "div",
            [
              _vm.customerEmail === null
                ? _c("payment-group-list-emails", {
                    attrs: { command: _vm.command },
                    on: {
                      select: function ($event) {
                        _vm.refreshComputed++
                      },
                    },
                  })
                : _c("payment-group-payment", {
                    attrs: { command: _vm.command },
                    on: { "go-back": _vm.removeCustomerEmail },
                  }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }