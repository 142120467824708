<template>
    <modal @close="closeFromModal">
        <div
            slot="header"
            class="product-image-modal"
            style="position: relative"
            :style="product.img != null ? `background-image: url('${product.img}');` : `height: 0px`">
            <button v-if="product.img != null" type="button" class="close" @click="$emit('close')">
                <span style="color: #eeeeee !important" aria-hidden="true">&times;</span>
            </button>
        </div>
        <div slot="body" class="p-4 border-bottom" style="position: relative">
            <h5 class="product-title noshow-widget-font-title" :style="styleProductName">{{ product.name }}</h5>
            <button v-if="product.img == null" type="button" class="close" @click="$emit('close')">
                <span aria-hidden="true">&times;</span>
            </button>
            <p class="product-description" v-html="backNtoBR(product.description)"></p>
            <!-- <small style="color: #222222 !important" v-if="product.allergens.length > 0">Informations allergènes : <span>{{ product.allergens.map(allergen => allergen.toLowerCase()).join(', ') }}.</span></small><br/> -->
            <small style="color: #222222 !important" v-if="allergens.length > 0"
                >Informations allergènes :
                <span>{{ allergens.map((allergen) => allergen.toLowerCase()).join(", ") }}.</span></small
            >
            <div v-for="productComponent in product.cc_product_components.data" :key="productComponent.id">
                <div
                    class="mt-3 pt-3 border-top"
                    v-if="
                        productComponent.cc_product_options.data.length > 0 &&
                        productComponent.cc_product_options.data.some(
                            (o) =>
                                validateProductStockForProduct(o.cc_product) &&
                                validateCategoryStockForProduct(o.cc_product)
                        )
                    ">
                    <div class="row">
                        <div class="col-sm-5">
                            <h6 class="mb-1 noshow-widget-font-title">{{ productComponent.name }}</h6>
                            <small v-if="productComponent.is_mandatory" class="text-muted" style="font-size: 0.75rem"
                                >Obligatoire</small
                            >
                            <small v-else class="text-muted" style="font-size: 0.75rem">Optionnel</small>
                        </div>
                        <div class="col-sm-7">
                            <div class="row">
                                <div class="col-12 radio" v-if="productComponent.is_mandatory">
                                    <template v-for="productOption in productComponent.cc_product_options.data">
                                        <label
                                            class="container mb-1"
                                            :key="`${productComponent.id}-${productOption.id}`"
                                            v-if="
                                                validateProductStockForProduct(productOption.cc_product) &&
                                                validateCategoryStockForProduct(productOption.cc_product)
                                            ">
                                            <input
                                                type="radio"
                                                @change="currentPriceChange++"
                                                :name="`category-${productComponent.id}`"
                                                v-model="optionsMandatorySelected[productComponent.id].selected"
                                                :value="productOption" />
                                            <span class="checkmark"></span>{{ productOption.cc_product.name }}
                                            <span v-if="!productOption.include"
                                                >- {{ $utils.formatPrice(productOption.price) }}€</span
                                            >
                                            <small
                                                class="d-block text-muted"
                                                v-if="productOption.cc_product.description"
                                                v-html="backNtoBR(productOption.cc_product.description)"></small>
                                        </label>
                                    </template>
                                    <small
                                        class="text-danger d-block"
                                        v-if="optionsMandatorySelected[productComponent.id].error"
                                        >{{ optionsMandatorySelected[productComponent.id].error }}</small
                                    >
                                </div>
                                <div class="col-12" v-else>
                                    <label
                                        v-for="productOption in productComponent.cc_product_options.data"
                                        :key="`${productComponent.id}-${productOption.id}`">
                                        <input
                                            type="number"
                                            min="0"
                                            @change="currentPriceChange++"
                                            v-model="optionsQuantity[productComponent.id][productOption.id].quantity"
                                            style="width: 45px"
                                            class="d-inline-block form-control text-center pt-1 p-1 mr-2" />
                                        <span style="font-size: 13px"
                                            >{{ productOption.cc_product.name
                                            }}<span v-if="!productOption.include">
                                                - {{ $utils.formatPrice(productOption.price) }}€</span
                                            ></span
                                        >
                                        <small
                                            class="text-danger d-block"
                                            v-if="optionsQuantity[productComponent.id][productOption.id].error"
                                            >{{ optionsQuantity[productComponent.id][productOption.id].error }}</small
                                        >
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer" class="p-3">
            <textarea
                v-model="comment"
                class="form-control mb-3"
                style="height: initial !important"
                placeholder="Note pour l'établissement"></textarea>
            <small class="text-danger mb-2 d-block" v-if="error">{{ error }}</small>
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
                <div>
                    <button
                        @click="quantityMinus"
                        class="main-button second-btn"
                        style="width: 40px !important; padding-left: 18px !important">
                        <i class="fas fa-minus"></i>
                    </button>
                    <input
                        type="text"
                        style="width: 50px"
                        class="d-inline-block form-control pt-1 text-center"
                        v-model.number="quantity" />
                    <button
                        @click="quantityAdd"
                        class="main-button second-btn"
                        style="width: 40px !important; padding-left: 18px !important">
                        <i class="fas fa-plus"></i>
                    </button>
                </div>
                <div>
                    <div class="d-inline-block mr-2" style="color: #222222 !important; font-size: 16px !important">
                        <span :class="{ 'text-muted': hasOffer, 'text-strikethrough': hasOffer }"
                            >{{ currentPriceWithoutOffer }}€</span
                        >
                        <span v-if="hasOffer">{{ currentPrice }}€</span>
                    </div>
                    <button
                        class="main-button"
                        :class="{ disabled: !validQuantity || !addressValidated }"
                        @click="addToCart"
                        :style="styleAddButton"
                        v-tooltip="
                            !addressValidated
                                ? {
                                      content:
                                          'Veuillez saisir votre adresse de livraison pour pouvoir ajouter des produits au panier',
                                  }
                                : undefined
                        ">
                        Ajouter
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import moment from "moment";

export default {
    data() {
        return {
            quantity: 1,
            comment: null,
            error: null,
            optionsMandatorySelected: {},
            optionsQuantity: {},
            currentPriceChange: 0,
        };
    },
    props: {
        product: {
            type: Object,
            required: true,
        },
        category: {
            type: String,
            required: true,
        },
        categories: {
            type: Array,
            required: true,
        },
        addressValidated: {
            default: true,
        },
    },
    computed: {
        allergens() {
            if (this.product.type != "menu") return this.product.allergens;
            let allergens = [];
            this.product.cc_product_components.data.forEach((component) => {
                component.cc_product_options.data.forEach((productOption) => {
                    allergens = allergens.concat(productOption.cc_product.allergens);
                });
            });
            return allergens.filter((allergen, index) => {
                return allergens.indexOf(allergen) === index;
            });
        },
        restaurantBgColor() {
            return this.$store.getters["restaurant/bgcolor"];
        },
        styleProductName() {
            return { color: this.restaurantBgColor };
        },
        styleAddButton() {
            return { background: this.restaurantBgColor };
        },
        menus_option() {
            return this.product.cc_menus_option.data;
        },
        validQuantity() {
            this.currentPriceChange;
            let quantity = Number.parseInt(this.quantity);
            let quantities = {};
            if (
                isNaN(quantity) ||
                quantity < 1 ||
                !this.validateProductStockForProduct(this.product, quantity) ||
                !this.validateCategoryStockForProduct(this.product, quantity) ||
                Object.keys(this.optionsMandatorySelected).some((optionMandatoryKey) => {
                    this.optionsMandatorySelected[optionMandatoryKey].error = null;
                    const option = this.optionsMandatorySelected[optionMandatoryKey].selected;
                    if (option) {
                        if (quantities[option.cc_product_id]) quantities[option.cc_product_id]++;
                        else quantities[option.cc_product_id] = 1;
                        if (
                            !this.validateProductStockForProduct(
                                option.cc_product,
                                quantities[option.cc_product_id] * quantity
                            )
                        ) {
                            this.optionsMandatorySelected[optionMandatoryKey].error = this.$t(
                                "errors.products.quantity.max",
                                { product: option.cc_product.name }
                            );
                            return true;
                        } else if (
                            !this.validateCategoryStockForProduct(
                                option.cc_product,
                                quantities[option.cc_product_id] * quantity
                            )
                        ) {
                            this.optionsMandatorySelected[optionMandatoryKey].error = this.$t(
                                "errors.categories.quantity.max",
                                {
                                    category: this.$utils.getCategoryName(
                                        option.cc_product.category_id,
                                        this.categories
                                    ),
                                }
                            );
                            return true;
                        }
                    }
                    return false;
                }) ||
                Object.keys(this.optionsQuantity).some((optionQuantityKey) =>
                    Object.keys(this.optionsQuantity[optionQuantityKey]).some((optionKey) => {
                        this.optionsQuantity[optionQuantityKey][optionKey].error = null;
                        const option = this.optionsQuantity[optionQuantityKey][optionKey];
                        if (option.option && option.quantity) {
                            if (quantities[option.option.cc_product_id])
                                quantities[option.option.cc_product_id] += parseInt(option.quantity);
                            else quantities[option.option.cc_product_id] = parseInt(option.quantity);
                            if (
                                !this.validateProductStockForProduct(
                                    option.option.cc_product,
                                    quantity * quantities[option.option.cc_product_id]
                                )
                            ) {
                                this.optionsQuantity[optionQuantityKey][optionKey].error = this.$t(
                                    "errors.products.quantity.max",
                                    { product: option.option.cc_product.name }
                                );
                                return true;
                            } else if (
                                !this.validateCategoryStockForProduct(
                                    option.option.cc_product,
                                    quantity * quantities[option.option.cc_product_id]
                                )
                            ) {
                                this.optionsQuantity[optionQuantityKey][optionKey].error = this.$t(
                                    "errors.categories.quantity.max",
                                    {
                                        category: this.$utils.getCategoryName(
                                            option.option.cc_product.category_id,
                                            this.categories
                                        ),
                                    }
                                );
                                return true;
                            }
                        }
                        return false;
                    })
                )
            ) {
                this.$forceUpdate();
                return false;
            }
            this.$forceUpdate();
            return true;
        },
        hasOffer() {
            if (!this.product.enable_price_offer) return false;
            if (
                this.product.offer_starts_on &&
                moment().startOf("day").isBefore(moment(this.product.offer_starts_on).startOf("day"))
            )
                return false;
            if (
                this.product.offer_ends_on &&
                moment().startOf("day").isAfter(moment(this.product.offer_ends_on).startOf("day"))
            )
                return false;
            if (!this.product.price_offer) return false;
            return true;
        },
        currentPrice() {
            this.currentPriceChange;
            let quantity = Number.parseInt(this.quantity);
            if (isNaN(quantity) || quantity < 1) return "--";
            let price = this.hasOffer ? this.product.price_offer : this.product.price;
            Object.values(this.optionsMandatorySelected).forEach((option) => {
                if (option.selected !== null && !option.selected.include) price += option.selected.price;
            });
            Object.values(this.optionsQuantity).forEach((component) => {
                Object.values(component).forEach((option) => {
                    if (option.quantity > 0) price += option.option.price * option.quantity;
                });
            });
            return this.$utils.formatPrice(quantity * price);
        },
        currentPriceWithoutOffer() {
            this.currentPriceChange;
            let quantity = Number.parseInt(this.quantity);
            if (isNaN(quantity) || quantity < 1) return "--";
            let price = this.product.price;
            Object.values(this.optionsMandatorySelected).forEach((option) => {
                if (option.selected !== null && !option.selected.include) price += option.selected.price;
            });
            Object.values(this.optionsQuantity).forEach((component) => {
                Object.values(component).forEach((option) => {
                    if (option.quantity > 0) price += option.option.price * option.quantity;
                });
            });
            return this.$utils.formatPrice(quantity * price);
        },
    },
    created() {
        this.product.cc_product_components.data.forEach((component) => {
            if (component.is_mandatory) {
                let selected = null;
                const filtered = component.cc_product_options.data.filter(
                    (o) =>
                        this.validateProductStockForProduct(o.cc_product) &&
                        this.validateCategoryStockForProduct(o.cc_product)
                );
                if (component.cc_product_options.data.length === 1) selected = component.cc_product_options.data[0];
                else if (filtered.length === 1) selected = filtered[0];
                this.optionsMandatorySelected[component.id] = {
                    selected,
                    error: null,
                };
            } else {
                this.optionsQuantity[component.id] = {};
                component.cc_product_options.data.forEach((option) => {
                    this.optionsQuantity[component.id][option.id] = {
                        quantity: 0,
                        error: null,
                        option,
                    };
                });
            }
        });
    },
    methods: {
        validateProductStockForProduct(product, quantity) {
            return this.$store.getters["cart/validateProductStockForProduct"](product, quantity);
        },
        validateCategoryStockForProduct(product, quantity) {
            return this.$store.getters["cart/validateCategoryStockForProduct"](product, quantity);
        },
        backNtoBR(text) {
            return text !== null ? text.replace(/\n/g, "<br/>") : "";
        },
        checkStocks(quantity) {
            let quantities = {};
            let quantitiesCategories = {};
            if (!this.validateProductStockForProduct(this.product, quantity)) {
                this.error = this.$t("errors.products.quantity.max", { product: this.product.name });
                return true;
            }
            if (!this.validateCategoryStockForProduct(this.product, quantity)) {
                this.error = this.$t("errors.categories.quantity.max", { category: this.category });
                return true;
            }
            quantitiesCategories[this.product.category_id] = 1;
            let hasError = false;
            Object.keys(this.optionsMandatorySelected).forEach((optionKey) => {
                this.optionsMandatorySelected[optionKey].error = null;
                const option = this.optionsMandatorySelected[optionKey].selected;
                if (option) {
                    const product = option.cc_product;
                    quantities[option.cc_product_id] = quantities[option.cc_product_id]
                        ? quantities[option.cc_product_id] + 1
                        : 1;
                    if (product.category_id != -1)
                        quantitiesCategories[product.category_id] = quantitiesCategories[product.category_id]
                            ? quantitiesCategories[product.category_id] + 1
                            : 1;
                    if (!this.validateProductStockForProduct(product, quantities[option.cc_product_id] * quantity)) {
                        this.optionsMandatorySelected[optionKey].error = this.$t("errors.products.quantity.max", {
                            product: product.name,
                        });
                        hasError = true;
                    } else if (
                        product.category_id != -1 &&
                        !this.validateCategoryStockForProduct(
                            product,
                            quantitiesCategories[product.category_id] * quantity
                        )
                    ) {
                        this.optionsMandatorySelected[optionKey].error = this.$t("errors.categories.quantity.max", {
                            category: this.$utils.getCategoryName(product.category_id, this.categories),
                        });
                        hasError = true;
                    }
                }
            });
            Object.keys(this.optionsQuantity).forEach((optionQuantityKey) => {
                Object.keys(this.optionsQuantity[optionQuantityKey]).forEach((optionKey) => {
                    this.optionsQuantity[optionQuantityKey][optionKey].error = null;
                    const option = this.optionsQuantity[optionQuantityKey][optionKey];
                    if (option.option && parseInt(option.quantity) > 0) {
                        const product = option.option.cc_product;
                        quantities[option.option.cc_product_id] = quantities[option.option.cc_product_id]
                            ? quantities[option.option.cc_product_id] + parseInt(option.quantity)
                            : parseInt(option.quantity);
                        if (product.category_id != -1)
                            quantitiesCategories[product.category_id] = quantitiesCategories[product.category_id]
                                ? quantitiesCategories[product.category_id] + parseInt(option.quantity)
                                : parseInt(option.quantity);
                        if (
                            !this.validateProductStockForProduct(
                                product,
                                quantity * quantities[option.option.cc_product_id]
                            )
                        ) {
                            this.optionsQuantity[optionQuantityKey][optionKey].error = this.$t(
                                "errors.products.quantity.max",
                                { product: product.name }
                            );
                            hasError = true;
                        } else if (
                            product.category_id != -1 &&
                            !this.validateCategoryStockForProduct(
                                product,
                                quantity * quantitiesCategories[product.category_id]
                            )
                        ) {
                            this.optionsQuantity[optionQuantityKey][optionKey].error = this.$t(
                                "errors.categories.quantity.max",
                                { category: this.$utils.getCategoryName(product.category_id, this.categories) }
                            );
                            hasError = true;
                        }
                    }
                });
            });
            return hasError;
        },
        addToCart() {
            if (!this.validQuantity || !this.addressValidated) return;
            let quantity = Number.parseInt(this.quantity);
            this.error = null;

            if (isNaN(quantity) || quantity < 1) {
                this.error = this.$t("errors.products.quantity.invalid");
                return;
            }
            let hasError = this.checkStocks(quantity);
            this.$forceUpdate();
            if (hasError) return;
            let options = [];
            this.product.cc_product_components.data.forEach((component) => {
                if (component.cc_product_options.data.length === 0) return;
                if (component.is_mandatory) {
                    this.optionsMandatorySelected[component.id].error = null;
                    if (this.optionsMandatorySelected[component.id].selected === null) {
                        this.optionsMandatorySelected[component.id].error = this.$t("errors.options.required");
                        hasError = true;
                    } else {
                        options.push({
                            option: this.optionsMandatorySelected[component.id].selected,
                            quantity: 1,
                        });
                    }
                } else {
                    Object.keys(this.optionsQuantity[component.id]).forEach((optionQuantity) => {
                        if (this.optionsQuantity[component.id][optionQuantity].quantity > 0)
                            options.push({
                                option: this.optionsQuantity[component.id][optionQuantity].option,
                                quantity: this.optionsQuantity[component.id][optionQuantity].quantity,
                            });
                    });
                }
            });
            if (hasError) {
                this.$forceUpdate();
                return;
            }
            this.$store.dispatch("cart/addProduct", {
                product: this.product,
                quantity,
                comment: this.comment,
                options,
            });
            this.$emit("close");
        },
        quantityAdd() {
            let quantity = Number.parseInt(this.quantity);
            if (isNaN(quantity) || quantity < 1) {
                this.quantity = 1;
                return;
            }
            this.quantity++;
            if (this.quantity < 1) this.quantity = 1;
        },
        quantityMinus() {
            let quantity = Number.parseInt(this.quantity);
            if (isNaN(quantity) || quantity < 2) this.quantity = 1;
            else this.quantity = quantity - 1;
        },
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            e.stopPropagation();
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
        getCartProductQuantity(productId) {
            return this.$store.getters["cart/getProductQuantity"](productId);
        },
    },
    watch: {
        quantity(newVal) {
            let quantity = Number.parseInt(newVal);
            this.error = null;

            if (isNaN(quantity) || quantity < 1) return;
            this.checkStocks(quantity);
            this.$forceUpdate();
        },
        optionsMandatorySelected: {
            deep: true,
            handler(newVal) {
                this.optionsMandatorySelected = newVal;
            },
        },
    },
};
</script>
