<template>
    <component :is="currentComponent" :items="items" :current-category="currentCategory" @set-current-category="setCurrentCategory"></component>
</template>

<script>
import $ from "jquery";
import NavItemComponent from "./NavItemComponent.vue";
import navDisplayAllCategories from "./navDisplayAllCategories.vue";
import navDisplayBurgerMenu from "./navDisplayBurgerMenu.vue";

export default {
    data() {
        return {
            navOpen: false,
        };
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        currentCategory: {
            type: Object,
            default: null,
        },
    },
    methods: {
        setCurrentCategory({ event, category }) {
            this.$emit("set-current-category", category);

            if (event !== null && !this.displayPerCategory) {
                this.goTo(event);
            }
        },
        goTo(event) {
            const targetId = $(event.target).attr("href").substr(1);

            $("html,body").animate({ scrollTop: $(`[id='${targetId}']`).offset().top }, 1000);
        },
    },
    computed: {
        displayPerCategory() {
            return this.$store.getters["restaurant/displayPerCategory"];
        },
        useBurgerMenu() {
            return this.$store.getters["restaurant/useBurgerMenu"];
        },
        currentComponent() {
            return this.useBurgerMenu ? "nav-display-burger-menu" : "nav-display-all-categories";
        },
    },
    components: {
        NavItemComponent,
        navDisplayAllCategories,
        navDisplayBurgerMenu,
    },
    created() {
        if (this.items.length > 0) {
            this.setCurrentCategory({ category: this.items[0], event: null });
        }
    },
};
</script>
