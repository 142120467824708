export default {
    name: null,
    address: null,
    bgcolor: "#000",
    email: null,
    tel: null,
    cgv: null,
    stripe_client_id: null,
    has_card_payment_system: false,
    available_collect_types: null,
    lastDayOrderable: null,
    futureServices: [],
    futureClosures: [],
    futureOpenings: [],
    slots: [],
    is_today_available: null,
    is_today_available_reason: null,
    is_group_payment_enable: false,
    first_orderable_date: null,
    dispatch_min_amount: null,
    dispatch_free_above: null,
    sendcloud_public_key: null,
    enable_service_points: false,
    promo_code_enabled: false,
    gv_enabled: {
        collect: false,
        delivery: false,
        dispatch: false,
    },
    available_payment_methods: [],
    widget_font_title: "'Open Sans', sans-serif",
    default_tel_country: "FR",
    display_per_category: false,
    use_burger_menu: false,
};
