<template>
    <div>
        <div class="row">
            <div class="col-6">
                <strong :style="styleColorWidget">Commande N° {{ command.numero }}</strong>
            </div>
            <div class="col-6 text-right">
                <strong :style="styleColorWidget" v-html="commandDateAndTime"></strong>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p>
                    <strong>Client :</strong> {{ command.client.firstname }} {{ command.client.lastname }}<br />
                    <strong>Établissement :</strong> {{ restaurantName }}<br />
                    <strong>Adresse :</strong> {{ restaurantAddress }}
                </p>
                <div>
                    <strong class="d-block mb-1" :style="styleColorWidget">Montant : {{ $utils.formatPrice(totalAmount / 100) }}€</strong>
                    <strong v-if="amountPaid" class="d-block mb-1" :style="styleColorWidget"
                        >Montant déjà réglé : {{ $utils.formatPrice(amountPaid / 100) }}€</strong
                    >
                    <strong class="d-block" :style="styleColorWidget">Reste à régler : {{ $utils.formatPrice(amountToPay / 100) }}€</strong>
                </div>
                <div v-if="isBoCommand" class="mt-1">
                    <small class="text-muted">
                        En effectuant mon paiement, je déclare accepter les
                        <a class="legal-link" :href="cgv" target="_blank">conditions générales de vente</a>
                        et la
                        <a class="legal-link" :href="`${base_url}/click_and_collect/widget/public/${this.$api_key}/policy?v=${uuid}`" target="_blank">
                            politique de confidentialité des données personnelles
                        </a>
                        et y adhère sans réserve.
                    </small>
                </div>
                <p class="mb-0" v-if="canPayUntil && canPay && !isBoCommand">
                    Vous avez jusqu'à {{ canPayUntil.format("HH[h]mm") }} pour procéder au réglement.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "../../plugins/moment.js";
import CommandTypeEnum from "../../mixins/enums/click_and_collect/CommandTypeEnum.js";

export default {
    data() {
        return {
            uuid: this.createUUID(),
        };
    },
    props: {
        command: {
            type: Object,
            required: true,
        },
        canPayUntil: {
            default: null,
        },
        canPay: {
            default: false,
        },
    },
    mixins: [CommandTypeEnum],
    computed: {
        base_url() {
            return NOSHOW_URL;
        },
        cgv() {
            return (
                (this.$store.getters["restaurant/cgv"] || `${this.base_url}/click_and_collect/widget/public/${this.$api_key}/cgv`) + `?v=${this.uuid}`
            );
        },
        isBoCommand() {
            return this.command.type === this.COMMAND_TYPE_BO.value;
        },
        styleColorWidget() {
            return { color: this.restaurantBgColor };
        },
        restaurantName() {
            return this.$store.getters["restaurant/name"];
        },
        restaurantAddress() {
            return this.$store.getters["restaurant/address"];
        },
        restaurantBgColor() {
            return this.$store.getters["restaurant/bgcolor"];
        },
        commandDateAndTime() {
            if (this.command.collect_type === "collect")
                return moment(this.command.reservation_date + " " + this.command.slot.hour).format("DD/MM/Y - HH[h]mm");
            if (this.command.collect_type === "delivery") {
                const date = moment(this.command.reservation_date).format("DD/MM/Y");
                const hour_start = this.command.slot.hour_start.replace(":", "h").substr(0, 5);
                const hour_end = this.command.slot.hour_end.replace(":", "h").substr(0, 5);
                return `${date} <br/> ${hour_start} - ${hour_end}`;
            }
            return "";
        },
        totalAmount() {
            return this.command.total_amount;
        },
        amountPaid() {
            return this.command.total_amount - this.command.remaining_amount_to_pay;
        },
        amountToPay() {
            return this.command.remaining_amount_to_pay;
        },
    },
    methods: {
        createUUID() {
            var dt = new Date().getTime();
            var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
                var r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
            });
            return uuid;
        },
    },
};
</script>

<style scoped>
a.legal-link {
    font-size: 12px;
    color: #212529 !important;
}

a.legal-link:hover {
    color: inherit !important;
}
</style>
