var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c(
      "div",
      {
        staticClass: "border-bottom w-100",
        staticStyle: { position: "relative" },
        attrs: { slot: "header" },
        slot: "header",
      },
      [
        _c("div", { staticClass: "w-100 p-3 border-bottom" }, [
          _c("div", { staticClass: "d-flex justify-content-between" }, [
            _c(
              "h5",
              {
                staticClass: "mt-2 noshow-widget-font-title",
                style: _vm.basicRestaurantBgColor,
              },
              [_vm._v("Informations")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: {
                  disabled: _vm.loading,
                  type: "button",
                  "data-dismiss": "modal",
                  "aria-label": "Close",
                },
                on: { click: _vm.close },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "text-black",
                    attrs: { "aria-hidden": "true" },
                  },
                  [_vm._v("×")]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("small", { staticClass: "d-block text-muted" }, [
            _vm._v(
              "Pour toute information relative au délai de rétractation, merci de vous référer à nos\n                "
            ),
            _c("a", { attrs: { href: _vm.cgv, target: "_blank" } }, [
              _vm._v("conditions générales de vente"),
            ]),
            _vm._v("."),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-payment-body p-3" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-3" }, [
              _c("h6", { staticClass: "noshow-widget-font-title" }, [
                _vm._v(_vm._s(_vm.collectTypeLabel)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-9" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("h6", { staticClass: "mb-1" }, [
                    _c(
                      "strong",
                      {
                        staticClass: "noshow-widget-font-title",
                        style: _vm.basicRestaurantBgColor,
                      },
                      [_vm._v(_vm._s(_vm.restaurantName))]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "mt-1" }, [
                    _vm._v(_vm._s(_vm.restaurantAddress)),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "mb-0 text-danger" }, [
                    _c("strong", [_vm._v(_vm._s(_vm.dateAndSlot))]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "p-3", attrs: { slot: "body" }, slot: "body" },
      [
        _vm.loading ? _c("loader-component") : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.loading && _vm.step === 1,
                expression: "!loading && step === 1",
              },
            ],
            staticClass: "row",
          },
          [
            _c("div", { staticClass: "col-sm-3" }, [
              _c("h6", { staticClass: "noshow-widget-font-title" }, [
                _vm._v("Coordonnées"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-9" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-3" }, [
                  _c("label", [
                    _vm._v("Civilité "),
                    _c("small", [_vm._v("*")]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-9 radio" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "container",
                        staticStyle: { width: "initial" },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.client.civility,
                              expression: "client.civility",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "civility",
                            value: "monsieur",
                          },
                          domProps: {
                            checked: _vm._q(_vm.client.civility, "monsieur"),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.client,
                                "civility",
                                "monsieur"
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "checkmark" }),
                        _vm._v(
                          "\n                            Monsieur\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "container",
                        staticStyle: { width: "initial" },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.client.civility,
                              expression: "client.civility",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "civility",
                            value: "madame",
                          },
                          domProps: {
                            checked: _vm._q(_vm.client.civility, "madame"),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(_vm.client, "civility", "madame")
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "checkmark" }),
                        _vm._v(
                          "\n                            Madame\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("ShowErrors", {
                      staticClass: "d-block",
                      attrs: {
                        errors: _vm.errors,
                        errorKey: "client.civility",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-6" },
                  [
                    _c("label", [
                      _vm._v("Prénom "),
                      _c("small", [_vm._v("*")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.client.firstname,
                            expression: "client.firstname",
                          },
                        ],
                        staticClass: "form-control mt-2",
                        attrs: { type: "text", required: "required" },
                        domProps: { value: _vm.client.firstname },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.client,
                              "firstname",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("ShowErrors", {
                      attrs: {
                        errors: _vm.errors,
                        errorKey: "client.firstname",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-6" },
                  [
                    _c("label", [
                      _vm._v("Nom "),
                      _c("small", [_vm._v("*")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.client.lastname,
                            expression: "client.lastname",
                          },
                        ],
                        staticClass: "form-control mt-2",
                        attrs: { type: "text", required: "required" },
                        domProps: { value: _vm.client.lastname },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.client,
                              "lastname",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("ShowErrors", {
                      attrs: {
                        errors: _vm.errors,
                        errorKey: "client.lastname",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-sm-6" },
                  [
                    _c("label", [
                      _vm._v("Email "),
                      _c("small", [_vm._v("*")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.client.email,
                            expression: "client.email",
                          },
                        ],
                        staticClass: "form-control mt-2",
                        attrs: { type: "email", required: "required" },
                        domProps: { value: _vm.client.email },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.client, "email", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("ShowErrors", {
                      attrs: { errors: _vm.errors, errorKey: "client.email" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-6" },
                  [
                    _c("label", [
                      _vm._v("Numéro de téléphone "),
                      _c("small", [_vm._v("*")]),
                    ]),
                    _vm._v(" "),
                    _c("vue-tel-input", {
                      attrs: {
                        name: "telInput",
                        defaultCountry: _vm.defaultTelCountry,
                        enabledCountryCode: true,
                        inputClasses: "m-0 border-0",
                        mode: "international",
                        placeholder: "-- -- -- -- --",
                        required: "required",
                      },
                      on: { validate: _vm.setPhoneAndCountry },
                      model: {
                        value: _vm.tel_raw,
                        callback: function ($$v) {
                          _vm.tel_raw = $$v
                        },
                        expression: "tel_raw",
                      },
                    }),
                    _vm._v(" "),
                    _c("ShowErrors", {
                      attrs: { errors: _vm.errors, errorKey: "client.tel" },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("label", [
                      _vm._v("Société\n                            "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.client.company,
                            expression: "client.company",
                          },
                        ],
                        staticClass: "form-control mt-2",
                        attrs: { type: "text" },
                        domProps: { value: _vm.client.company },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.client, "company", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("ShowErrors", {
                      attrs: { errors: _vm.errors, errorKey: "client.company" },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.collectType === "delivery"
                ? _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("label", [
                          _vm._v("Adresse\n                            "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.client.address.address,
                                expression: "client.address.address",
                              },
                            ],
                            staticClass: "form-control mt-2",
                            attrs: { readonly: "", type: "text" },
                            domProps: { value: _vm.client.address.address },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.client.address,
                                  "address",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("ShowErrors", {
                          staticClass: "d-block",
                          attrs: {
                            errors: _vm.errors,
                            errorKey: "ccDeliveryAreaId",
                          },
                        }),
                        _vm._v(" "),
                        _c("ShowErrors", {
                          attrs: {
                            errors: _vm.errors,
                            errorKey: "client.address.address",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("label", [
                          _vm._v("Code postal\n                            "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.client.address.postalCode,
                                expression: "client.address.postalCode",
                              },
                            ],
                            staticClass: "form-control mt-2",
                            attrs: { readonly: "", type: "text" },
                            domProps: { value: _vm.client.address.postalCode },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.client.address,
                                  "postalCode",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("ShowErrors", {
                          attrs: {
                            errors: _vm.errors,
                            errorKey: "client.address.postalCode",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("label", [
                          _vm._v("Ville\n                            "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.client.address.city,
                                expression: "client.address.city",
                              },
                            ],
                            staticClass: "form-control mt-2",
                            attrs: { readonly: "", type: "text" },
                            domProps: { value: _vm.client.address.city },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.client.address,
                                  "city",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("ShowErrors", {
                          attrs: {
                            errors: _vm.errors,
                            errorKey: "client.address.city",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isGroupPaymentAvailable !== 0
                ? _c("div", { staticClass: "row mt-3" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c(
                          "label",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value:
                                  _vm.isGroupPaymentAvailable !== 1
                                    ? undefined
                                    : {
                                        content: `Le paiement de groupe est disponible pour toute commande prise minimum ${_vm.timeNeededForGroupPayment} minutes à l'avance.`,
                                      },
                                expression:
                                  "\n                                isGroupPaymentAvailable !== 1\n                                    ? undefined\n                                    : {\n                                          content: `Le paiement de groupe est disponible pour toute commande prise minimum ${timeNeededForGroupPayment} minutes à l'avance.`,\n                                      }\n                            ",
                              },
                            ],
                            staticClass: "container",
                            class: {
                              disabled: _vm.isGroupPaymentAvailable !== 2,
                            },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.isGroupPayment,
                                  expression: "isGroupPayment",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                disabled: _vm.isGroupPaymentAvailable !== 2,
                              },
                              domProps: {
                                checked: Array.isArray(_vm.isGroupPayment)
                                  ? _vm._i(_vm.isGroupPayment, null) > -1
                                  : _vm.isGroupPayment,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.isGroupPayment,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.isGroupPayment = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.isGroupPayment = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.isGroupPayment = $$c
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", {
                              staticClass: "checkmark",
                              class: {
                                disabled: _vm.isGroupPaymentAvailable !== 2,
                              },
                            }),
                            _vm._v(
                              "Paiement de groupe\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("ShowErrors", {
                          attrs: {
                            errors: _vm.errors,
                            errorKey: "isGroupPayment",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isGroupPayment
                ? _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("p", { staticClass: "mt-1 text-warning" }, [
                            _vm._v(
                              "Le montant minimum par personne est de 5€."
                            ),
                          ]),
                          _vm._v(" "),
                          _c("label", { staticClass: "d-block" }, [
                            _vm._v(
                              "\n                            Votre part :\n                            "
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.ownerAmount,
                                  expression: "ownerAmount",
                                },
                              ],
                              staticClass: "form-control mr-1 d-inline-block",
                              staticStyle: { width: "20%" },
                              attrs: { type: "number", min: "5", step: "0.01" },
                              domProps: { value: _vm.ownerAmount },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.ownerAmount = $event.target.value
                                },
                              },
                            }),
                            _vm._v("€\n                        "),
                          ]),
                          _vm._v(" "),
                          _c("ShowErrors", {
                            staticClass: "d-block",
                            attrs: {
                              errors: _vm.errors,
                              errorKey: "ownerAmount",
                            },
                          }),
                          _vm._v(" "),
                          _c("ShowErrors", {
                            staticClass: "d-block",
                            attrs: {
                              errors: _vm.errors,
                              errorKey: "groupPayments",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.groupPayments, function (groupPayment, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "col-12" },
                          [
                            _c("label", [
                              _vm._v(
                                "\n                            Email de la " +
                                  _vm._s(_vm.getPersonNumberLabel(index)) +
                                  " personne et montant à payer *\n                            "
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: groupPayment.email,
                                    expression: "groupPayment.email",
                                  },
                                ],
                                staticClass: "form-control mt-2 d-inline-block",
                                staticStyle: { width: "60%" },
                                attrs: { type: "email" },
                                domProps: { value: groupPayment.email },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      groupPayment,
                                      "email",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: groupPayment.amount,
                                    expression: "groupPayment.amount",
                                  },
                                ],
                                staticClass:
                                  "form-control mt-2 ml-2 d-inline-block",
                                staticStyle: { width: "20%" },
                                attrs: {
                                  type: "number",
                                  min: "5",
                                  step: "0.01",
                                },
                                domProps: { value: groupPayment.amount },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      groupPayment,
                                      "amount",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(
                                "\n                            €\n                            "
                              ),
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: {
                                        content: "Supprimer cette personne",
                                      },
                                      expression:
                                        "{ content: 'Supprimer cette personne' }",
                                    },
                                  ],
                                  staticClass:
                                    "btn btn-sm text-danger d-inline-block text-center",
                                  staticStyle: { width: "10%" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.groupPayments.splice(index, 1)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "fas fa-times" })]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("ShowErrors", {
                              staticClass: "d-block",
                              attrs: {
                                errors: _vm.errors,
                                errorKey: `groupPayments.${index}.email`,
                              },
                            }),
                            _vm._v(" "),
                            _c("ShowErrors", {
                              staticClass: "d-block",
                              attrs: {
                                errors: _vm.errors,
                                errorKey: `groupPayments.${index}.amount`,
                              },
                            }),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isGroupPayment
                ? _c("div", { staticClass: "row mb-4" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: { content: "Ajouter une personne" },
                              expression: "{ content: 'Ajouter une personne' }",
                            },
                          ],
                          staticClass: "main-button main-button-sm",
                          staticStyle: {
                            width: "40px !important",
                            "padding-left": "18px !important",
                          },
                          on: { click: _vm.addGroupPayment },
                        },
                        [_c("i", { staticClass: "fas fa-plus" })]
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.step === 2 && _vm.isGroupPayment
          ? _c("div", [
              _c("div", { staticClass: "w-100" }, [
                _c("div", { staticClass: "noshow-widget-font-title" }, [
                  _vm._v("Votre commande a bien été enregistrée !"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mt-3" }, [
                  _c("p", [
                    _vm._v(
                      "\n                        Un email a été envoyé à chaque adresse email saisie afin de procéder au paiement."
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                        Une fois les paiements effectués, vous recevrez un email afin de procéder à votre paiement."
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c("strong", [
                      _vm._v(
                        "Attention, vous disposez de 30 minutes pour procéder à tous les paiements."
                      ),
                    ]),
                    _c("br"),
                    _vm._v(
                      "\n                        Vous pouvez suivre les paiements depuis ce lien ou en cliquant sur le bouton ci dessous :"
                    ),
                    _c("br"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center mt-3" },
                    [
                      _c("CopyToClipboard", {
                        attrs: {
                          element: "Le lien",
                          textToCopy: _vm.group_payment_url,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "w-100 text-center" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          target: "_blank",
                          href: _vm.group_payment_url,
                        },
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn main-button mt-4 btn-sm",
                            style: _vm.buttonRestaurantBgColor,
                            attrs: { type: "button" },
                          },
                          [
                            _vm._v(
                              "\n                                Voir la liste des paiements\n                            "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ])
          : _vm.step === 2 && !_vm.isGroupPayment
          ? _c("div", [
              _c("div", { staticClass: "w-100" }, [
                _c("div", { staticClass: "noshow-widget-font-title" }, [
                  _vm._v("Votre commande a bien été enregistrée !"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mt-3" }, [
                  _c("p", [
                    _vm._v(
                      "\n                        Vous allez être redirigé dans quelques secondes afin de procéder au paiement de la commande."
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                        Si rien ne se passe, merci de cliquer sur le bouton ci-dessous.\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "w-100 text-center" }, [
                    _c("a", { attrs: { href: _vm.payment_url } }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn main-button mt-4 btn-sm",
                          style: _vm.buttonRestaurantBgColor,
                          attrs: { type: "button" },
                        },
                        [
                          _vm._v(
                            "\n                                Procéder au paiement\n                            "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "footer" }, slot: "footer" },
      [
        _vm.step === 1
          ? [
              _c(
                "div",
                { staticClass: "p-3 border-top" },
                [
                  _vm.collectType === "delivery"
                    ? [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-6" }, [
                            _c(
                              "h6",
                              { staticClass: "mb-1 noshow-widget-font-title" },
                              [_vm._v("Sous-total")]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-6" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-sm-12" }, [
                                _c(
                                  "h6",
                                  {
                                    staticClass: "mb-0 text-right",
                                    style: _vm.basicRestaurantBgColor,
                                  },
                                  [
                                    _c("strong", [
                                      _vm.totalAmountStr !==
                                      _vm.totalAmountStrWithPromoCodeAndGiftVoucher
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "text-strikethrough",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.totalAmountStr) + "€"
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(
                                        "\n                                            " +
                                          _vm._s(
                                            _vm.totalAmountStrWithPromoCodeAndGiftVoucher
                                          ) +
                                          "€"
                                      ),
                                    ]),
                                    _c("br"),
                                    _vm._v(" "),
                                    _vm.totalAmountStr !==
                                    _vm.totalAmountStrWithPromoCode
                                      ? _c(
                                          "small",
                                          { staticClass: "text-muted" },
                                          [
                                            _vm._v(
                                              "Code promotionnel : " +
                                                _vm._s(_vm.promoCodeDisplayable)
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.totalAmountStr !==
                                    _vm.totalAmountStrWithPromoCodeAndGiftVoucher
                                      ? [
                                          _vm.totalAmountStr !==
                                          _vm.totalAmountStrWithPromoCode
                                            ? _c("br")
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "small",
                                            { staticClass: "text-muted" },
                                            [
                                              _vm._v(
                                                "Chèque cadeau : " +
                                                  _vm._s(
                                                    _vm.giftVoucherDisplayable
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-6" }, [
                            _c(
                              "h6",
                              { staticClass: "mb-1 noshow-widget-font-title" },
                              [_vm._v("Frais de livraison")]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-6" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-sm-12" }, [
                                _c(
                                  "h6",
                                  {
                                    staticClass: "mb-0 text-right",
                                    style: _vm.basicRestaurantBgColor,
                                  },
                                  [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.deliveryFees === null
                                            ? "Gratuit"
                                            : `${_vm.$utils.formatPrice(
                                                _vm.deliveryFees
                                              )}€`
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-6" }, [
                      _c(
                        "h6",
                        { staticClass: "mb-0 noshow-widget-font-title" },
                        [_vm._v("Total")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-6" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-12" }, [
                          _c(
                            "h6",
                            {
                              staticClass: "mb-0 text-right",
                              style: _vm.basicRestaurantBgColor,
                            },
                            [
                              _c("strong", [
                                _vm.totalAmountStr !==
                                  _vm.totalAmountWithDeliveryFeesStr &&
                                _vm.collectType !== "delivery"
                                  ? _c(
                                      "span",
                                      { staticClass: "text-strikethrough" },
                                      [_vm._v(_vm._s(_vm.totalAmountStr) + "€")]
                                    )
                                  : _vm._e(),
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(_vm.totalAmountWithDeliveryFeesStr) +
                                    "€"
                                ),
                              ]),
                              _c("br"),
                              _vm._v(" "),
                              _vm.hasValidPromoCode &&
                              _vm.collectType !== "delivery"
                                ? _c("small", { staticClass: "text-muted" }, [
                                    _vm._v(
                                      "Code promotionnel : " +
                                        _vm._s(_vm.promoCodeDisplayable)
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.hasValidGiftVoucher &&
                              _vm.collectType !== "delivery"
                                ? [
                                    _vm.hasValidPromoCode &&
                                    _vm.collectType !== "delivery"
                                      ? _c("br")
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("small", { staticClass: "text-muted" }, [
                                      _vm._v(
                                        "Chèque cadeau : " +
                                          _vm._s(_vm.giftVoucherDisplayable)
                                      ),
                                    ]),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "footer-modal p-3" }, [
                _vm.error
                  ? _c(
                      "div",
                      { staticClass: "mb-3" },
                      [
                        _vm.error && !_vm.loading
                          ? _c("span", { staticClass: "text-danger d-block" }, [
                              _vm._v(_vm._s(_vm.error)),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("ShowErrors", {
                          staticClass: "d-block",
                          attrs: { errors: _vm.errors, errorKey: "*" },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center",
                  },
                  [
                    _c("div", [
                      _c("label", { staticClass: "container" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.general_terms,
                              expression: "general_terms",
                            },
                          ],
                          attrs: { type: "checkbox", disabled: _vm.loading },
                          domProps: {
                            checked: Array.isArray(_vm.general_terms)
                              ? _vm._i(_vm.general_terms, null) > -1
                              : _vm.general_terms,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.general_terms,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.general_terms = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.general_terms = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.general_terms = $$c
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "checkmark" }),
                        _c("small", { staticClass: "text-muted" }, [
                          _vm._v("Je déclare accepter les "),
                          _c(
                            "a",
                            { attrs: { href: _vm.cgv, target: "_blank" } },
                            [_vm._v("conditions générales de vente")]
                          ),
                          _vm._v(" et de\n                                "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: `${_vm.base_url}/click_and_collect/widget/public/${this.$api_key}/policy?v=${_vm.uuid}`,
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "politique de confidentialité des données personnelles"
                              ),
                            ]
                          ),
                          _vm._v(
                            "\n                                et y adhère sans réserve."
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "payment-btn text-right" }, [
                      _c(
                        "button",
                        {
                          staticClass: "main-button mt-2 mt-md-0",
                          style: _vm.buttonRestaurantBgColor,
                          attrs: {
                            disabled: _vm.loading || this.ownerAmount < 0,
                          },
                          on: { click: _vm.save },
                        },
                        [
                          _vm._v(
                            "\n                            Payer\n                        "
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.step === 2 && _vm.isGroupPayment
          ? [
              _c("div", { staticClass: "w-100 p-3 border-top text-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "main-button",
                    style: _vm.buttonRestaurantBgColor,
                    attrs: { disabled: _vm.loading },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("saved")
                      },
                    },
                  },
                  [_vm._v("Fermer")]
                ),
              ]),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }