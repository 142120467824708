<template>
    <div>
        <form class="row" v-if="!hasValidGiftVoucher && gvEnabled" @submit="validateGiftVoucher">
            <div class="col-8 mb-2">
                <input type="text" v-model="tmpGiftVoucher" placeholder="Chèque cadeau" class="form-control" />
            </div>
            <div class="col-4 mb-2">
                <button
                    :disabled="loading || !tmpGiftVoucher"
                    type="submit"
                    :style="styleCartButton"
                    class="main-button btn-light d-inline-block text-white">
                    OK
                </button>
            </div>
            <ShowErrors :errors="giftVoucherErrors" errorKey="*" class="col-12" />
        </form>
        <span class="text-muted d-block mb-2" v-else-if="hasValidGiftVoucher && gvEnabled"
            ><small>Chèque cadeau : {{ giftVoucherDisplayable }}</small
            ><button
                @click="resetGiftVoucher"
                type="button"
                class="btn btn-sm text-danger p-0 pl-1 btn-delete-item d-inline-block"
                style="margin-top: -2px !important">
                +
            </button></span
        >
    </div>
</template>

<script>
import ShowErrors from "../errors/ShowErrors.vue";

export default {
    data() {
        return {
            tmpGiftVoucher: null,
            giftVoucherErrors: null,
        };
    },
    props: {
        loading: {
            default: 0,
        },
        totalAmount: {
            default: 0,
        },
    },
    computed: {
        restaurantBgColor() {
            return this.$store.getters["restaurant/bgcolor"];
        },
        styleCartButton() {
            return { background: this.restaurantBgColor, width: "100%" };
        },
        collectType() {
            return this.$store.getters["cart/collectType"];
        },
        gvEnabled() {
            return this.$store.getters["restaurant/gv_enabled"](this.collectType);
        },
        hasValidGiftVoucher() {
            return this.giftVoucher != null;
        },
        giftVoucher() {
            return this.$store.getters["cart/giftVoucher"];
        },
        giftVoucherDisplayable() {
            if (!this.giftVoucher) return "";
            let amount = null;
            return `${this.giftVoucher.numero} (-${this.$utils.formatPrice(this.giftVoucher.amount)}€)`;
        },
    },
    methods: {
        validateGiftVoucher(e) {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }
            if (!this.tmpGiftVoucher) return;
            this.giftVoucherErrors = null;
            this.$emit("start-loading");

            this.$store
                .dispatch("cart/validateGiftVoucher", {
                    gift_voucher: this.tmpGiftVoucher,
                    collect_type: this.collectType,
                    total_amount: this.totalAmount,
                })
                .then((response) => {
                    this.$emit("stop-loading");
                })
                .catch((error) => {
                    if (this.hasValidGiftVoucher) this.resetGiftVoucher();
                    this.$emit("stop-loading");
                    if (error.response && error.response.data.errors)
                        this.giftVoucherErrors = error.response.data.errors;
                    else if (error.message && error.message.toLowerCase() === "network error")
                        this.giftVoucherErrors = [this.$t("errors.common.network")];
                    else if (error.message) this.giftVoucherErrors = [error.message];
                    else this.giftVoucherErrors = [this.$t("errors.common.unknown")];
                });
        },
        resetGiftVoucher() {
            this.$store.dispatch("cart/resetGiftVoucher");
        },
    },
    components: {
        ShowErrors,
    },
};
</script>
