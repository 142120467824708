var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("loader-component")
        : [
            _vm.error
              ? _c("small", { staticClass: "text-danger d-block" }, [
                  _vm._v(_vm._s(_vm.error)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("ShowErrors", {
              staticClass: "d-block",
              attrs: { errors: _vm.errors, errorKey: "*" },
            }),
            _vm._v(" "),
            _c("label", [
              _vm._v("Méthode d'expédition\n            "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.shipping_method,
                      expression: "shipping_method",
                    },
                  ],
                  staticClass: "custom-select mt-2",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.shipping_method = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c(
                    "option",
                    { attrs: { disabled: true }, domProps: { value: null } },
                    [_vm._v("Sélectionner une méthode d'expédition")]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.shipping_methods, function (sm, index) {
                    return _c(
                      "option",
                      { key: sm.id, domProps: { value: sm } },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(sm.name) +
                            " -\n                    " +
                            _vm._s(
                              index === 0 && _vm.isDispatchFeesFree
                                ? "Offert"
                                : _vm.$utils.formatPrice(sm.price / 100) + "€"
                            ) +
                            "\n                "
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex justify-content-between" }, [
              _c(
                "button",
                {
                  staticClass: "main-button btn-light text-white d-flex mt-2",
                  staticStyle: { "align-self": "flex-end" },
                  style: _vm.styleCartButton,
                  attrs: { disabled: !_vm.dispatch.isShippingMethodValidated },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("next-step")
                    },
                  },
                },
                [_vm._v("\n                Valider\n            ")]
              ),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }