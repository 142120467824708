var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading > 0
        ? _c("loader-component", { staticClass: "w-100 text-center" })
        : _vm.error
        ? _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "alert alert-danger" }, [
              _vm._v(_vm._s(_vm.error)),
            ]),
          ])
        : _c("div", { staticClass: "container" }, [
            _c(
              "div",
              {
                staticClass: "col-lg-6 col-md-8 col-12",
                staticStyle: { "max-width": "700px", margin: "0 auto" },
              },
              [
                _c("Recap", {
                  staticClass: "border-light b-radius-20 p-4 mb-3",
                  attrs: {
                    command: _vm.command,
                    canPayUntil: _vm.canPayUntil,
                    canPay: _vm.canPay,
                  },
                }),
                _vm._v(" "),
                _c("SelectPaymentMethod", {
                  staticClass: "border-light b-radius-20 p-4 mb-3",
                  attrs: {
                    command: _vm.command,
                    paymentDelayExpired: _vm.paymentDelayExpired,
                  },
                  on: { "fetch-command": _vm.fetchCommand },
                }),
              ],
              1
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }