var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "product mb-3", class: { disable: _vm.productInactive } },
    [
      _c(
        "div",
        {
          staticClass: "row m-0",
          on: {
            click: function ($event) {
              _vm.showModal = _vm.productInactive ? false : true
            },
          },
        },
        [
          _c("div", { staticClass: "col-8 pt-3 pb-2 product-infos" }, [
            _c(
              "h6",
              {
                staticClass: "product-title noshow-widget-font-title",
                style: _vm.restaurantBgColor,
              },
              [_vm._v(_vm._s(_vm.product.name))]
            ),
            _vm._v(" "),
            _vm.product.description != null &&
            _vm.product.description.length < 70
              ? _c("p", {
                  staticClass: "product-description",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$utils.backNtoSpace(_vm.product.description)
                    ),
                  },
                })
              : _vm.product.description != null &&
                _vm.product.description.length >= 70
              ? _c("p", {
                  staticClass: "product-description",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$utils.backNtoSpace(
                        _vm.product.description.substring(0, 70) +
                          "... <span>voir plus</span>"
                      )
                    ),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("span", { staticClass: "product-price" }, [
              _c(
                "span",
                {
                  class: {
                    "text-muted": _vm.hasOffer,
                    "text-strikethrough": _vm.hasOffer,
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$utils.formatPrice(_vm.product.price)) + "€"
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.hasOffer
                ? _c("span", [
                    _vm._v(
                      _vm._s(_vm.$utils.formatPrice(_vm.product.price_offer))
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.productInactive
                ? _c("span", [_vm._v(" - Indisponible")])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("div", {
            staticClass: "col-4 product-image",
            style:
              _vm.imgLink !== null
                ? `background-image: url('${_vm.imgLink}')`
                : "",
          }),
        ]
      ),
      _vm._v(" "),
      _vm.showModal
        ? _c(
            "AddProductToCartModal",
            _vm._b(
              {
                on: {
                  close: function ($event) {
                    _vm.showModal = false
                  },
                },
              },
              "AddProductToCartModal",
              {
                categories: _vm.categories,
                category: _vm.category,
                product: _vm.product,
                addressValidated: _vm.addressValidated,
              },
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }