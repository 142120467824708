<template>
    <div>
        <div class="row">
            <div class="col-12">
                <SelectAddress v-if="!dispatch.isAddressValidated" class="col-12" :dispatch="dispatch" />
                <button
                    class="main-button btn-light text-white d-flex btn-sm"
                    :style="styleCartButton"
                    style="padding: 0.6em 1em !important"
                    v-if="dispatch.isAddressValidated"
                    @click="dispatch.isAddressValidated = false">
                    Changer d'adresse
                </button>
            </div>
        </div>
        <div class="row mt-4" v-if="dispatch.isAddressValidated">
            <div class="col-sm-3">
                <label>Mode d'expédition <small>*</small></label>
            </div>
            <div class="col-sm-9 radio">
                <label class="container" style="width: initial">
                    <input
                        type="radio"
                        name="dispatch_type"
                        :value="DISPATCH_TYPE_HOME.value"
                        v-model="dispatch.type" />
                    <span class="checkmark"></span>
                    {{ DISPATCH_TYPE_HOME.label }} <loader-component-small class="loader-small" v-if="loading" /><span
                        class="text-muted"
                        v-else
                        >{{ home_range_price }}</span
                    >
                </label>
                <label class="container" style="width: initial" v-if="enable_service_points">
                    <input
                        type="radio"
                        name="dispatch_type"
                        :value="DISPATCH_TYPE_SERVICE_POINT.value"
                        v-model="dispatch.type" />
                    <span class="checkmark"></span>
                    {{ DISPATCH_TYPE_SERVICE_POINT.label }}
                    <loader-component-small class="loader-small" v-if="loading" /><span class="text-muted" v-else>{{
                        service_point_range_price
                    }}</span>
                </label>
                <ShowErrors class="d-block" :errors="errors" errorKey="dispatch.type" />
            </div>
        </div>
        <div class="row" v-if="dispatch.type && dispatch.isAddressValidated">
            <SelectServicePoint
                class="col-12"
                v-show="dispatch.type === DISPATCH_TYPE_SERVICE_POINT.value"
                :dispatch="dispatch"
                @next-step="$emit('next-step', $event)" />
            <div class="col-12" v-if="dispatch.type === DISPATCH_TYPE_HOME.value && dispatch.isAddressValidated">
                <button
                    class="main-button btn-light text-white d-flex mt-2"
                    :style="styleCartButton"
                    @click="$emit('next-step')">
                    Valider
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import DispatchTypesEnum from "../../mixins/enums/click_and_collect/DispatchTypesEnum.js";
import ShowErrors from "../errors/ShowErrors.vue";
import SelectAddress from "./SelectAddress.vue";
import SelectServicePoint from "./SelectServicePoint.vue";
import LoaderComponentSmall from "../LoaderComponentSmall.vue";

export default {
    data() {
        return {
            loading: false,
            dispatchRangeHome: [],
            dispatchRangeServicePoint: [],
        };
    },
    props: {
        dispatch: {
            required: true,
        },
        errors: {
            default: null,
        },
    },
    mixins: [DispatchTypesEnum],
    computed: {
        enable_service_points() {
            return this.$store.getters["restaurant/enable_service_points"];
        },
        restaurantBgColorOnly() {
            return this.$store.getters["restaurant/bgcolor"];
        },
        styleCartButton() {
            return { "background-color": `${this.restaurantBgColorOnly} !important` };
        },
        home_range_price() {
            if (!this.dispatchRangeHome) return "";
            return "(à partir de " + this.$utils.formatPrice(this.dispatchRangeHome.price / 100) + "€)";
        },
        service_point_range_price() {
            if (!this.dispatchRangeServicePoint) return "";
            return "(à partir de " + this.$utils.formatPrice(this.dispatchRangeServicePoint.price / 100) + "€)";
        },
    },
    methods: {
        fetchDispatchPriceRanges() {
            this.loading = true;
            this.dispatchRangeHome = null;
            this.dispatchRangeServicePoint = null;
            axios
                .post(`${API_URL}/${this.$api_key}/dispatch_price_ranges?include=is_collect_point`, {
                    cart: { products: this.$store.getters["cart/products"] },
                })
                .then((response) => {
                    this.loading = false;
                    let dispatchRangeHome = response.data.data.filter((s) => !s.is_collect_point);
                    let dispatchRangeServicePoint = response.data.data.filter((s) => s.is_collect_point);
                    if (dispatchRangeHome.length > 0) this.dispatchRangeHome = dispatchRangeHome[0];
                    if (dispatchRangeServicePoint.length > 0)
                        this.dispatchRangeServicePoint = dispatchRangeServicePoint[0];
                })
                .catch(() => (this.loading = false));
        },
    },
    components: {
        ShowErrors,
        SelectAddress,
        SelectServicePoint,
        LoaderComponentSmall,
    },
    created() {
        this.fetchDispatchPriceRanges();
        if (!this.enable_service_points) this.dispatch.type = this.DISPATCH_TYPE_HOME.value;
    },
};
</script>
