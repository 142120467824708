var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.totalAmount != "--"
    ? _c("div", { staticStyle: { width: "100%" } }, [
        _c(
          "a",
          {
            staticClass: "btn-go-to-cart",
            staticStyle: { color: "white !important" },
            style: _vm.styleCartButton,
            attrs: { href: "#cc-cart" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.animateScroll.apply(null, arguments)
              },
            },
          },
          [
            _vm._v("\n        Voir le panier "),
            _c("span", [_vm._v(": " + _vm._s(_vm.totalAmount) + "€")]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }