<template>
    <div :class="layoutClass" style="box-sizing: border-box" class="notranslate" translate="no">
        <MaintenanceError v-if="inMaintenance" />
        <LicencesErrors v-else-if="hasLicenceError" />
        <component v-else :is="`${layout}Layout`" />
    </div>
</template>

<script>
import MainLayout from "./layouts/MainLayout.vue";
import GroupPaymentLayout from "./layouts/GroupPaymentLayout.vue";
import PaymentLayout from "./layouts/PaymentLayout.vue";
import moment from "moment";
import LicencesErrors from "./components/errors/LicencesErrors.vue";
import MaintenanceError from "./components/errors/MaintenanceError.vue";
import WidgetTypesEnum from "./mixins/enums/click_and_collect/WidgetTypesEnum.js";

export default {
    mixins: [WidgetTypesEnum],
    components: {
        MainLayout,
        GroupPaymentLayout,
        PaymentLayout,
        LicencesErrors,
        MaintenanceError,
    },
    computed: {
        layout() {
            let currentRoute = location.href.split(this.$api_key);
            if (currentRoute.length === 1) {
                if (currentRoute[0].includes("/group_payment") && this.$utils.getQueryParameter("command")) return "GroupPayment";
                if (currentRoute[0].includes("/pay") && this.$utils.getQueryParameter("command")) return "Payment";
            } else if (currentRoute.length > 1) {
                currentRoute = currentRoute[1];
                if (currentRoute.startsWith("/page/group_payment") && this.$utils.getQueryParameter("command")) return "GroupPayment";
                if (currentRoute.startsWith("/page/pay") && this.$utils.getQueryParameter("command")) return "Payment";
            }
            return "Main";
        },
        layoutClass() {
            switch (this.layout) {
                case "GroupPayment":
                    return "payment-group";
                case "Payment":
                    return "pay-from-bo";
                default:
                    return "noshow-widget";
            }
        },
        hasLicenceError() {
            return this.$store.getters["error/getHasLicenceError"];
        },
        inMaintenance() {
            return this.$store.getters["error/getInMaintenance"];
        },
    },
    methods: {
        validateWidgetParams() {
            const widgetType = this.$store.getters["widget/type"];
            if (widgetType) {
                if (!this.ALL_WIDGET_TYPES.some((t) => t.value === widgetType)) {
                    const typesConcat = this.ALL_WIDGET_TYPES.map((t) => `"${t.value}"`).join(", ");
                    console.error(`${THEME} Widget: Invalid type "${widgetType}", expected one of: ${typesConcat}`);
                    return;
                }
            }
        },
    },
    created() {
        this.validateWidgetParams();
        if (this.$store.getters["cart/date"] !== null && this.layout === "Main")
            if (moment(this.$store.getters["cart/date"]).isBefore(moment(), "day")) {
                this.$store.dispatch("cart/setDate", moment().format("Y-MM-DD"));
                this.$store.dispatch("cart/setSlot", null);
            }
    },
};
</script>

<style>
@import url(https://fonts.googleapis.com/css?family=DM+Serif+Display|Lato:400,700|Montserrat+Alternates:400,600|Open+Sans:400,600|Oswald:400,600|Playfair+Display:400,600|Poppins:400,600|Raleway:400,600|Roboto:400,700&display=swap);
</style>
