export default {
    id: null,
    firstName: null,
    lastName: null,
    email: null,
    tel: null,
    tel_country: null,
    company: null,
    civility: null,
    address: null,
};
