<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div v-if="command.remaining_amount_to_pay === 0" class="text-center">
                    Cette commande a été totalement payée
                    <div class="w-100 text-center" v-if="command.redirect_url">
                        <a
                            :href="command.redirect_url"
                            class="mt-3 btn main-button"
                            :style="{ 'background-color': `${bgColor} !important` }"
                            >Retour au site</a
                        >
                    </div>
                </div>
                <div v-else-if="command.status === COMMAND_CANCELED.value" class="text-center">
                    Cette commande a été annulée
                    <div class="w-100 text-center" v-if="command.redirect_url">
                        <a
                            :href="command.redirect_url"
                            class="mt-3 btn main-button"
                            :style="{ 'background-color': `${bgColor} !important` }"
                            >Retour au site</a
                        >
                    </div>
                </div>
                <div v-else-if="paymentDelayExpired" class="text-center text-danger">
                    Vous avez dépassé le temps limite pour le paiement de cette commande.<br />
                    La commande a été annulée.
                    <div class="w-100 text-center" v-if="command.redirect_url">
                        <a
                            :href="command.redirect_url"
                            class="mt-3 btn main-button"
                            :style="{ 'background-color': `${bgColor} !important` }"
                            >Retour au site</a
                        >
                    </div>
                </div>
                <component
                    v-else-if="choosenPaymentMethod"
                    @paid="$emit('paid', $event)"
                    :method="choosenPaymentMethod"
                    :command="command"
                    :is="$utils.capitalize(choosenPaymentMethod.method)" />
                <span class="text-danger" v-else-if="allPaymentMethods.length === 0">
                    Aucun moyen de paiement n'est disponible pour cette commande.
                </span>
                <template v-else>
                    <div class="row">
                        <div class="col-12 mb-2">Sélectionnez votre moyen de paiement</div>
                        <div class="d-flex align-items-center pl-3 pr-3">
                            <div
                                v-for="method in allPaymentMethods"
                                :key="method.method"
                                class="border-light p-1 mt-2 ml-2"
                                :class="{ 'cursor-pointer': isMethodAvailable(method) }"
                                :style="isMethodAvailable(method) ? '' : 'opacity: 0.5;'">
                                <img
                                    style="height: 50px; margin: auto"
                                    :src="`/images/${method.logo}`"
                                    @click="choosePaymentMethod(method)" />
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import Stripe from "./PaymentMethods/Stripe.vue";
import Edenred from "./PaymentMethods/Edenred.vue";
import Payplug from "./PaymentMethods/PayPlug.vue";
import PaymentStatusEnum from "../../mixins/enums/click_and_collect/PaymentStatusEnum.js";
import CommandStatusEnum from "../../mixins/enums/click_and_collect/CommandStatusEnum.js";

export default {
    data() {
        return {
            choosenPaymentMethod: null,
        };
    },
    mixins: [PaymentStatusEnum, CommandStatusEnum],
    props: {
        command: {
            type: Object,
            required: true,
        },
        paymentDelayExpired: {
            default: false,
        },
    },
    computed: {
        allPaymentMethods() {
            return this.$store.getters["restaurant/available_payment_methods"];
        },
        bgColor() {
            return this.$store.getters["restaurant/bgcolor"];
        },
    },
    methods: {
        choosePaymentMethod(method) {
            if (this.isMethodAvailable(method)) this.choosenPaymentMethod = method;
        },
        isMethodAvailable(method) {
            return !this.command.command_payments.data.some((payment) => {
                return (
                    payment.online_payment_method == method.method &&
                    this.$utils.inEnum(payment.status, this.PAID_STATUS)
                );
            });
        },
        resetChoosenPaymentMethod(fetchCommand = false) {
            this.choosenPaymentMethod = null;
            if (fetchCommand) {
                this.$emit("fetch-command");
            }
        },
    },
    created() {
        const askedPaymentMethod = this.$utils.getQueryParameter("payment_method");
        if (askedPaymentMethod) {
            const foundMethod = this.allPaymentMethods.find(
                (method) => method.method == askedPaymentMethod && this.isMethodAvailable(method)
            );
            if (foundMethod) {
                this.choosenPaymentMethod = foundMethod;
                return;
            }
        }
        if (this.allPaymentMethods.length === 1) this.choosenPaymentMethod = this.allPaymentMethods[0];
    },
    components: {
        Stripe,
        Edenred,
        Payplug,
    },
};
</script>

<style>
.cursor-pointer {
    cursor: pointer;
}
</style>
