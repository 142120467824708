var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex align-items-center" }, [
    _c("input", {
      ref: "noshowTxtCopyUrl",
      staticClass: "form-control",
      attrs: { type: "text", readonly: "" },
      domProps: { value: _vm.textToCopy },
    }),
    _vm._v(" "),
    _c(
      "button",
      {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip",
            value: _vm.currentCopyTooltip,
            expression: "currentCopyTooltip",
          },
        ],
        staticClass: "btn",
        attrs: { type: "button" },
        on: { click: _vm.copy },
      },
      [
        _c("i", {
          staticClass: "far fa-copy",
          staticStyle: { color: "#666666" },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }