<template>
    <div class="d-inline">
        <feather class="loader-rotate ml-1 text-muted" type="refresh-cw"></feather>
    </div>
</template>

<script>
export default {
    name: "LoaderComponentSmall",
};
</script>

<style scoped type="text/css">
@keyframes loaderRotate {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

.loader-rotate {
    animation: 2s linear infinite loaderRotate;
}
</style>
