import * as Sentry from "@sentry/vue";
import Vue from "vue";

const initSentry = function (applicationKey) {
    const tracesSampleRate = APP_ENV === "production" ? 0.1 : 1.0;

    const dsn =
        THEME === "yservices"
            ? "https://7a5ef41889964d59a7d2ed22fa8832dd@o59715.ingest.sentry.io/4504633553780740"
            : "https://f71fcf263c494e668a1a5f44e3fa724e@o59715.ingest.sentry.io/6228119";

    Sentry.init({
        Vue,
        dsn,
        tracesSampleRate,
        environment: APP_ENV,
        logErrors: true,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.thirdPartyErrorFilterIntegration({
                filterKeys: [applicationKey],
                behaviour: "drop-error-if-exclusively-contains-third-party-frames",
            }),
        ],
    });
};

export { initSentry };
