var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c(
      "div",
      {
        staticClass: "border-bottom w-100",
        staticStyle: { position: "relative" },
        attrs: { slot: "header" },
        slot: "header",
      },
      [
        _c("div", { staticClass: "w-100 p-3" }, [
          _c("div", { staticClass: "d-flex justify-content-between" }, [
            _c(
              "h5",
              { staticClass: "mt-2", style: _vm.basicRestaurantBgColor },
              [_vm._v(_vm._s(_vm.$t("info.paid_title")))]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: {
                  type: "button",
                  "data-dismiss": "modal",
                  "aria-label": "Close",
                },
                on: { click: _vm.close },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "text-black",
                    attrs: { "aria-hidden": "true" },
                  },
                  [_vm._v("×")]
                ),
              ]
            ),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "p-3", attrs: { slot: "body" }, slot: "body" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("h6", [_c("strong", [_vm._v(_vm._s(_vm.$t("info.paid")))])]),
            _vm._v(" "),
            _vm.collectType === "collect"
              ? [
                  _c("p", [
                    _c("strong", [_vm._v("Date et heure de retrait :")]),
                    _vm._v(
                      " le " +
                        _vm._s(_vm.moment(_vm.date).format("DD MMMM Y")) +
                        " à\n                        " +
                        _vm._s(_vm.$utils.formatHour(_vm.slot.hour)) +
                        "\n                    "
                    ),
                  ]),
                ]
              : _vm.collectType === "delivery"
              ? [
                  _c("p", [
                    _c("strong", [_vm._v("Date et heure de livraison :")]),
                    _vm._v(
                      " le\n                        " +
                        _vm._s(_vm.moment(_vm.date).format("DD MMMM Y")) +
                        " entre " +
                        _vm._s(_vm.$utils.formatHour(_vm.slot.hour_start)) +
                        " et\n                        " +
                        _vm._s(_vm.$utils.formatHour(_vm.slot.hour_end)) +
                        "\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v("Adresse de livraison : " + _vm._s(_vm.address)),
                  ]),
                ]
              : _vm._e(),
            _vm._v(" "),
            _c("p", [_c("strong", [_vm._v("Récapitulatif de la commande :")])]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ml-2" },
              _vm._l(_vm.products, function (product, index) {
                return _c(
                  "p",
                  { key: index, staticClass: "d-block" },
                  [
                    _c("span", { staticClass: "d-block" }, [
                      _vm._v(
                        _vm._s(product.quantity) +
                          " x " +
                          _vm._s(product.product.name)
                      ),
                    ]),
                    _vm._v(" "),
                    product.comment
                      ? _c("span", { staticClass: "d-block" }, [
                          _vm._v(_vm._s(product.comment)),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    product.options && product.options.length > 0
                      ? _c("span", { staticClass: "ml-2" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(product.options, function (option, index) {
                      return _c(
                        "span",
                        { key: index, staticStyle: { "font-size": "0.8rem" } },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(option.option.cc_product.name) +
                              "\n                            "
                          ),
                          !option.option.include > 0
                            ? _c("span", [
                                _vm._v(
                                  "\n                                (" +
                                    _vm._s(
                                      _vm.$utils.formatPrice(
                                        option.option.price
                                      )
                                    ) +
                                    "€)"
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          option.quantity > 1
                            ? _c("span", [
                                _vm._v(" (x" + _vm._s(option.quantity) + ")"),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          index < product.options.length - 1
                            ? _c("span", [_vm._v(" • ")])
                            : _vm._e(),
                        ]
                      )
                    }),
                  ],
                  2
                )
              }),
              0
            ),
          ],
          2
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
      _c("div", { staticClass: "w-100 p-3 border-top text-right" }, [
        _c(
          "button",
          {
            staticClass: "main-button",
            style: _vm.buttonRestaurantBgColor,
            on: { click: _vm.close },
          },
          [_vm._v("Fermer")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }