var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    { attrs: { id: "noshow-cc-header" } },
    [
      _vm.loading > 0 ? _c("loader-component") : _vm._e(),
      _vm._v(" "),
      _vm.errors && _vm.errors.length > 0
        ? _c("small", {
            staticClass: "text-danger",
            domProps: { innerHTML: _vm._s(_vm.errors.join("<br/>")) },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading === 0,
              expression: "loading === 0",
            },
          ],
          staticClass: "pickup-info p-3",
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "h5",
                    {
                      staticClass: "d-inline-block noshow-widget-font-title",
                      style: _vm.restaurantBgColor,
                    },
                    [_vm._v("Livraison")]
                  ),
                  _vm._v(" "),
                  _vm.isDispatchAvailable && _vm.canChangeCollectType
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "main-button float-right btn-light d-inline-block ml-3 text-white",
                          staticStyle: { padding: "0.6em 1em !important" },
                          style: _vm.styleCartButton,
                          on: {
                            click: function ($event) {
                              return _vm.$emit(
                                "changeCollectType",
                                _vm.WIDGET_DISPATCH.value
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            Expédition à domicile\n                        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isCollectAvailable && _vm.canChangeCollectType
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "main-button float-right btn-light d-inline-block ml-3 text-white",
                          staticStyle: { padding: "0.6em 1em !important" },
                          style: _vm.styleCartButton,
                          on: {
                            click: function ($event) {
                              return _vm.$emit(
                                "changeCollectType",
                                _vm.WIDGET_COLLECT.value
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            Retrait sur place\n                        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-md-6",
                    staticStyle: { "margin-top": "10px !important" },
                  },
                  [
                    _c("label", { staticClass: "mr-3" }, [
                      _vm._v("Date de livraison"),
                    ]),
                    _vm._v(" "),
                    _c("datepicker", {
                      staticClass: "date-resa-cal date-resa",
                      attrs: {
                        format: "dd/MM/yyyy",
                        "use-utc": true,
                        "disabled-dates": _vm.disabledDates,
                        "calendar-class": "positition-relative z-2000 mt-2",
                        "input-class": "w-auto",
                        "monday-first": true,
                        language: _vm.fr,
                      },
                      model: {
                        value: _vm.currentDate,
                        callback: function ($$v) {
                          _vm.currentDate = $$v
                        },
                        expression: "currentDate",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "col-md-6",
                    staticStyle: { "margin-top": "10px !important" },
                  },
                  [
                    _c("label", [_vm._v("Créneau de livraison")]),
                    _vm._v(" "),
                    _vm.slots.length > 0
                      ? _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.currentSlot,
                                expression: "currentSlot",
                              },
                            ],
                            staticClass: "custom-select",
                            staticStyle: { "margin-top": "-2px !important" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.currentSlot = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          _vm._l(_vm.slots, function (slot) {
                            return _c(
                              "option",
                              { key: slot.id, domProps: { value: slot.id } },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.slotLabel(slot.hour_start)) +
                                    " - " +
                                    _vm._s(_vm.slotLabel(slot.hour_end)) +
                                    "\n                            "
                                ),
                              ]
                            )
                          }),
                          0
                        )
                      : _c(
                          "select",
                          {
                            staticClass: "custom-select disabled",
                            staticStyle: { "margin-top": "-2px !important" },
                            attrs: { disabled: "disabled" },
                          },
                          [_c("option", [_vm._v("Aucun créneau disponible")])]
                        ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm.currentSlot
                ? _c(
                    "div",
                    {
                      staticClass: "row",
                      staticStyle: { "margin-top": "10px !important" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          !_vm.isAddressValidated
                            ? _c("label", [
                                _vm._v(
                                  "Adresse de livraison\n                            "
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.clientAddress,
                                      expression: "clientAddress",
                                    },
                                  ],
                                  staticClass: "form-control mt-2",
                                  attrs: {
                                    disabled: _vm.isValidatingAddress,
                                    type: "text",
                                    name: "address",
                                    placeholder: "Saisissez votre adresse",
                                    autofocus: "",
                                  },
                                  domProps: { value: _vm.clientAddress },
                                  on: {
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) return
                                        _vm.clientAddress = $event.target.value
                                      },
                                      _vm.clientAddressChanged,
                                    ],
                                  },
                                }),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.errorAddress
                            ? _c("small", { staticClass: "text-danger" }, [
                                _vm._v(_vm._s(_vm.errorAddress)),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.addresses_.length > 0
                            ? _c(
                                "div",
                                { staticClass: "address_results border-light" },
                                _vm._l(
                                  _vm.addresses_,
                                  function (address, index) {
                                    return _c(
                                      "span",
                                      {
                                        key: index,
                                        staticClass: "d-block mb-1 mt-1",
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectAddress(address)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(address.description) +
                                            "\n                            "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.isAddressValidated
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "main-button btn-light text-white d-flex mt-2",
                                  staticStyle: { "align-self": "flex-end" },
                                  style: _vm.styleCartButton,
                                  attrs: {
                                    disabled:
                                      _vm.finalClientAddress === null ||
                                      _vm.isValidatingAddress ||
                                      _vm.isLoadingSlot,
                                  },
                                  on: { click: _vm.validateAddress },
                                },
                                [
                                  _vm._v(
                                    "\n                            Valider\n                        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isAddressValidated
                            ? [
                                _c("label", [_vm._v("Adresse de livraison")]),
                                _c("br"),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "13px !important",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.clientAddress))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "main-button btn-light text-white d-flex mt-2",
                                    staticStyle: { "align-self": "flex-end" },
                                    style: _vm.styleCartButton,
                                    on: { click: _vm.changeAddress },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                Changer d'adresse\n                            "
                                    ),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6" }, [
              _c(
                "div",
                {
                  staticClass: "pickup-point p-3",
                  staticStyle: { "background-color": "#f7f7f7" },
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("h5", { staticClass: "noshow-widget-font-title" }, [
                        _vm._v("Informations de l'établissement"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "h6",
                        {
                          style:
                            "color: " +
                            _vm.restaurantBgColorOnly +
                            " !important;",
                        },
                        [
                          _c(
                            "strong",
                            { staticClass: "noshow-widget-font-title" },
                            [_vm._v(_vm._s(_vm.restaurantName))]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(_vm.restaurantAddress))]),
                    ]),
                    _vm._v(" "),
                    _vm.isAddressValidated && _vm.deliveryInfoForClient
                      ? _c("div", { staticClass: "col-12 mt-2" }, [
                          _c(
                            "h6",
                            { staticClass: "noshow-widget-font-title" },
                            [_vm._v("Informations de livraison")]
                          ),
                          _vm._v(" "),
                          _c("p", {
                            domProps: {
                              innerHTML: _vm._s(_vm.deliveryInfoForClient),
                            },
                          }),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }