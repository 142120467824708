var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currentCategory !== null
    ? _c("div", { staticClass: "nav sidemenu" }, [
        _c("div", { staticClass: "w-100 text-center" }, [
          _c("div", { staticClass: "float-left" }, [
            _c("div", { staticClass: "d-flex align-items-center" }, [
              _c(
                "button",
                {
                  staticClass: "sidemenu_btn",
                  class: { active: _vm.navOpen },
                  on: {
                    click: function ($event) {
                      _vm.navOpen = !_vm.navOpen
                    },
                  },
                },
                [
                  _c("span", { staticClass: "top" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "mid" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "bottom" }),
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "pointer libelle-categorie",
                  on: {
                    click: function ($event) {
                      _vm.navOpen = !_vm.navOpen
                    },
                  },
                },
                [_vm._v("Menu")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "title-categorie text-center" }, [
            _vm._v(
              "\n            " + _vm._s(_vm.currentCategory.name) + "\n        "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "nav",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.navOpen,
                expression: "navOpen",
              },
            ],
          },
          [
            _c("div", { staticClass: "sidemenu_wrapper" }, [
              _c(
                "ul",
                { staticClass: "nav sidemenu_list" },
                _vm._l(_vm.items, function (item) {
                  return _c("nav-item-component", {
                    key: item.id,
                    staticClass: "sidemenu_item",
                    attrs: { item: item },
                    on: { "go-to": _vm.goTo },
                  })
                }),
                1
              ),
            ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }