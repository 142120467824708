var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("label", [
      _vm._v("Merci de renseigner votre adresse\n        "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.clientAddress,
            expression: "clientAddress",
          },
        ],
        staticClass: "form-control mt-2",
        attrs: {
          disabled: _vm.isValidatingAddress,
          type: "text",
          name: "address",
          placeholder: "Saisissez votre adresse",
          autofocus: "",
        },
        domProps: { value: _vm.clientAddress },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.clientAddress = $event.target.value
            },
            _vm.clientAddressChanged,
          ],
        },
      }),
    ]),
    _vm._v(" "),
    _vm.errorAddress
      ? _c("small", { staticClass: "text-danger" }, [
          _vm._v(_vm._s(_vm.errorAddress)),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.addresses_.length > 0
      ? _c(
          "div",
          { staticClass: "address_results border-light" },
          _vm._l(_vm.addresses_, function (address, index) {
            return _c(
              "span",
              {
                key: index,
                staticClass: "d-block mb-1 mt-1",
                on: {
                  click: function ($event) {
                    return _vm.selectAddress(address)
                  },
                },
              },
              [
                _vm._v(
                  "\n            " + _vm._s(address.description) + "\n        "
                ),
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "main-button btn-light text-white d-flex mt-2",
        staticStyle: { "align-self": "flex-end" },
        style: _vm.styleCartButton,
        attrs: {
          disabled:
            _vm.dispatch.finalClientAddress === null || _vm.isValidatingAddress,
        },
        on: { click: _vm.validateAddress },
      },
      [_vm._v("\n        Valider\n    ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }