export default {
    name(state) {
        return state.name;
    },
    address(state) {
        return state.address;
    },
    bgcolor(state) {
        return state.bgcolor;
    },
    widgetFontTitle(state) {
        return state.widget_font_title;
    },
    cgv(state) {
        return state.cgv;
    },
    stripeClientId(state) {
        return state.stripe_client_id;
    },
    hasCardPaymentSystem(state) {
        return state.has_card_payment_system;
    },
    availableCollectTypes(state) {
        return state.available_collect_types;
    },
    isDeliveryAvailable(state) {
        return state.available_collect_types && state.available_collect_types.includes("delivery");
    },
    isCollectAvailable(state) {
        return state.available_collect_types && state.available_collect_types.includes("collect");
    },
    isDispatchAvailable(state) {
        return state.available_collect_types && state.available_collect_types.includes("dispatch");
    },
    futureServices(state) {
        return state.futureServices;
    },
    futureClosures(state) {
        return state.futureClosures;
    },
    futureOpenings(state) {
        return state.futureOpenings;
    },
    lastDayOrderable(state) {
        return state.lastDayOrderable;
    },
    slots(state) {
        return state.slots;
    },
    tel(state) {
        return state.tel;
    },
    email(state) {
        return state.email;
    },
    isTodayAvailable(state) {
        return state.is_today_available;
    },
    isTodayAvailableReason(state) {
        return state.is_today_available_reason;
    },
    isGroupPaymentEnable(state) {
        return state.is_group_payment_enable;
    },
    firstOrderableDate(state) {
        return state.first_orderable_date;
    },
    dispatch_min_amount(state) {
        return state.dispatch_min_amount;
    },
    dispatch_free_above(state) {
        return state.dispatch_free_above;
    },
    sendcloud_public_key(state) {
        return state.sendcloud_public_key;
    },
    enable_service_points(state) {
        return state.enable_service_points;
    },
    promo_code_enabled(state) {
        return state.promo_code_enabled;
    },
    gv_enabled(state) {
        return (type) => state.gv_enabled[type] === true;
    },
    available_payment_methods(state) {
        return state.available_payment_methods;
    },
    default_tel_country(state) {
        return state.default_tel_country;
    },
    displayPerCategory(state) {
        return state.display_per_category;
    },
    useBurgerMenu(state) {
        return state.use_burger_menu;
    },
};
