import axios from "axios";

export default {
    setClient({ commit }, client) {
        commit("setId", client.id);
        commit("setFirstName", client.firstname);
        commit("setLastName", client.lastname);
        commit("setEmail", client.email);
        commit("setTel", client.tel);
        commit("setTelCountry", client.telCountry);
        commit("setCompany", client.company);
        commit("setCivility", client.civility);
    },
    setFirstName({ commit }, firstname) {
        commit("setFirstName", firstname);
    },
    setLastName({ commit }, lastname) {
        commit("setLastName", lastname);
    },
    setEmail({ commit }, email) {
        commit("setEmail", email);
    },
    setTel({ commit }, tel) {
        commit("setTel", tel);
    },
    setTelCountry({ commit }, telCountry) {
        commit("setTelCountry", telCountry);
    },
    setCompany({ commit }, company) {
        commit("setCompany", company);
    },
    setCivility({ commit }, civility) {
        commit("setCivility", civility);
    },
    validateAddress({ commit }, params) {
        return axios.post(`${API_URL}/${this._vm.$api_key}/validateAddress`, params).then((response) => {
            commit("setAddress", response.data.placeDetails);
            return response;
        });
    },
    validateAddressDispatch({ commit }, params) {
        return axios
            .get(`${API_URL}/${this._vm.$api_key}/placeDetails`, {
                params,
            })
            .then((response) => {
                commit("setAddress", response.data);
                return response;
            });
    },
};
