var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("span", { domProps: { innerHTML: _vm._s(_vm.custom_css) } }),
      _vm._v(" "),
      _vm.loadingRestaurant
        ? _c("LoaderComponent")
        : _vm.errorRestaurant
        ? _c("div", { staticClass: "text-center" }, [
            _vm._v("\n        " + _vm._s(_vm.errorRestaurant) + " "),
            _c("br"),
            _vm._v(" "),
            _c("small", [
              _vm._v(
                "Pour toutes informations complémentaires, merci de nous contacter par téléphone au " +
                  _vm._s(_vm.restaurantTel)
              ),
            ]),
          ])
        : _vm.currentHeaderComponent
        ? _c(_vm.currentHeaderComponent, {
            ref: "headerComponent",
            tag: "component",
            attrs: { canChangeCollectType: _vm.canChangeCollectType },
            on: {
              noSlotAvailableForToday: function ($event) {
                return _vm.$emit("noSlotAvailableForToday", $event)
              },
              slotSelected: function ($event) {
                return _vm.$emit("slotSelected", $event)
              },
              noSlot: function ($event) {
                return _vm.$emit("noSlot", $event)
              },
              setLoading: function ($event) {
                return _vm.$emit("setLoading", $event)
              },
              noProducts: function ($event) {
                return _vm.$emit("noProducts", $event)
              },
              changeCollectType: _vm.changeCollectType,
              "widget-message": function ($event) {
                return _vm.$emit("widget-message", $event)
              },
              "widget-closed": function ($event) {
                return _vm.$emit("widget-closed", $event)
              },
              "address-validated": function ($event) {
                return _vm.$emit("address-validated", $event)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }