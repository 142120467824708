var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cart-sticky", attrs: { id: "cc-cart" } },
    [
      _c(
        "div",
        { staticClass: "cart p-3" },
        [
          _c(
            "h5",
            { staticClass: "noshow-widget-font-title", style: _vm.styleTitle },
            [_vm._v("Votre commande")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "cart-body mb-3" },
            _vm._l(_vm.products, function (product, index) {
              return _c(
                "div",
                { key: index, staticClass: "cart-product pt-1 pb-1" },
                [
                  _c("div", { staticClass: "row div-cart-price" }, [
                    _c("div", { staticClass: "col-3 pr-1" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: product.quantity,
                            expression: "product.quantity",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "number", min: 1 },
                        domProps: { value: product.quantity },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                product,
                                "quantity",
                                _vm._n($event.target.value)
                              )
                            },
                            function ($event) {
                              return _vm.productQuantityChanged(index)
                            },
                          ],
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-6 pl-1 pr-1" }, [
                      _c("p", [
                        _c("strong", { staticClass: "d-block" }, [
                          _vm._v(_vm._s(product.product.name)),
                        ]),
                        _vm._v(" "),
                        product.comment
                          ? _c("span", { staticClass: "d-block" }, [
                              _vm._v(_vm._s(product.comment)),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticClass: "pl-3",
                          staticStyle: {
                            "list-style": "none",
                            "font-size": "0.8rem",
                            color: "#666666",
                          },
                        },
                        _vm._l(product.options, function (option, index) {
                          return _c("span", { key: index }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(option.option.cc_product.name) +
                                "\n                                "
                            ),
                            !option.option.include > 0
                              ? _c("span", [
                                  _vm._v(
                                    " (" +
                                      _vm._s(
                                        _vm.$utils.formatPrice(
                                          option.option.price
                                        )
                                      ) +
                                      "€)"
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            option.quantity > 1
                              ? _c("span", [
                                  _vm._v(" (x" + _vm._s(option.quantity) + ")"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            index < product.options.length - 1
                              ? _c("span", [_vm._v(" • ")])
                              : _vm._e(),
                          ])
                        }),
                        0
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-3 pl-0 text-right" }, [
                      _c("p", { staticClass: "text-right" }, [
                        _c("strong", { staticClass: "cart-price" }, [
                          _c(
                            "span",
                            {
                              class: {
                                "text-muted": _vm.hasOffer(product.product),
                                "text-strikethrough": _vm.hasOffer(
                                  product.product
                                ),
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.currentPrice(product, false)) + "€"
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.hasOffer(product.product)
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.currentPrice(product)) + "€"),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-sm text-danger p-0 pl-1 float-right btn-delete-item",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteCartItem(index)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                +\n                            "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.errors[index] && _vm.errors[index].length > 0
                    ? _c("small", {
                        staticClass: "d-block text-danger",
                        domProps: {
                          innerHTML: _vm._s(_vm.errors[index].join("<br />")),
                        },
                      })
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          !_vm.hasValidPromoCode &&
          _vm.promoCodeEnabled &&
          !_vm.hasValidGiftVoucher
            ? _c(
                "form",
                { staticClass: "row", on: { submit: _vm.validatePromoCode } },
                [
                  _c("div", { staticClass: "col-8 mb-2" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.tmpPromoCode,
                          expression: "tmpPromoCode",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", placeholder: "Code promotionnel" },
                      domProps: { value: _vm.tmpPromoCode },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.tmpPromoCode = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-4 mb-2" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "main-button btn-light d-inline-block text-white",
                        style: _vm.styleCartButton,
                        attrs: {
                          disabled: _vm.loading || !_vm.tmpPromoCode,
                          type: "submit",
                        },
                      },
                      [_vm._v("\n                    OK\n                ")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("ShowErrors", {
                    staticClass: "col-12",
                    attrs: { errors: _vm.promoCodeErrors, errorKey: "*" },
                  }),
                ],
                1
              )
            : _vm.hasValidPromoCode && _vm.promoCodeEnabled
            ? _c("span", { staticClass: "text-muted d-block mb-2" }, [
                _c("small", [
                  _vm._v(
                    "Code promotionnel : " + _vm._s(_vm.promoCodeDisplayable)
                  ),
                ]),
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-sm text-danger p-0 pl-1 btn-delete-item d-inline-block",
                    staticStyle: { "margin-top": "-2px !important" },
                    attrs: { type: "button" },
                    on: { click: _vm.resetPromoCode },
                  },
                  [_vm._v("\n                +\n            ")]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("AddGiftVoucher", {
            attrs: {
              loading: _vm.loading,
              totalAmount: _vm.totalAmountWithPromoCode,
            },
            on: {
              "start-loading": function ($event) {
                _vm.loading++
              },
              "stop-loading": function ($event) {
                _vm.loading--
              },
            },
          }),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.comment,
                expression: "comment",
              },
            ],
            staticClass: "form-control mb-2",
            staticStyle: { height: "initial !important" },
            attrs: { placeholder: "Note pour l'établissement" },
            domProps: { value: _vm.comment },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.comment = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "main-button",
              style: _vm.styleCartButton,
              attrs: {
                disabled:
                  _vm.products.length === 0 ||
                  _vm.invalidCart ||
                  !_vm.hasCardPaymentSystem ||
                  _vm.loading > 0,
              },
              on: { click: _vm.showPaymentModal },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-8 text-left" }, [
                  _vm._v("Valider la commande"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-4 text-right" }, [
                  _c(
                    "span",
                    {
                      class: {
                        "text-white":
                          (_vm.hasValidPromoCode || _vm.hasValidGiftVoucher) &&
                          _vm.totalAmount !== 0,
                        "text-strikethrough":
                          (_vm.hasValidPromoCode || _vm.hasValidGiftVoucher) &&
                          _vm.totalAmount !== 0,
                      },
                    },
                    [_vm._v(_vm._s(_vm.totalAmountStr) + "€")]
                  ),
                  _vm._v(" "),
                  (_vm.hasValidPromoCode || _vm.hasValidGiftVoucher) &&
                  _vm.totalAmount !== 0
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.totalAmountStrWithPromoCodeAndGiftVoucher
                          ) + "€"
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showModal
        ? _c("PaymentModal", {
            on: {
              close: function ($event) {
                _vm.showModal = false
              },
              paid: _vm.paid,
              saved: _vm.saved,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showShowErrorsModal
        ? _c("ShowErrorsModal", {
            attrs: { errors: _vm.errorsForModal },
            on: {
              close: function ($event) {
                _vm.showShowErrorsModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }