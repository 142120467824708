<template>
    <div>
        <div v-show="!loadingPayment" class="p-3 border mt-2" id="stripeCardForm"></div>
        <span class="d-block text-danger" v-if="errorCard">{{ errorCard }}</span>
    </div>
</template>

<script>
export default {
    data() {
        return {
            card: null,
            stripe: null,
            errorCard: null,
        };
    },
    props: {
        paymentIntent: {
            type: Object,
            required: true,
        },
        groupPayment: {
            type: Object,
            required: true,
        },
        loadingPayment: {
            default: false,
        },
    },
    computed: {
        stripe_client_id() {
            return this.$store.getters["restaurant/stripeClientId"];
        },
    },
    methods: {
        initStripe() {
            this.stripe = Stripe(STRIPE_API_KEY, { stripeAccount: this.stripe_client_id });
            if (this.stripe) {
                this.card = this.stripe.elements().create("card");
                this.card.mount(document.getElementById("stripeCardForm"));
            } else {
                this.error = this.$t("errors.common.stripe_init");
            }
        },
        processPayment() {
            this.errorCard = null;
            this.$emit("set-loading", true);
            this.stripe
                .handleCardPayment(this.paymentIntent.client_secret, this.card, {
                    payment_method_data: {
                        billing_details: {
                            email: `${this.groupPayment.email}`,
                        },
                    },
                })
                .then((result) => {
                    this.$emit("set-loading", false);
                    if (result.error) this.errorCard = result.error.message;
                    else this.$emit("paid");
                })
                .catch((error) => {
                    this.$emit("set-loading", false);
                    this.errorCard = error.message;
                });
        },
    },
    mounted() {
        this.initStripe();
    },
};
</script>
