export default {
    data() {
        return {
            ONLINE_PAYMENT_METHOD_STRIPE: {
                value: "stripe",
                label: "CB, Visa, Mastercard",
            },
            ONLINE_PAYMENT_METHOD_EDENRED: {
                value: "edenred",
                label: "Edenred",
            },
            ONLINE_PAYMENT_METHOD_PAYPLUG: {
                value: "payplug",
                label: "CB, Visa, Mastercard",
            },
        };
    },
    methods: {
        getOnlinePaymentMethodLabel(value) {
            const found = this.ONLINE_PAYMENT_METHODS.find((w) => w.value == value);
            if (typeof found != "undefined") return found.label;
            return value;
        },
    },
    computed: {
        ONLINE_PAYMENT_METHODS() {
            return [
                this.ONLINE_PAYMENT_METHOD_STRIPE,
                this.ONLINE_PAYMENT_METHOD_EDENRED,
                this.ONLINE_PAYMENT_METHOD_PAYPLUG,
            ];
        },
    },
};
