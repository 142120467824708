var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container pt-2" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        {
          staticClass: "col-lg-6 col-md-8 col-12",
          staticStyle: { "max-width": "700px", margin: "0 auto" },
        },
        [
          _c("PaymentGroupCommandRecap", {
            staticClass: "border-light b-radius-20 p-4 mb-3",
            attrs: { command: _vm.command },
          }),
          _vm._v(" "),
          _vm.loading
            ? _c("LoaderComponent", { staticClass: "w-100 text-center" })
            : _vm.error
            ? _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "mb-3 back-to-list",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("go-back")
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fas fa-angle-left mr-1" }),
                    _vm._v(" Voir la liste des emails\n                "),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v(_vm._s(_vm.error)),
                ]),
              ])
            : _c("div", { staticClass: "border-light b-radius-20 p-4 mb-3" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "mb-3 back-to-list",
                          on: {
                            click: function ($event) {
                              return _vm.$emit("go-back")
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fas fa-angle-left mr-1" }),
                          _vm._v(
                            " Voir la liste des emails\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      !_vm.paid
                        ? [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-content-between" },
                              [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.groupPayment.email)),
                                ]),
                                _vm._v(" "),
                                _c("strong", { style: _vm.styleColorWidget }, [
                                  _vm._v(
                                    "Votre part : " +
                                      _vm._s(
                                        _vm.$utils.formatPrice(
                                          _vm.groupPayment.amount
                                        )
                                      ) +
                                      "€"
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.canPay
                              ? _c("p", [
                                  _vm._v(
                                    "Renseignez vos informations de carte bancaire"
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.loadingPayment
                              ? _c("LoaderComponent", {
                                  staticClass: "w-100 text-center",
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.canPay
                              ? [
                                  _vm.paymentIntentType ===
                                  _vm.CARD_PAYMENT_SYSTEM_STRIPE.value
                                    ? _c("StripePaymentForm", {
                                        ref: "stripePaymentForm",
                                        attrs: {
                                          paymentIntent: _vm.paymentIntent,
                                          groupPayment: _vm.groupPayment,
                                          loadingPayment: _vm.loadingPayment,
                                        },
                                        on: {
                                          "set-loading": function ($event) {
                                            _vm.loadingPayment = $event
                                          },
                                          paid: function ($event) {
                                            _vm.paid = true
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.paymentIntentType ===
                                  _vm.CARD_PAYMENT_SYSTEM_PAYPLUG.value
                                    ? _c("PayPlugPaymentForm", {
                                        ref: "payplugPaymentForm",
                                        attrs: {
                                          paymentIntent: _vm.paymentIntent,
                                          groupPayment: _vm.groupPayment,
                                          loadingPayment: _vm.loadingPayment,
                                        },
                                        on: {
                                          "set-loading": function ($event) {
                                            _vm.loadingPayment = $event
                                          },
                                          paid: function ($event) {
                                            _vm.paid = true
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.errorCard
                              ? _c(
                                  "span",
                                  { staticClass: "d-block text-danger" },
                                  [_vm._v(_vm._s(_vm.errorCard))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.canPay
                              ? _c("p", { staticClass: "mb-0" }, [
                                  _vm._v(
                                    "\n                                Vous avez jusqu'à " +
                                      _vm._s(
                                        _vm.canPayUntil.format("HH[h]mm")
                                      ) +
                                      " pour procéder au réglement.\n                            "
                                  ),
                                ])
                              : _c("p", { staticClass: "mb-0 text-danger" }, [
                                  _vm._v(
                                    "\n                                Délai de paiement dépassé, votre commande à été annulée.\n                            "
                                  ),
                                ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "w-100 text-right" }, [
                              _vm.canPay
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn main-button",
                                      style: _vm.buttonRestaurantBgColor,
                                      attrs: { disabled: _vm.loadingPayment },
                                      on: { click: _vm.processPayment },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    Payer\n                                "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        : _c("div", [
                            _vm._v(
                              "Votre paiement a bien été pris en compte !"
                            ),
                          ]),
                    ],
                    2
                  ),
                ]),
              ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }