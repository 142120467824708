<template>
    <div style="width: 100%" v-if="totalAmount != '--'">
        <a
            @click.prevent="animateScroll"
            href="#cc-cart"
            :style="styleCartButton"
            style="color: white !important"
            class="btn-go-to-cart">
            Voir le panier <span>: {{ totalAmount }}€</span>
        </a>
    </div>
</template>

<script>
import $ from "jquery";

export default {
    data() {
        return {
            saveProducts: this.$_.cloneDeep(this.$store.getters["cart/products"]),
            products: this.$store.getters["cart/products"],
            comment: this.$store.getters["cart/comment"],
        };
    },
    computed: {
        restaurantBgColor() {
            return this.$store.getters["restaurant/bgcolor"];
        },
        styleCartButton() {
            return { background: this.restaurantBgColor, width: "100%" };
        },
        totalAmount() {
            if (this.products.length === 0) {
                return "--";
            }

            return this.formatPrice(this.$store.getters["cart/totalAmount"]);
        },
    },
    methods: {
        animateScroll(e) {
            var clickedAnchor = $(e.target);

            if (clickedAnchor && clickedAnchor.attr("href")) {
                var element = $(clickedAnchor.attr("href"));

                if (element) {
                    $("html,body").animate({ scrollTop: element.offset().top }, 1000);
                }
            }
        },
        currentPrice(product) {
            let quantity = Number.parseInt(product.quantity);
            if (isNaN(quantity) || quantity < 1) {
                return "--";
            }

            return this.formatPrice(
                quantity * (product.product.price + this.optionPrice(product) + this.extraPrice(product))
            );
        },
        optionPrice(product) {
            var price = 0;
            product.options.forEach((option) => {
                if (option.type != "extra") {
                    price += option.price * option.quantity;
                }
            });
            return price;
        },
        extraPrice(product) {
            var price = 0;
            product.options.forEach((option) => {
                if (option.type == "extra") {
                    price += option.price * option.quantity;
                }
            });
            return price;
        },
        formatPrice(price) {
            return Number.parseFloat(price).toFixed(2).toString().replace(".", ",");
        },
        paid() {
            this.showModal = false;
            this.$emit("paid");
        },
        productQuantityChanged(index) {
            let product = this.products[index];
            let quantity = Number.parseInt(product.quantity);
            this.errors[index] = "";
            if (isNaN(quantity) || quantity < 1) {
                if (!confirm(this.$t("confirm.products.delete"))) {
                    this.products = this.$_.cloneDeep(this.saveProducts);
                    this.$store.dispatch("cart/setProducts", this.products);
                    this.saveProducts = this.$_.cloneDeep(this.$store.getters["cart/products"]);
                    return;
                }
                this.products.splice(index, 1);
                this.$store.dispatch("cart/setProducts", this.products);
                this.saveProducts = this.$_.cloneDeep(this.$store.getters["cart/products"]);
            }
            let total_quantity = 0;
            this.products.forEach((cproduct) => {
                if (cproduct.product.id === product.product.id) total_quantity += cproduct.quantity;
            });
            if (product.product.max_quantity !== null && total_quantity > product.product.max_quantity) {
                this.errors[index] = this.$t("errors.products.quantity.max", { max: product.product.max_quantity });
                return;
            }
            this.$store.dispatch("cart/setProducts", this.products);
            this.saveProducts = this.$_.cloneDeep(this.$store.getters["cart/products"]);
        },
    },
    watch: {
        comment(newVal) {
            this.$store.dispatch("cart/setComment", newVal);
        },
        products: {
            deep: true,
            handler() {
                this.saveProducts = this.$_.cloneDeep(this.$store.getters["cart/products"]);
            },
        },
    },
    created() {
        this.$store.watch(
            () => this.$store.getters["cart/products"],
            () => {
                this.products = this.$store.getters["cart/products"];
            }
        );
    },
};
</script>
