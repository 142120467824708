<template>
    <div>
        <div class="row">
            <div class="col-12">
                <template v-if="!paid">
                    <span class="d-block text-danger" v-if="error">{{ error }}</span>
                    <span class="d-block text-danger" v-else-if="alreadyPaid">{{
                        $t("errors.payment_methods.already_used")
                    }}</span>
                    <template v-else>
                        <p v-if="canPay">
                            <span
                                class="mr-2 cursor-pointer"
                                @click="$parent.resetChoosenPaymentMethod()"
                                v-if="availablePaymentMethods.length > 1">
                                <svg
                                    style="margin-top: -5px !important"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="css-i6dzq1">
                                    <polyline points="15 18 9 12 15 6"></polyline>
                                </svg> </span
                            >Renseignez vos informations de carte bancaire et réglez
                            {{ $utils.formatPrice(method.payable_amount / 100) }}€
                        </p>
                        <p v-else class="text-danger">{{ $t("errors.common.cant_be_pay") }}</p>
                        <LoaderComponent class="w-100 text-center" v-show="loadingPayment" />
                        <span class="d-block text-danger" v-if="errorCard">{{ errorCard }}</span>
                        <div class="w-100 text-right">
                            <button
                                v-if="canPay"
                                :disabled="loadingPayment"
                                @click="pay"
                                class="mt-2 btn main-button"
                                :style="{ 'background-color': `${bgColor} !important` }">
                                Payer
                            </button>
                        </div>
                    </template>
                    <div class="secure-payment text-muted mt-3">
                        <a href="https://payplug.com/fr" target="_blank"
                            ><small><feather class="feather-18" type="lock"></feather> Paiement sécurisé</small></a
                        >
                    </div>
                </template>
                <PaymentConfirmRedirect v-else :command="command" :amount_paid="method.payable_amount" />
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../LoaderComponent.vue";
import PaymentConfirmRedirect from "./PaymentConfirmRedirect.vue";
import OnlinePaymentMethodEnum from "../../../mixins/enums/click_and_collect/OnlinePaymentMethodEnum.js";
import PaymentStatusEnum from "../../../mixins/enums/click_and_collect/PaymentStatusEnum.js";

export default {
    data() {
        return {
            loadingPayment: false,
            error: null,
            errorCard: null,
            paymentIntent: null,
            paid: false,
        };
    },
    mixins: [OnlinePaymentMethodEnum, PaymentStatusEnum],
    props: {
        command: {
            type: Object,
            required: true,
        },
        method: {
            type: Object,
            required: true,
        },
    },
    computed: {
        availablePaymentMethods() {
            return this.$store.getters["restaurant/available_payment_methods"];
        },
        bgColor() {
            return this.$store.getters["restaurant/bgcolor"];
        },
        methodCommandPayment() {
            return this.command.command_payments.data.find(
                (payment) => payment.online_payment_method === this.ONLINE_PAYMENT_METHOD_PAYPLUG.value
            );
        },
        alreadyPaid() {
            if (this.methodCommandPayment)
                return this.$utils.inEnum(this.methodCommandPayment.status, this.PAID_STATUS_WITH_REFUNDED);
            return this.command.remaining_amount_to_pay === 0;
        },
        canPay() {
            if (this.alreadyPaid) return false;
            return (
                (!this.methodCommandPayment || this.methodCommandPayment.status === this.PAYMENT_PENDING.value) &&
                this.method.payable_amount > 0
            );
        },
    },
    methods: {
        pay() {
            this.fetchPaymentIntent().then((ret) => {
                if (ret === true) this.processPayment();
            });
        },
        fetchPaymentIntent() {
            this.loadingPayment = true;
            this.error = null;

            return axios
                .post(`${API_URL}/${this.$api_key}/createPaymentIntent/${this.command.token}`, {
                    amount: this.method.payable_amount,
                })
                .then((response) => {
                    this.loadingPayment = false;
                    this.paymentIntent = response.data.setup_intent;
                    return true;
                })
                .catch((error) => {
                    this.loadingPayment = false;
                    if (error.response && error.response.data.message) this.error = error.response.data.message;
                    else if (error.message && error.message.toLowerCase() === "network error")
                        this.error = $t("errors.common.network");
                    else if (error.message) this.error = error.message;
                    else this.error = this.$t("errors.common.unknown");
                    if (error.response && error.response.status == 406)
                        setTimeout(() => {
                            document.location.replace(document.location.href);
                        }, 5000);
                    return false;
                });
        },
        processPayment() {
            this.error = null;
            if (!this.paymentIntent || !this.canPay) {
                this.error = this.$t("errors.common.already_paid");
                return;
            }
            if (
                !this.paymentIntent ||
                !this.paymentIntent.hosted_payment ||
                !this.paymentIntent.hosted_payment.payment_url
            ) {
                this.error = "Can't init PayPlug";
                return;
            }
            Payplug.showPayment(this.paymentIntent.hosted_payment.payment_url);
        },
    },
    components: {
        LoaderComponent,
        PaymentConfirmRedirect,
    },
    mounted() {
        if (!Payplug) {
            this.error = "Can't init PayPlug";
            return;
        }
        Payplug._listen("message", window, (e) => {
            if (typeof e.data === "string") {
                if (e.data === "closePayPlugFrame") {
                    if (this.paid) this.$emit("paid");
                    else this.loadingPayment = false;
                }
            } else if (typeof e.data === "object") {
                if (e.data.event === "paidByPayPlug" || e.data.event === "setRedirectionUrl") {
                    this.paid = true;
                }
            }
        });
    },
};
</script>
