var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container pt-2" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        {
          staticClass: "col-lg-6 col-md-8 col-12",
          staticStyle: { "max-width": "700px", margin: "0 auto" },
        },
        [
          _c("PaymentGroupCommandRecap", {
            staticClass: "border-light b-radius-20 p-4 mb-3",
            attrs: { command: _vm.command },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "border-light b-radius-20 p-4 mb-3" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("p", { staticClass: "mt-0" }, [
                    _vm._v(
                      "Sélectionnez votre email pour procéder au réglement"
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._l(
                    _vm.command.group_payments.data,
                    function (groupPayment) {
                      return [
                        !groupPayment.is_captain
                          ? _c(
                              "div",
                              {
                                key: groupPayment.id,
                                staticClass:
                                  "payment-mail-adress p-3 mb-2 border-light d-flex justify-content-between",
                                class: {
                                  paid:
                                    groupPayment.status != "pending" ||
                                    !_vm.canPay,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectEmail(groupPayment)
                                  },
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(groupPayment.email) +
                                      "\n                                    " +
                                      _vm._s(
                                        groupPayment.status != "pending"
                                          ? " - payé"
                                          : ""
                                      )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "strong",
                                  {
                                    style:
                                      groupPayment.status === "pending" &&
                                      _vm.canPay
                                        ? _vm.styleColorWidget
                                        : "",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$utils.formatPrice(
                                          groupPayment.amount
                                        )
                                      ) + " €"
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : groupPayment.is_captain &&
                            groupPayment.status === "pending" &&
                            _vm.atLeastOneMissingPayment
                          ? [
                              _c(
                                "div",
                                {
                                  key: groupPayment.id,
                                  staticClass:
                                    "payment-mail-adress p-3 mb-2 border-light paid",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-between",
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(groupPayment.email) +
                                            "\n                                            " +
                                            _vm._s(
                                              groupPayment.status != "pending"
                                                ? " - payé"
                                                : ""
                                            )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$utils.formatPrice(
                                              groupPayment.amount
                                            )
                                          ) + " €"
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.canPay
                                    ? _c("small", [
                                        _vm._v(
                                          "Vous devez attendre tous les paiements avant de procéder au vôtre"
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          : [
                              _c(
                                "div",
                                {
                                  key: groupPayment.id,
                                  staticClass:
                                    "payment-mail-adress p-3 mb-2 border-light",
                                  class: {
                                    paid:
                                      groupPayment.status != "pending" ||
                                      !_vm.canPay,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-between",
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectEmail(groupPayment)
                                        },
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(groupPayment.email) +
                                            "\n                                            " +
                                            _vm._s(
                                              groupPayment.status != "pending"
                                                ? " - payé"
                                                : ""
                                            )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "strong",
                                        {
                                          style:
                                            groupPayment.status === "pending" &&
                                            _vm.canPay
                                              ? _vm.styleColorWidget
                                              : "",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$utils.formatPrice(
                                                groupPayment.amount
                                              )
                                            ) + " €"
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                      ]
                    }
                  ),
                  _vm._v(" "),
                  _vm.canPay
                    ? _c("p", { staticClass: "mb-0" }, [
                        _vm._v(
                          "\n                            Vous avez jusqu'à " +
                            _vm._s(_vm.canPayUntil.format("HH[h]mm")) +
                            " pour procéder au réglement.\n                        "
                        ),
                      ])
                    : _c("p", { staticClass: "mb-0 text-danger" }, [
                        _vm._v(
                          "\n                            Délai de paiement dépassé, votre commande à été annulée.\n                        "
                        ),
                      ]),
                ],
                2
              ),
            ]),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }