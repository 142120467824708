var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c(
      "div",
      {
        staticClass: "border-bottom w-100",
        staticStyle: { position: "relative" },
        attrs: { slot: "header" },
        slot: "header",
      },
      [
        _c("div", { staticClass: "w-100 p-3" }, [
          _c("div", { staticClass: "d-flex justify-content-between" }, [
            _c(
              "h5",
              {
                staticClass: "mt-2 noshow-widget-font-title",
                style: _vm.basicRestaurantBgColor,
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$t("errors.common.required_action")) +
                    "\n                "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: {
                  type: "button",
                  "data-dismiss": "modal",
                  "aria-label": "Close",
                },
                on: { click: _vm.close },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "text-black",
                    attrs: { "aria-hidden": "true" },
                  },
                  [_vm._v("×")]
                ),
              ]
            ),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "p-3", attrs: { slot: "body" }, slot: "body" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("ShowErrors", {
              staticClass: "d-block",
              attrs: { errors: _vm.errors, errorKey: "*" },
            }),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
      _c("div", { staticClass: "w-100 p-3 border-top text-right" }, [
        _c(
          "button",
          {
            staticClass: "main-button",
            style: _vm.buttonRestaurantBgColor,
            on: { click: _vm.close },
          },
          [_vm._v("Fermer")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }