var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c("strong", { style: _vm.styleColorWidget }, [
          _vm._v("Commande N° " + _vm._s(_vm.command.numero)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 text-right" }, [
        _c("strong", {
          style: _vm.styleColorWidget,
          domProps: { innerHTML: _vm._s(_vm.commandDateAndTime) },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("p", [
          _c("strong", [_vm._v("Client :")]),
          _vm._v(
            " " +
              _vm._s(_vm.command.client.firstname) +
              " " +
              _vm._s(_vm.command.client.lastname)
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_vm._v("Établissement :")]),
          _vm._v(" " + _vm._s(_vm.restaurantName)),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_vm._v("Adresse :")]),
          _vm._v(" " + _vm._s(_vm.restaurantAddress) + "\n            "),
        ]),
        _vm._v(" "),
        _c("div", [
          _c(
            "strong",
            { staticClass: "d-block mb-1", style: _vm.styleColorWidget },
            [
              _vm._v(
                "Montant : " +
                  _vm._s(_vm.$utils.formatPrice(_vm.totalAmount / 100)) +
                  "€"
              ),
            ]
          ),
          _vm._v(" "),
          _vm.amountPaid
            ? _c(
                "strong",
                { staticClass: "d-block mb-1", style: _vm.styleColorWidget },
                [
                  _vm._v(
                    "Montant déjà réglé : " +
                      _vm._s(_vm.$utils.formatPrice(_vm.amountPaid / 100)) +
                      "€"
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "strong",
            { staticClass: "d-block", style: _vm.styleColorWidget },
            [
              _vm._v(
                "Reste à régler : " +
                  _vm._s(_vm.$utils.formatPrice(_vm.amountToPay / 100)) +
                  "€"
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _vm.isBoCommand
          ? _c("div", { staticClass: "mt-1" }, [
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(
                  "\n                    En effectuant mon paiement, je déclare accepter les\n                    "
                ),
                _c(
                  "a",
                  {
                    staticClass: "legal-link",
                    attrs: { href: _vm.cgv, target: "_blank" },
                  },
                  [_vm._v("conditions générales de vente")]
                ),
                _vm._v("\n                    et la\n                    "),
                _c(
                  "a",
                  {
                    staticClass: "legal-link",
                    attrs: {
                      href: `${_vm.base_url}/click_and_collect/widget/public/${this.$api_key}/policy?v=${_vm.uuid}`,
                      target: "_blank",
                    },
                  },
                  [
                    _vm._v(
                      "\n                        politique de confidentialité des données personnelles\n                    "
                    ),
                  ]
                ),
                _vm._v(
                  "\n                    et y adhère sans réserve.\n                "
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.canPayUntil && _vm.canPay && !_vm.isBoCommand
          ? _c("p", { staticClass: "mb-0" }, [
              _vm._v(
                "\n                Vous avez jusqu'à " +
                  _vm._s(_vm.canPayUntil.format("HH[h]mm")) +
                  " pour procéder au réglement.\n            "
              ),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }