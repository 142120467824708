import Vue from "vue";
import Package from "../package.json";
import App from "./App.vue";
import store from "./store/store.js";
import VueI18n from "vue-i18n";
import VTooltip from "v-tooltip";
import i18n from "./plugins/i18n.js";
import utils from "./plugins/utils.js";
import lodash from "lodash";
import axios from "axios";
import VueFeather from "vue-feather";

import BaseModal from "./components/Modals/BaseModal.vue";

import "./scss/bootstrap.min.scss";
import "./scss/style.scss";
import ErrorsInterceptor from "./axios/Interceptors/ErrorsInterceptor";
import { initSentry } from "./plugins/sentry.js";

/**
 * Add X-App-Version header
 */
const appVersion = Package.version;
if (appVersion) {
    axios.defaults.headers.common["X-App-Version"] = appVersion;
} else {
    console.error(`${THEME} app Version could not be found`);
}

axios.interceptors.request.use((config) => {
    config.params = config.params || {};
    config.params["lang"] = window.navigator.language.slice(0, 2) !== "fr" ? "en" : "fr";
    return config;
});

const initFunction = function (id, token, params = {}) {
    if (typeof id === "undefined" || typeof token === "undefined") {
        if (THEME === "noshow") {
            throw "NoShow ClickAndCollect Error : missing required args 'id' or 'token' in 'initNoShowClickAndCollect' function";
        } else {
            throw "Y-Services ClickAndCollect Error : missing required args 'id' or 'token' in 'initYservicesClickAndCollect' function";
        }
    }

    Vue.prototype.$__BUILD_ID__ = BUILD_ID;
    Vue.use(VueI18n);
    Vue.use(VTooltip);
    Vue.use(lodash);
    Vue.prototype.$_ = lodash;
    Vue.use(utils);
    Vue.use(VueFeather);

    Vue.component("modal", BaseModal);

    token = encodeURIComponent(token);
    Vue.prototype.$api_key = token;

    var storeObject = store(token);
    storeObject.dispatch("widget/setInitParams", params);
    ErrorsInterceptor(storeObject);
    var app = new Vue({
        el: `#${id}`,
        i18n,
        store: storeObject,
        render: (h) => h(App),
    });

    if (ENABLE_SENTRY) {
        initSentry(BUILD_ID);
    }
};

if (THEME === "noshow") {
    window.initNoShowClickAndCollect = initFunction;
} else {
    window.initYServicesClickAndCollect = initFunction;
}
