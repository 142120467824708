<template>
    <div :class="{ 'mt-3': hideCategoryName }">
        <div v-if="!hideCategoryName" class="row section-menu" :id="`noshow-cc-category-${category.id}`">
            <div class="col-12">
                <h6 :style="styleTitle" class="categories-title noshow-widget-font-title">{{ category.name }}</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <ProductCardComponent
                    :addressValidated="addressValidated"
                    :categories="categories"
                    :category="category.name"
                    v-for="product in categoryProducts"
                    :key="product.id"
                    :product="product" />
            </div>
        </div>
    </div>
</template>

<script>
import ProductCardComponent from "../Products/ProductCardComponent.vue";

export default {
    props: {
        category: {
            type: Object,
            required: true,
        },
        products: {
            type: Array,
            required: true,
        },
        categories: {
            type: Array,
            required: true,
        },
        addressValidated: {
            type: Boolean,
            required: true,
        },
        hideCategoryName: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        restaurantBgColor() {
            return this.$store.getters["restaurant/bgcolor"];
        },
        styleTitle() {
            return { color: this.restaurantBgColor + "!important" };
        },
        categoryProducts() {
            return this.products
                .filter((product) => {
                    return this.category.id === product.category_id;
                })
                .sort((a, b) => {
                    return a.order - b.order;
                });
        },
    },
    components: {
        ProductCardComponent,
    },
};
</script>
