var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._v(
        "\n    Votre paiement de " +
          _vm._s(_vm.$utils.formatPrice(_vm.amount_paid / 100)) +
          "€ a bien été pris en compte !\n    "
      ),
      _vm.amount_paid === _vm.command.remaining_amount_to_pay
        ? [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ml-2" },
              _vm._l(
                _vm.command.command_products.data,
                function (product, index) {
                  return _c(
                    "p",
                    { key: index, staticClass: "d-block" },
                    [
                      _c("span", { staticClass: "d-block" }, [
                        _vm._v(
                          _vm._s(product.quantity) +
                            " x " +
                            _vm._s(product.product.name)
                        ),
                      ]),
                      _vm._v(" "),
                      product.comment
                        ? _c("span", { staticClass: "d-block" }, [
                            _vm._v(_vm._s(product.comment)),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      product.command_product_option.data.length > 0
                        ? _c("span", { staticClass: "ml-2" })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(
                        product.command_product_option.data,
                        function (option, index) {
                          return _c(
                            "span",
                            {
                              key: index,
                              staticStyle: { "font-size": "0.8rem" },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    option.product_option.cc_product.name
                                  ) +
                                  "\n                    "
                              ),
                              !option.product_option.include > 0
                                ? _c("span", [
                                    _vm._v(
                                      "\n                        (" +
                                        _vm._s(
                                          _vm.$utils.formatPrice(
                                            option.product_option.price
                                          )
                                        ) +
                                        "€)"
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              option.quantity > 1
                                ? _c("span", [
                                    _vm._v(
                                      " (x" + _vm._s(option.quantity) + ")"
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              index <
                              product.command_product_option.data.length - 1
                                ? _c("span", [_vm._v(" • ")])
                                : _vm._e(),
                            ]
                          )
                        }
                      ),
                    ],
                    2
                  )
                }
              ),
              0
            ),
            _vm._v(" "),
            _vm.command.redirect_url
              ? _c("div", { staticClass: "w-100 text-center" }, [
                  _c(
                    "a",
                    {
                      staticClass: "mt-2 btn main-button",
                      style: {
                        "background-color": `${_vm.bgColor} !important`,
                      },
                      attrs: { href: _vm.command.redirect_url },
                    },
                    [_vm._v("Retour au site")]
                  ),
                ])
              : _vm._e(),
          ]
        : _c("div", { staticClass: "w-100 text-center" }, [
            _c(
              "button",
              {
                staticClass: "mt-2 btn main-button",
                style: { "background-color": `${_vm.bgColor} !important` },
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("go-back")
                  },
                },
              },
              [_vm._v("\n            Régler le montant restant\n        ")]
            ),
          ]),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("strong", [_vm._v("Récapitulatif de la commande :")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }