<template>
    <div>
        <!-- <label>Pays
            <select class="custom-select mt-2" v-model="country">
                <option v-for="country in ALL_SERVICE_POINT_COUNTRIES"
                    :key="country.value" :value="country.value">
                    {{ country.label }}
                </option>
            </select>
        </label> -->
        <label
            class="d-block mt-2 mb-2"
            style="padding: 14px !important; border: 2px solid #f7f7f7 !important"
            v-if="dispatch.isServicePointValidated"
            v-html="servicePointDisplayable"></label>
        <small class="text-danger d-block" v-if="errorServicePoint">{{ errorServicePoint }}</small>
        <div class="d-flex justify-content-between">
            <button
                class="main-button btn-light text-white d-flex mt-2"
                style="align-self: flex-end"
                :style="styleCartButton"
                @click="openServicePoint">
                Rechercher un point relais
            </button>
            <button
                class="main-button btn-light text-white d-flex mt-2"
                style="align-self: flex-end"
                :style="styleCartButton"
                :disabled="!dispatch.isServicePointValidated || !dispatch.isAddressValidated"
                @click="$emit('next-step')">
                Valider
            </button>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import ServicePointCountriesEnum from "../../mixins/enums/click_and_collect/ServicePointCountriesEnum.js";

export default {
    data() {
        return {
            errorServicePoint: null,
            country: "fr",
        };
    },
    mixins: [ServicePointCountriesEnum],
    props: {
        dispatch: {
            required: true,
        },
    },
    computed: {
        restaurantBgColorOnly() {
            return this.$store.getters["restaurant/bgcolor"];
        },
        clientAddress() {
            return this.$store.getters["client/address"];
        },
        styleCartButton() {
            return { "background-color": `${this.restaurantBgColorOnly} !important` };
        },
        sendcloud_public_key() {
            return this.$store.getters["restaurant/sendcloud_public_key"];
        },
        servicePointDisplayable() {
            if (!this.dispatch.isServicePointValidated) return "";
            const carrier = this.dispatch.servicePointSelected.carrier.split("_");
            let formattedCarrier = [];
            carrier.forEach((c) => formattedCarrier.push(this.$utils.capitalize(c)));
            return [
                `Transporteur : ${formattedCarrier.join(" ")}`,
                `Nom du point relais : ${this.dispatch.servicePointSelected.name}`,
                `Adresse du point relais : ${this.dispatch.servicePointSelected.street}, ${this.dispatch.servicePointSelected.postal_code} ${this.dispatch.servicePointSelected.city}`,
            ].join("<br/>");
        },
    },
    methods: {
        openServicePoint() {
            this.dispatch.servicePointSelected = null;
            this.dispatch.isServicePointValidated = false;
            sendcloud.servicePoints.open(
                {
                    apiKey: this.sendcloud_public_key,
                    country: this.country,
                    postalCode: this.clientAddress ? this.clientAddress.postalCode : undefined,
                    language: window.navigator.language.slice(0, 2) !== "fr" ? "en-gb" : "fr-fr",
                },
                this.onServicePointSelected,
                this.onServicePointSelectionFailure
            );
        },
        onServicePointSelected(servicePointSelected) {
            this.errorServicePoint = null;
            this.dispatch.servicePointSelected = servicePointSelected;
            this.dispatch.isServicePointValidated = true;
        },
        onServicePointSelectionFailure(errors) {
            this.errorServicePoint = this.$t("errors.service_points.not_selected");
        },
    },
    created() {},
};
</script>
