export default {
    client: (state) => {
        return state;
    },
    id: (state) => {
        return state.id;
    },
    firstName: (state) => {
        return state.firstName;
    },
    lastName: (state) => {
        return state.lastName;
    },
    email: (state) => {
        return state.email;
    },
    tel: (state) => {
        return state.tel;
    },
    telCountry: (state) => {
        return state.telCountry;
    },
    company: (state) => {
        return state.company;
    },
    civility: (state) => {
        return state.civility;
    },
    address: (state) => {
        return state.address;
    },
    addressForClient: (state) => {
        return state.address ? `${state.address.address}, ${state.address.postalCode} ${state.address.city}` : "";
    },
};
