import axios from "axios";

export default {
    setProducts({ commit }, products) {
        commit("setProducts", products);
    },
    addProduct({ commit }, product) {
        commit("addProduct", product);
    },
    setSlot({ commit }, slot) {
        commit("setSlot", slot);
    },
    setDate({ commit }, date) {
        commit("setDate", date);
    },
    setToken({ commit }, token) {
        commit("setToken", token);
    },
    setComment({ commit }, comment) {
        commit("setComment", comment);
    },
    setCollectType({ commit }, collectType) {
        commit("setCollectType", collectType);
    },
    setDeliveryInfo({ commit }, deliveryInfo) {
        commit("setDeliveryInfo", deliveryInfo);
    },
    setPromoCode({ commit }, promoCode) {
        commit("setPromoCode", promoCode);
    },
    clear({ commit }) {
        commit("setProducts", []);
        commit("setSlot", null);
        commit("setDate", null);
        commit("setToken", null);
        commit("setDeliveryInfo", null);
        commit("setPromoCode", null);
        commit("setGiftVoucher", null);
    },
    clearProducts({ commit }) {
        commit("setProducts", []);
    },
    clearUnavailableProducts({ commit, getters }, availableProducts) {
        if (availableProducts.length == 0) return;
        let oldProducts = this._vm.$_.cloneDeep(getters.products);
        commit("setProducts", []);
        oldProducts.forEach((oldProduct) => {
            const foundProduct = availableProducts.find((p) => p.id === oldProduct.product.id);
            if (
                foundProduct &&
                foundProduct.active &&
                getters.validateProductStockForProduct(foundProduct, oldProduct.quantity) &&
                getters.validateCategoryStockForProduct(foundProduct, oldProduct.quantity)
            ) {
                oldProduct.product = foundProduct;
                let invalidOption = false;
                oldProduct.options.forEach((option) => {
                    const foundComponent = foundProduct.cc_product_components.data.find(
                        (pc) => pc.id === option.option.cc_product_component_id
                    );
                    if (foundComponent) {
                        const foundOption = foundComponent.cc_product_options.data.find(
                            (po) => po.id === option.option.id
                        );
                        if (
                            foundOption &&
                            getters.validateProductStockForProduct(
                                foundOption.cc_product,
                                oldProduct.quantity * option.quantity
                            ) &&
                            getters.validateCategoryStockForProduct(
                                foundOption.cc_product,
                                oldProduct.quantity * option.quantity
                            ) &&
                            !foundProduct.cc_product_components.data.some(
                                (c) =>
                                    c.is_mandatory &&
                                    c.cc_product_options.data.every(
                                        (o) =>
                                            !getters.validateProductStockForProduct(
                                                o.cc_product,
                                                oldProduct.quantity * option.quantity
                                            ) ||
                                            !getters.validateCategoryStockForProduct(
                                                o.cc_product,
                                                oldProduct.quantity * option.quantity
                                            )
                                    )
                            )
                        ) {
                            option.option = foundOption;
                            return;
                        }
                    }
                    invalidOption = true;
                });
                if (!invalidOption) commit("addProduct", oldProduct);
            }
        });
    },
    validatePromoCode({ commit }, params) {
        return axios.post(`${API_URL}/${this._vm.$api_key}/promo_codes/validate`, params).then((response) => {
            commit("setPromoCode", response.data);
        });
    },
    resetPromoCode({ commit }) {
        commit("setPromoCode", null);
    },
    validateGiftVoucher({ commit }, params) {
        return axios.post(`${API_URL}/${this._vm.$api_key}/gift_vouchers/validate`, params).then((response) => {
            commit("setGiftVoucher", response.data);
            return response;
        });
    },
    resetGiftVoucher({ commit }) {
        commit("setGiftVoucher", null);
    },
};
