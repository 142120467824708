<template>
    <div v-if="currentCategory !== null" class="nav sidemenu">
        <div class="w-100 text-center">
            <div class="float-left">
                <div class="d-flex align-items-center">
                    <button class="sidemenu_btn" @click="navOpen = !navOpen" :class="{ active: navOpen }">
                        <span class="top"></span>
                        <span class="mid"></span>
                        <span class="bottom"></span>
                    </button>
                    <span @click="navOpen = !navOpen" class="pointer libelle-categorie">Menu</span>
                </div>
            </div>
            <div class="title-categorie text-center">
                {{ currentCategory.name }}
            </div>
        </div>
        <nav v-show="navOpen">
            <div class="sidemenu_wrapper">
                <ul class="nav sidemenu_list">
                    <nav-item-component v-for="item in items" :key="item.id" :item="item" class="sidemenu_item" @go-to="goTo" />
                </ul>
            </div>
        </nav>
    </div>
</template>

<script>
import NavItemComponent from "./NavItemComponent.vue";

export default {
    data() {
        return {
            navOpen: false,
        };
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        currentCategory: {
            type: Object,
            default: null,
        },
    },
    computed: {
        displayPerCategory() {
            return this.$store.getters["restaurant/displayPerCategory"];
        },
    },
    methods: {
        goTo(event) {
            this.navOpen = false;

            this.$emit("set-current-category", event);
        },
    },
    components: {
        NavItemComponent,
    },
};
</script>

<style lang="scss" scoped>
.nav.sidemenu {
    display: flex;
    flex-direction: column;
}

.sidemenu {
    &_btn {
        display: block;
        width: 50px;
        height: 50px;
        border: none;
        position: relative;
        z-index: 100;
        appearance: none;
        cursor: pointer;
        outline: none;
        background: none;

        span {
            display: block;
            width: 20px;
            height: 2px;
            margin: auto;
            background: grey;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            transition: all 0.4s ease;

            &.top {
                transform: translateY(-8px);
            }

            &.bottom {
                transform: translateY(8px);
            }
        }
        &.active {
            .top {
                transform: rotate(-45deg);
            }
            .mid {
                transform: rotate(360deg);
                opacity: 0;
            }
            .bottom {
                transform: rotate(45deg);
            }
        }
    }
    .libelle-categorie {
        font-size: 14px !important;
        letter-spacing: 0.05em !important;
        font-weight: 600;
        color: grey !important;
        text-transform: uppercase !important;
    }

    .title-categorie {
        font-size: 16px !important;
        color: #303133 !important;
        padding: 0.7rem 50px !important;
        letter-spacing: 0.05em !important;
        font-weight: 600;
        text-transform: uppercase !important;
    }

    &_wrapper {
        position: absolute;
        z-index: 100;
        min-width: 200px;

        .libelle {
            position: absolute;
        }

        .nav.sidemenu_list {
            display: flex;
            flex-direction: column;
            padding-bottom: 15px !important;
        }
    }
}

@media screen and (max-width: 425px) {
    .sidemenu {
        .libelle-categorie {
            display: none;
        }

        &_wrapper {
            width: 100%;
        }
    }
}
</style>
