<template>
    <div>
        <loader-component class="w-100 text-center" v-if="loading > 0" />
        <div class="container" v-else-if="error">
            <div class="alert alert-danger">{{ error }}</div>
        </div>
        <div v-else class="container">
            <div class="col-lg-6 col-md-8 col-12" style="max-width: 700px; margin: 0 auto">
                <Recap class="border-light b-radius-20 p-4 mb-3" :command="command" :canPayUntil="canPayUntil" :canPay="canPay" />
                <SelectPaymentMethod
                    @fetch-command="fetchCommand"
                    class="border-light b-radius-20 p-4 mb-3"
                    :command="command"
                    :paymentDelayExpired="paymentDelayExpired" />
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../components/LoaderComponent.vue";
import Recap from "../components/Command/Recap.vue";
import SelectPaymentMethod from "../components/Command/SelectPaymentMethod.vue";
import axios from "axios";
import moment from "moment";
import CommandTypeEnum from "../mixins/enums/click_and_collect/CommandTypeEnum.js";
import CommandStatusEnum from "../mixins/enums/click_and_collect/CommandStatusEnum.js";

export default {
    name: "PaymentLayout",
    data() {
        return {
            loading: 0,
            error: null,
            command: null,
        };
    },
    mixins: [CommandTypeEnum, CommandStatusEnum],
    computed: {
        canPayUntil() {
            return moment(this.command.created_at).add(20, "minutes");
        },
        canPay() {
            return (
                this.command &&
                this.command.status != this.COMMAND_CANCELED.value &&
                this.command.remaining_amount_to_pay > 0 &&
                !this.paymentDelayExpired
            );
        },
        commandToken() {
            return this.$utils.getQueryParameter("command");
        },
        paymentDelayExpired() {
            if (!this.command) {
                return true;
            }

            if (this.command.type === this.COMMAND_TYPE_BO.value) {
                return false;
            }

            return this.canPayUntil.isBefore(moment());
        },
    },
    methods: {
        fetchRestaurant() {
            this.loading++;
            this.errorRestaurant = null;

            axios
                .get(`${API_URL}/${this.$api_key}/restaurant`)
                .then((response) => {
                    this.$store.dispatch("restaurant/setName", response.data.name);
                    this.$store.dispatch("restaurant/setAddress", response.data.formatted_address);
                    this.$store.dispatch("restaurant/setBgColor", response.data.bgcolor);
                    this.$store.dispatch("restaurant/setStripeClientId", response.data.stripe_client_id);
                    this.$store.dispatch("restaurant/setCgv", response.data.cgv);
                })
                .catch((error) => {
                    this.$store.dispatch("restaurant/resetData");
                    this.errorRestaurant = this.$utils.getErrorMsgFromErrorResponse(error);
                })
                .finally(() => this.loading--);
        },
        fetchCommand() {
            this.loading++;
            this.error = null;

            axios
                .get(
                    `${API_URL}/commands/${this.commandToken}?include=redirect_url,client,command_payments,slot,available_payment_methods,remaining_amount_to_pay,command_products.product,command_products.command_product_option.product_option.cc_product`
                )
                .then((response) => {
                    this.command = response.data;
                    this.$store.dispatch("restaurant/setAvailablePaymentMethods", response.data.available_payment_methods.data);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 404) {
                        this.error = this.$t("errors.group_payment.command_not_found");
                    } else {
                        this.error = this.$utils.getErrorMsgFromErrorResponse(error);
                    }
                })
                .finally(() => this.loading--);
        },
    },
    components: {
        LoaderComponent,
        Recap,
        SelectPaymentMethod,
    },
    created() {
        this.fetchRestaurant();
        this.fetchCommand();
    },
};
</script>
