var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "text-center mt-4 mb-4" }, [
    _c("div", { staticClass: "lds-ring mb-2" }, [
      _c("div", { style: _vm.loadingBorderColor }),
      _vm._v(" "),
      _c("div", { style: _vm.loadingBorderColor }),
      _vm._v(" "),
      _c("div", { style: _vm.loadingBorderColor }),
      _vm._v(" "),
      _c("div", { style: _vm.loadingBorderColor }),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "text-center", staticStyle: { "font-size": "14px" } },
      [_vm._v(_vm._s(_vm.$t("loading")))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }