<template>
    <div>
        <loader-component class="w-100 text-center" v-if="loading > 0" />
        <div class="container" v-else-if="errorRestaurant || errorCommand">
            <div class="alert alert-danger" v-if="errorRestaurant">{{ errorRestaurant }}</div>
            <div class="alert alert-danger" v-if="errorCommand">{{ errorCommand }}</div>
        </div>
        <div v-else>
            <payment-group-list-emails :command="command" v-if="customerEmail === null" @select="refreshComputed++" />
            <payment-group-payment @go-back="removeCustomerEmail" :command="command" v-else />
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../components/LoaderComponent.vue";
import PaymentGroupListEmails from "../components/GroupPayment/PaymentGroupListEmails.vue";
import PaymentGroupPayment from "../components/GroupPayment/PaymentGroupPayment.vue";
import axios from "axios";

export default {
    name: "GroupPaymentLayout",
    data() {
        return {
            loading: 0,
            errorCommand: null,
            errorRestaurant: null,
            command: null,
            refreshComputed: 0,
        };
    },
    computed: {
        commandToken() {
            return this.$utils.getQueryParameter("command");
        },
        customerEmail() {
            this.refreshComputed;
            return this.$utils.getQueryParameter("email");
        },
    },
    methods: {
        fetchCommand() {
            this.loading++;
            this.errorCommand = null;

            axios
                .get(`${API_URL}/commands/${this.commandToken}?include=group_payments,slot,client&with_trashed=1`)
                .then((response) => {
                    this.loading--;
                    this.command = response.data;
                })
                .catch((error) => {
                    this.loading--;
                    if (error.response.status === 404) this.errorCommand = this.$t("errors.group_payment.command_not_found");
                    else this.errorCommand = error.response.data.message ? error.response.data.message : this.$t("errors.common.unknown");
                });
        },
        fetchRestaurant() {
            this.loading++;
            this.errorRestaurant = null;

            axios
                .get(`${API_URL}/${this.$api_key}/restaurant`)
                .then((response) => {
                    this.loading--;
                    this.$store.dispatch("restaurant/setName", response.data.name);
                    this.$store.dispatch("restaurant/setAddress", response.data.formatted_address);
                    this.$store.dispatch("restaurant/setBgColor", response.data.bgcolor);
                    this.$store.dispatch("restaurant/setStripeClientId", response.data.stripe_client_id);
                })
                .catch((error) => {
                    this.loading--;
                    this.$store.dispatch("restaurant/resetData");
                    this.errorRestaurant = error.response.data.message ? error.response.data.message : this.$t("errors.common.unknown");
                });
        },
        removeCustomerEmail() {
            let url = this.$utils.removeQueryParameter("email");
            window.history.pushState({ path: url }, "", url);
            this.refreshComputed++;
            this.fetchCommand();
        },
    },
    created() {
        this.fetchRestaurant();
        this.fetchCommand();
    },
    components: {
        LoaderComponent,
        PaymentGroupListEmails,
        PaymentGroupPayment,
    },
};
</script>
