export default {
    setProducts(state, products) {
        state.products = products;
    },
    addProduct(state, product) {
        state.products.push(product);
    },
    setSlot(state, slot) {
        state.slot = slot;
    },
    setDate(state, date) {
        state.date = date;
    },
    setToken(state, token) {
        state.token = token;
    },
    setComment(state, comment) {
        state.comment = comment;
    },
    setCollectType(state, collectType) {
        state.collectType = collectType;
    },
    setDeliveryInfo(state, deliveryInfo) {
        state.deliveryInfo = deliveryInfo;
    },
    setPromoCode(state, promoCode) {
        state.promoCode = promoCode;
    },
    setGiftVoucher(state, giftVoucher) {
        state.giftVoucher = giftVoucher;
    },
};
