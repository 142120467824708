export default {
    setId(state, id) {
        state.id = id;
    },
    setFirstName(state, firstName) {
        state.firstName = firstName;
    },
    setLastName(state, lastName) {
        state.lastName = lastName;
    },
    setEmail(state, email) {
        state.email = email;
    },
    setTel(state, tel) {
        state.tel = tel;
    },
    setTelCountry(state, telCountry) {
        state.telCountry = telCountry;
    },
    setCompany(state, company) {
        state.company = company;
    },
    setCivility(state, civility) {
        state.civility = civility;
    },
    setAddress(state, address) {
        state.address = address;
    },
};
