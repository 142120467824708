export default {
    data() {
        return {
            WIDGET_COLLECT: {
                value: "collect",
            },
            WIDGET_DELIVERY: {
                value: "delivery",
            },
            WIDGET_DISPATCH: {
                value: "dispatch",
            },
        };
    },
    methods: {},
    computed: {
        ALL_WIDGET_TYPES() {
            return [this.WIDGET_COLLECT, this.WIDGET_DELIVERY, this.WIDGET_DISPATCH];
        },
    },
};
