<template>
    <li class="nav-item">
        <a
            @click.prevent="goTo($event, item)"
            class="nav-link"
            :style="showActive ? `color: ${restaurantBgColor} !important; text-decoration: underline;` : undefined"
            :href="`#noshow-cc-category-${item.id}`">
            {{ item.name }}
        </a>
    </li>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
        showActive: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        restaurantBgColor() {
            return this.$store.getters["restaurant/bgcolor"];
        },
    },
    methods: {
        goTo(event, category) {
            this.$emit("go-to", { event, category });
        },
    },
};
</script>
