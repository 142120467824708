<template>
    <div>
        <div class="row">
            <div class="col-6">
                <strong :style="styleColorWidget">Commande N° {{ command.numero }}</strong>
            </div>
            <div class="col-6 text-right">
                <strong :style="styleColorWidget" v-html="commandDateAndTime"></strong>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p>
                    <strong>Client :</strong> {{ command.client.firstname }} {{ command.client.lastname }}<br />
                    <strong>Établissement :</strong> {{ restaurantName }}<br />
                    <strong>Adresse :</strong> {{ restaurantAddress }}
                </p>
                <div class="d-flex justify-content-between">
                    <strong :style="styleColorWidget">Montant : {{ $utils.formatPrice(command.total_amount / 100) }}€</strong>
                    <strong :style="styleColorWidget">Reste à régler : {{ $utils.formatPrice(remainingTotalAmount) }}€</strong>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "../../plugins/moment.js";

export default {
    props: {
        command: {
            type: Object,
            required: true,
        },
    },
    computed: {
        restaurantBgColor() {
            return this.$store.getters["restaurant/bgcolor"];
        },
        styleColorWidget() {
            return { color: this.restaurantBgColor };
        },
        restaurantName() {
            return this.$store.getters["restaurant/name"];
        },
        restaurantAddress() {
            return this.$store.getters["restaurant/address"];
        },
        commandDateAndTime() {
            if (this.command.collect_type === "collect")
                return moment(this.command.reservation_date + " " + this.command.slot.hour).format("DD/MM/Y - HH[h]mm");
            if (this.command.collect_type === "delivery") {
                const date = moment(this.command.reservation_date).format("DD/MM/Y");
                const hour_start = this.command.slot.hour_start.replace(":", "h").substr(0, 5);
                const hour_end = this.command.slot.hour_end.replace(":", "h").substr(0, 5);
                return `${date} <br/> ${hour_start} - ${hour_end}`;
            }
            return "";
        },
        remainingTotalAmount() {
            let total_amount = this.command.total_amount / 100;
            this.command.group_payments.data.forEach((groupPayment) => {
                if (groupPayment.paid_at != null) total_amount -= groupPayment.amount;
            });
            return total_amount;
        },
    },
};
</script>
