import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import CartStore from "./modules/cart/cart.js";
import RestaurantStore from "./modules/restaurant/restaurant.js";
import ClientStore from "./modules/client/client.js";
import ErrorStore from "./modules/error/error.js";
import WidgetStore from "./modules/widget/widget.js";

function isLocalStorageSupported() {
    try {
        const testKey = "__this_key_should_never_be_used__";

        localStorage.setItem(testKey, testKey);
        localStorage.removeItem(testKey);

        return true;
    } catch (e) {
        return false;
    }
}

Vue.use(Vuex);

export default function (token) {
    let plugins = [];

    if (isLocalStorageSupported()) {
        plugins.push(
            createPersistedState({
                key: `${THEME}-clickandcollect-${token.substr(0, 6)}`,
                paths: [
                    "client",
                    "restaurant.name",
                    "restaurant.address",
                    "restaurant.bgcolor",
                    "restaurant.email",
                    "restaurant.tel",
                    "cart.token",
                    "cart.comment",
                ],
            })
        );
    }

    return new Vuex.Store({
        modules: {
            cart: CartStore,
            restaurant: RestaurantStore,
            client: ClientStore,
            error: ErrorStore,
            widget: WidgetStore,
        },
        plugins,
    });
}
