var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c("strong", { style: _vm.styleColorWidget }, [
          _vm._v("Commande N° " + _vm._s(_vm.command.numero)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 text-right" }, [
        _c("strong", {
          style: _vm.styleColorWidget,
          domProps: { innerHTML: _vm._s(_vm.commandDateAndTime) },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("p", [
          _c("strong", [_vm._v("Client :")]),
          _vm._v(
            " " +
              _vm._s(_vm.command.client.firstname) +
              " " +
              _vm._s(_vm.command.client.lastname)
          ),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_vm._v("Établissement :")]),
          _vm._v(" " + _vm._s(_vm.restaurantName)),
          _c("br"),
          _vm._v(" "),
          _c("strong", [_vm._v("Adresse :")]),
          _vm._v(" " + _vm._s(_vm.restaurantAddress) + "\n            "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex justify-content-between" }, [
          _c("strong", { style: _vm.styleColorWidget }, [
            _vm._v(
              "Montant : " +
                _vm._s(_vm.$utils.formatPrice(_vm.command.total_amount / 100)) +
                "€"
            ),
          ]),
          _vm._v(" "),
          _c("strong", { style: _vm.styleColorWidget }, [
            _vm._v(
              "Reste à régler : " +
                _vm._s(_vm.$utils.formatPrice(_vm.remainingTotalAmount)) +
                "€"
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }