<template>
    <modal @close="closeFromModal">
        <div slot="header" class="border-bottom w-100" style="position: relative">
            <div class="w-100 p-3">
                <div class="d-flex justify-content-between">
                    <h5 :style="basicRestaurantBgColor" class="mt-2 noshow-widget-font-title">
                        <span class="mr-2 pointer" @click="prevStep" v-if="step > 1">
                            <svg
                                style="margin-top: -5px !important"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                stroke="currentColor"
                                stroke-width="2"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="css-i6dzq1">
                                <polyline points="15 18 9 12 15 6"></polyline>
                            </svg> </span
                        >{{ title }}
                    </h5>
                    <button @click="close" :disabled="loading" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="text-black" aria-hidden="true">&times;</span>
                    </button>
                </div>
                <small class="d-block text-muted"
                    >Pour toute information relative au délai de rétractation, merci de vous référer à nos
                    <a :href="cgv" target="_blank">conditions générales de vente</a>.</small
                >
            </div>
        </div>
        <div slot="body" class="p-3">
            <loader-component v-if="loading" />
            <ChooseDispatchType v-if="!loading && step === 1" :dispatch="dispatch" :errors="errors" @next-step="step++" />
            <SelectShippingMethod v-if="!loading && step === 2" :dispatch="dispatch" :errors="errors" @next-step="step++" />
            <div class="row" v-show="!loading && step === 3">
                <div class="col-sm-3">
                    <h6 class="noshow-widget-font-title">Coordonnées</h6>
                </div>
                <div class="col-sm-9">
                    <div class="row">
                        <div class="col-sm-3">
                            <label>Civilité <small>*</small></label>
                        </div>
                        <div class="col-sm-9 radio">
                            <label class="container" style="width: initial">
                                <input type="radio" name="civility" value="monsieur" v-model="client.civility" />
                                <span class="checkmark"></span>
                                Monsieur
                            </label>
                            <label class="container" style="width: initial">
                                <input type="radio" name="civility" value="madame" v-model="client.civility" />
                                <span class="checkmark"></span>
                                Madame
                            </label>
                            <ShowErrors class="d-block" :errors="errors" errorKey="client.civility" />
                        </div>
                        <div class="col-sm-6">
                            <label
                                >Prénom <small>*</small>
                                <input type="text" class="form-control mt-2" v-model="client.firstname" required="required" />
                            </label>
                            <ShowErrors :errors="errors" errorKey="client.firstname" />
                        </div>
                        <div class="col-sm-6">
                            <label
                                >Nom <small>*</small>
                                <input type="text" class="form-control mt-2" v-model="client.lastname" required="required" />
                            </label>
                            <ShowErrors :errors="errors" errorKey="client.lastname" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <label
                                >Email <small>*</small>
                                <input type="email" class="form-control mt-2" v-model="client.email" required="required" />
                            </label>
                            <ShowErrors :errors="errors" errorKey="client.email" />
                        </div>
                        <div class="col-sm-6">
                            <label>Numéro de téléphone <small>*</small></label>
                            <vue-tel-input
                                name="telInput"
                                :defaultCountry="defaultTelCountry"
                                @validate="setPhoneAndCountry"
                                v-model="tel_raw"
                                :enabledCountryCode="true"
                                inputClasses="m-0 border-0"
                                mode="international"
                                placeholder="-- -- -- -- --"
                                required="required"></vue-tel-input>
                            <ShowErrors :errors="errors" errorKey="client.tel" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <label
                                >Société
                                <input type="text" class="form-control mt-2" v-model="client.company" />
                            </label>
                            <ShowErrors :errors="errors" errorKey="client.company" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <label
                                >Adresse
                                <input readonly type="text" class="form-control mt-2" :value="$store.getters['client/addressForClient']" />
                            </label>
                        </div>
                    </div>
                    <template v-if="dispatch.type === DISPATCH_TYPE_SERVICE_POINT.value">
                        <div class="row mt-3" v-for="key in Object.keys(servicePointDisplayable)" :key="key">
                            <div class="col-12">
                                <label
                                    >{{ key }}
                                    <input readonly type="text" class="form-control mt-2" :value="servicePointDisplayable[key]" />
                                </label>
                            </div>
                        </div>
                    </template>
                    <div class="row" v-if="dispatch.finalShippingMethod">
                        <div class="col-12">
                            <label
                                >Méthode d'expédition
                                <input readonly type="text" class="form-control mt-2" :value="dispatch.finalShippingMethod.name" />
                            </label>
                        </div>
                    </div>
                    <div class="row mt-3" v-if="isGroupPaymentAvailable">
                        <div class="col-12">
                            <label class="container">
                                <input type="checkbox" v-model="isGroupPayment" />
                                <span class="checkmark"></span>Paiement de groupe
                            </label>
                            <ShowErrors :errors="errors" errorKey="isGroupPayment" />
                        </div>
                    </div>
                    <div class="row" v-if="isGroupPayment">
                        <div class="col-12">
                            <p class="mt-1 text-warning">Le montant minimum par personne est de 5€.</p>
                            <label class="d-block">
                                Votre part :
                                <input
                                    v-model="ownerAmount"
                                    class="form-control mr-1 d-inline-block"
                                    style="width: 20%"
                                    type="number"
                                    min="5"
                                    step="0.01" />€
                            </label>
                            <ShowErrors class="d-block" :errors="errors" errorKey="ownerAmount" />
                            <ShowErrors class="d-block" :errors="errors" errorKey="groupPayments" />
                        </div>
                        <div class="col-12" v-for="(groupPayment, index) in groupPayments" :key="index">
                            <label>
                                Email de la {{ getPersonNumberLabel(index) }} personne et montant à payer *
                                <input v-model="groupPayment.email" class="form-control mt-2 d-inline-block" type="email" style="width: 60%" />
                                <input
                                    v-model="groupPayment.amount"
                                    class="form-control mt-2 ml-2 d-inline-block"
                                    style="width: 20%"
                                    type="number"
                                    min="5"
                                    step="0.01" />
                                €
                                <button
                                    v-tooltip="{ content: 'Supprimer cette personne' }"
                                    @click="groupPayments.splice(index, 1)"
                                    class="btn btn-sm text-danger d-inline-block text-center"
                                    style="width: 10%">
                                    <i class="fas fa-times"></i>
                                </button>
                            </label>
                            <ShowErrors class="d-block" :errors="errors" :errorKey="`groupPayments.${index}.email`" />
                            <ShowErrors class="d-block" :errors="errors" :errorKey="`groupPayments.${index}.amount`" />
                        </div>
                    </div>
                    <div class="row mb-4" v-if="isGroupPayment">
                        <div class="col-12">
                            <button
                                v-tooltip="{ content: 'Ajouter une personne' }"
                                @click="addGroupPayment"
                                class="main-button main-button-sm"
                                style="width: 40px !important; padding-left: 18px !important">
                                <i class="fas fa-plus"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="step === 4 && isGroupPayment">
                <div class="w-100">
                    <div class="noshow-widget-font-title">Votre commande a bien été enregistrée !</div>
                    <div class="mt-3">
                        <p>
                            Un email a été envoyé à chaque adresse email saisie afin de procéder au paiement.<br />
                            Une fois les paiements effectués, vous recevrez un email afin de procéder à votre paiement.<br />
                            <strong>Attention, vous disposez de 30 minutes pour procéder à tous les paiements.</strong><br />
                            Vous pouvez suivre les paiements depuis ce lien ou en cliquant sur le bouton ci dessous :<br />
                        </p>

                        <p></p>
                        <div class="d-flex align-items-center mt-3">
                            <CopyToClipboard :textToCopy="group_payment_url" :element="$t('labels.linkIdentifier')" />
                        </div>
                        <div class="w-100 text-center">
                            <a target="_blank" :href="group_payment_url">
                                <button class="btn main-button mt-4 btn-sm" type="button" :style="buttonRestaurantBgColor">
                                    Voir la liste des paiements
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="step === 4 && !isGroupPayment">
                <div class="w-100">
                    <div class="noshow-widget-font-title">Votre commande a bien été enregistrée !</div>
                    <div class="mt-3">
                        <p>
                            Vous allez être redirigé dans quelques secondes afin de procéder au paiement de la commande.<br />
                            Si rien ne se passe, merci de cliquer sur le bouton ci-dessous.
                        </p>
                        <div class="w-100 text-center">
                            <a :href="payment_url">
                                <button class="btn main-button mt-4 btn-sm" type="button" :style="buttonRestaurantBgColor">
                                    Procéder au paiement
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <template v-if="step === 3">
                <div class="p-3 border-top">
                    <div class="row">
                        <div class="col-6">
                            <h6 class="mb-1 noshow-widget-font-title">Sous-total</h6>
                        </div>
                        <div class="col-6">
                            <div class="row">
                                <div class="col-sm-12">
                                    <h6 :style="basicRestaurantBgColor" style="text-align: right !important" class="mb-0">
                                        <strong>{{ totalAmountStrWithPromoCode }}€</strong>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <h6 class="mb-1 noshow-widget-font-title">Frais de livraison</h6>
                        </div>
                        <div class="col-6">
                            <div class="row">
                                <div class="col-sm-12">
                                    <h6 :style="basicRestaurantBgColor" style="text-align: right !important" class="mb-0">
                                        <strong>{{ deliveryFees === null ? "Gratuit" : `${$utils.formatPrice(deliveryFees / 100)}€` }}</strong>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <h6 class="mb-0 noshow-widget-font-title">Total</h6>
                        </div>
                        <div class="col-6">
                            <div class="row">
                                <div class="col-sm-12">
                                    <h6 :style="basicRestaurantBgColor" style="text-align: right !important" class="mb-0">
                                        <strong>{{ totalAmountWithDeliveryFeesStr }}€</strong>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-modal p-3">
                    <div v-if="error" class="mb-3">
                        <span class="text-danger d-block" v-if="error && !loading">{{ error }}</span>
                        <ShowErrors class="d-block" :errors="errors" errorKey="*" />
                    </div>
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
                        <div>
                            <label class="container">
                                <input type="checkbox" v-model="general_terms" :disabled="loading" />
                                <span class="checkmark"></span>
                                <small class="text-muted">
                                    Je déclare accepter les <a :href="cgv" target="_blank">conditions générales de vente</a> et de
                                    <a :href="`${base_url}/click_and_collect/widget/public/${this.$api_key}/policy?v=${uuid}`" target="_blank">
                                        politique de confidentialité des données personnelles
                                    </a>
                                    et y adhère sans réserve.
                                </small>
                            </label>
                        </div>
                        <div class="payment-btn text-right">
                            <button
                                :disabled="loading || this.ownerAmount < 0"
                                @click="save"
                                :style="buttonRestaurantBgColor"
                                class="main-button mt-2 mt-md-0">
                                Payer
                            </button>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="step === 4">
                <div class="w-100 p-3 border-top text-right">
                    <button :disabled="loading" @click="$emit('saved')" class="main-button" :style="buttonRestaurantBgColor">Fermer</button>
                </div>
            </template>
        </div>
    </modal>
</template>

<script>
import moment from "../../plugins/moment.js";
import { VueTelInput } from "vue-tel-input";
import LoaderComponent from "../LoaderComponent.vue";
import axios from "axios";
import ShowErrors from "../errors/ShowErrors.vue";
import PromoCodeTypeEnum from "../../mixins/enums/click_and_collect/PromoCodeTypeEnum.js";
import DispatchTypesEnum from "../../mixins/enums/click_and_collect/DispatchTypesEnum.js";
import ChooseDispatchType from "../Dispatch/ChooseDispatchType.vue";
import SelectShippingMethod from "../Dispatch/SelectShippingMethod.vue";
import CopyToClipboard from "../Buttons/CopyToClipboard.vue";

export default {
    data() {
        return {
            loading: false,
            error: null,
            client: this.$store.getters["client/client"],
            tel_raw: this.$store.getters["client/tel"],
            telClient: null,
            errors: null,
            general_terms: false,
            token: null,
            uuid: this.createUUID(),
            isGroupPayment: false,
            groupPayments: [],
            ownerAmount: null,
            group_payment_url: null,
            dispatch: {
                type: null,
                isAddressValidated: false,
                finalClientAddress: null,
                isServicePointValidated: false,
                finalServicePoint: null,
                isShippingMethodValidated: false,
                finalShippingMethod: null,
            },
            dispatchType: null,
            step: 1,
            timer: undefined,
            payment_url: null,
        };
    },
    mixins: [PromoCodeTypeEnum, DispatchTypesEnum],
    computed: {
        defaultTelCountry() {
            return this.$store.getters["restaurant/default_tel_country"];
        },
        servicePointDisplayable() {
            if (!this.dispatch.isServicePointValidated) return {};
            return {
                Transporteur: `${this.$utils.capitalize(this.dispatch.servicePointSelected.carrier)}`,
                "Nom du point relais": `${this.dispatch.servicePointSelected.name}`,
                "Adresse du point relais": `${this.dispatch.servicePointSelected.street}, ${this.dispatch.servicePointSelected.postal_code} ${this.dispatch.servicePointSelected.city}`,
            };
        },
        title() {
            switch (this.step) {
                case 1:
                    return "Mode d'expédition";
                case 2:
                    return "Méthode d'expédition";
                default:
                    return "Informations";
            }
        },
        collectType() {
            return this.$store.getters["cart/collectType"];
        },
        collectTypeLabel() {
            switch (this.collectType) {
                case "collect":
                    return this.$t("info.collect");
                case "delivery":
                    return this.$t("info.delivery.name");
                default:
                    return "";
            }
        },
        isGroupPaymentAvailable() {
            if (!this.$store.getters["restaurant/isGroupPaymentEnable"]) return false;
            if (this.token !== null && !this.isGroupPayment) return false;
            return true;
        },
        base_url() {
            return NOSHOW_URL;
        },
        cgv() {
            return this.$store.getters["restaurant/cgv"] !== null
                ? this.$store.getters["restaurant/cgv"] + `?v=${this.uuid}`
                : this.base_url + `/click_and_collect/widget/public/${this.$api_key}/cgv?v=${this.uuid}`;
        },
        builtData() {
            let data = {
                client: this.client,
                cart: {
                    products: this.$store.getters["cart/products"],
                    comment: this.$store.getters["cart/comment"],
                },
                general_terms: this.general_terms,
                isGroupPayment: this.isGroupPayment,
                groupPayments: this.groupPayments,
                ownerAmount: Number.parseFloat(this.ownerAmount),
                collectType: this.collectType,
                promo_code_id: null,
                gv_sale_id: null,
                dispatch: {
                    type: this.dispatch.type,
                    shipping_method_id: this.dispatch.finalShippingMethod.id,
                    shipping_method_name: this.dispatch.finalShippingMethod.name,
                },
                redirect_uri: document.location.href.replace(/\?.*/, ""), // Remove query parameters -> prevent error on customer's website if invalid parameters
            };
            const promoCode = this.$store.getters["cart/promoCode"];
            if (promoCode) data.promo_code_id = promoCode.id;
            const giftVoucher = this.$store.getters["cart/giftVoucher"];
            if (giftVoucher) data.gv_sale_id = giftVoucher.id;
            if (this.dispatch.isServicePointValidated) {
                data.dispatch.service_point_id = this.dispatch.servicePointSelected.id;
                data.dispatch.service_point_carrier = this.dispatch.servicePointSelected.carrier;
                data.dispatch.service_point_name = this.dispatch.servicePointSelected.name;
                data.dispatch.service_point_address = `${this.dispatch.servicePointSelected.street}, ${this.dispatch.servicePointSelected.postal_code} ${this.dispatch.servicePointSelected.city}`;
            }
            return data;
        },
        restaurantBgColor() {
            return this.$store.getters["restaurant/bgcolor"];
        },
        basicRestaurantBgColor() {
            return { color: `${this.restaurantBgColor} !important` };
        },
        buttonRestaurantBgColor() {
            return { background: this.restaurantBgColor };
        },
        products() {
            return this.$store.getters["cart/products"];
        },
        totalGroupAmount() {
            let totalAmount =
                this.ownerAmount !== null && this.ownerAmount != "" && !isNaN(this.ownerAmount) ? Number.parseFloat(this.ownerAmount) : 0;
            this.groupPayments.forEach((payment) => {
                totalAmount += payment.amount !== null && payment.amount != "" && !isNaN(payment.amount) ? Number.parseFloat(payment.amount) : 0;
            });
            return totalAmount;
        },
        hasValidPromoCode() {
            return this.promoCode != null;
        },
        promoCode() {
            return this.$store.getters["cart/promoCode"];
        },
        totalAmount() {
            let totalAmount = 0;
            if (this.products.length === 0) return 0;
            return this.$store.getters["cart/totalAmount"];
        },
        totalAmountWithPromoCode() {
            let totalAmount = this.totalAmount * 100;
            if (!this.hasValidPromoCode) return totalAmount;
            let promoCode = this.$store.getters["cart/promoCode"];
            if (promoCode.type === this.PROMO_CODE_TYPE_AMOUNT.value) return totalAmount - promoCode.amount;
            if (promoCode.type === this.PROMO_CODE_TYPE_PERCENT.value) return totalAmount - (promoCode.amount * totalAmount) / 100;
            return totalAmount;
        },
        totalAmountStr() {
            if (this.totalAmount === 0) return "--";
            return this.formatPrice(this.totalAmount);
        },
        totalAmountStrWithPromoCode() {
            if (this.totalAmountWithPromoCode === 0) return "--";
            return this.$utils.formatPrice(this.totalAmountWithPromoCode / 100);
        },
        deliveryInfo() {
            return this.$store.getters["cart/deliveryInfo"] !== null ? this.$store.getters["cart/deliveryInfo"].cc_delivery_area : null;
        },
        deliveryFees() {
            const dispatchFreeAbove = this.$store.getters["restaurant/dispatch_free_above"];
            if (!this.dispatch.finalShippingMethod) return 0;
            if (dispatchFreeAbove && dispatchFreeAbove <= this.totalAmountWithPromoCode && this.dispatch.finalShippingMethod.is_first) return null;
            return this.dispatch.finalShippingMethod.price;
        },
        totalAmountWithDeliveryFees() {
            const deliveryFees = this.deliveryFees !== null ? this.deliveryFees : 0;
            return this.totalAmountWithPromoCode / 100 + deliveryFees / 100;
        },
        totalAmountWithDeliveryFeesStr() {
            if (this.totalAmountWithDeliveryFees === 0) return "--";
            return this.formatPrice(this.totalAmountWithDeliveryFees);
        },
    },
    methods: {
        prevStep() {
            if (this.step === 2) {
                this.dispatch.isShippingMethodValidated = false;
                this.dispatch.finalShippingMethod = null;
            }
            this.step--;
        },
        getPersonNumberLabel(nb) {
            let labels = {
                0: "deuxième",
                1: "troisième",
                2: "quatrième",
                3: "cinquième",
                4: "sixième",
                5: "septième",
                6: "huitième",
                7: "neuvième",
                8: "dixième",
            };
            if (labels[nb]) return labels[nb];
            return "";
        },
        addGroupPayment() {
            if (this.groupPayments.length >= 9) return;
            this.groupPayments.push({
                email: null,
                amount: this.totalAmountWithDeliveryFees - this.totalGroupAmount > 0 ? this.totalAmountWithDeliveryFees - this.totalGroupAmount : 0,
            });
        },
        createUUID() {
            var dt = new Date().getTime();
            var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
                var r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
            });
            return uuid;
        },
        formatPrice(price) {
            return Number.parseFloat(price).toFixed(2).toString().replace(".", ",");
        },
        setPhoneAndCountry(phoneObject) {
            if (phoneObject.isValid) {
                this.client.tel = phoneObject.number.international;
                this.client.tel_country = phoneObject.regionCode;
            } else {
                this.client.tel = null;
                this.client.tel_country = null;
            }
        },
        save(e, confirm_add_doublon_command = false) {
            this.loading = true;
            this.errors = null;
            this.error = null;

            if (this.token === null) {
                axios
                    .post(`${API_URL}/${this.$api_key}/commands`, {
                        ...this.builtData,
                        confirm_add_doublon_command,
                    })
                    .then((response) => {
                        this.token = response.data.token;
                        this.loading = false;
                        this.step++;
                        if (!this.isGroupPayment) {
                            this.payment_url = response.data.payment_url;
                            setTimeout(() => {
                                document.location.href = this.payment_url;
                            }, 5000);
                        } else {
                            this.group_payment_url = response.data.group_payment_url;
                        }
                    })
                    .catch((error) => {
                        this.loading = false;
                        if (error.response.status == 409) {
                            if (confirm("Une commande a déjà été enregistrée pour cette date. Êtes-vous sûr de vouloir commander à nouveau ?"))
                                this.save(null, true);
                            return;
                        }
                        this.errors = error.response ? error.response.data.errors : null;
                        this.error = this.$t("errors.common.required_action");
                    });
            }
        },
        close() {
            if (this.step === 4) this.$emit("saved");
            else this.$emit("close");
        },
        closeFromModal: function (e) {
            e.stopPropagation();
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
        clearTimer() {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = undefined;
            }
        },
    },
    watch: {
        client: {
            deep: true,
            handler() {
                this.$store.dispatch("client/setClient", this.client);
            },
        },
    },
    components: {
        VueTelInput,
        LoaderComponent,
        ShowErrors,
        ChooseDispatchType,
        SelectShippingMethod,
        CopyToClipboard,
    },
    created() {
        if (this.products.length < 1) {
            alert(this.$t("errors.common.empty_cart"));
            this.close();
            return;
        }
        // Timer of 15 minutes
        // this.timer = setTimeout(() => {
        //     if (this.step > 3)
        //         return;
        //     alert('Vous avez dépassé le temps limite pour effectuer votre paiement, merci de réitérer votre demande');
        //     this.close();
        // }, 15 * 60 * 1000);
    },
};
</script>

<style>
.vue-tel-input {
    border: 1px solid #f7f7f7 !important;
    padding: 4px;
}
</style>
